import * as math from 'mathjs'

export const millimeterToInch = millimeters => {
  const inchs = millimeters / 25.4
  const numerator = Math.floor(inchs)
  const fraction = math.fraction(inchs % 1)
  return `${numerator} ${fraction.n}/${fraction.d} "`
}

/**
 * Transform binary file into a base 64 string.
 */
export const transformFileToBase64 = (content) => {
  let binary = ''
  const bytes = new Uint8Array(content)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  const base64 = btoa(binary)
  return base64
}