export default {
  development: {
    rig_return_report_list: 'datasets_bra_smart_inventory_dev_inventory_qas_rig_return_report_list',
    rig_return_report_data: 'datasets_bra_smart_inventory_dev_inventory_qas_rig_return_report_data',
    modified_products_report_data: 'datasets_bra_smart_inventory_dev_inventory_qas_modified_product_report_data',
    receive_inspection_list: 'datasets_bra_smart_inventory_dev_inventory_qas_receiving_inspection_report_list',
    receive_inspection_data: 'datasets_bra_smart_inventory_dev_inventory_qas_receiving_inspection_report_data',
    periodical_inspection_list: 'datasets_bra_smart_inventory_new_dev_vtsa_dev_periodical_inspection_report_list',
    periodical_inspection_data: 'datasets_bra_smart_inventory_dev_inventory_qas_periodical_inspection_report_data',
    dispatch_notes_list: 'datasets_bra_smart_inventory_dev_inventory_qas_dispatch_notes_list',
    dispatch_notes_third_party_data: 'datasets_bra_smart_inventory_dev_inventory_qas_dispatch_notes_delivery_to_third_party_data',
    dispatch_notes_rigprep_report_data:'datasets_bra_smart_inventory_dev_inventory_qas_dispatch_notes_rigprep_report_data',
    dispatch_notes_material_modification_data:'datasets_bra_smart_inventory_dev_inventory_qas_dispatch_notes_material_modification_data',
    delivery_notes_mat_physical_transfer_report_data: 'datasets_bra_smart_inventory_dev_inventory_qas_delivery_notes_mat_physical_transfer_data',
    spot_inspection_report: 'datasets_bra_smart_inventory_dev_inventory_qas_spot_inspection_report',
    mill_receipt: 'datasets_bra_smart_inventory_dev_inventory_qas_mill_receipt_report_data',
    mill_receipt_list: 'datasets_bra_smart_inventory_dev_inventory_qas_mill_receipt_list',
    material_requisition_list: 'datasets_bra_smart_inventory_dev_inventory_qas_material_requisition_inspection_list',
    material_requisition_report_data: 'datasets_bra_smart_inventory_dev_inventory_qas_material_requisition_rigprep_inspection_report_data',
    material_requisition_delivery_third_party_data: 'datasets_bra_smart_inventory_dev_inventory_qas_material_requisition_delivery_third_party_data',
    material_requisition_mat_modification_data: 'datasets_bra_smart_inventory_dev_inventory_qas_material_requisition_mat_modification_data',
    material_requisition_mat_physical_transfer_data: 'datasets_bra_smart_inventory_dev_inventory_qas_material_requisition_mat_physical_transfer_data',
    delivery_notes: 'datasets_bra_smart_inventory_dev_inventory_qas_delivery_notes_data_2',
    rigprep_report: 'datasets_bra_smart_inventory_dev_inventory_qas_rigprep_report_data',
    material_description: 'material_description',
    pipe_data: 'pipe_data_dev',
    nominal_data: 'datasets_bra_smart_inventory_general_pipe_data_nominal_data', 
    slow_moving_pipes_report_data:'datasets_bra_smart_inventory_dev_inventory_qas_slow_moving_pipes_report_data',
    material_physical_transfer_report_data:'datasets_bra_smart_inventory_dev_inventory_qas_physical_transfer_report_data',
    title_transfer_report_data:'datasets_bra_smart_inventory_dev_inventory_qas_title_transfer_report_data'
  },
  qas: {
    rig_return_report_list: 'datasets_bra_smart_inventory_dev_inventory_qas_rig_return_report_list',
    rig_return_report_data: 'datasets_bra_smart_inventory_dev_inventory_qas_rig_return_report_data',
    modified_products_report_data: 'datasets_bra_smart_inventory_dev_inventory_qas_modified_product_report_data',
    receive_inspection_list: 'datasets_bra_smart_inventory_dev_inventory_qas_receiving_inspection_report_list',
    receive_inspection_data: 'datasets_bra_smart_inventory_dev_inventory_qas_receiving_inspection_report_data',
    periodical_inspection_list: 'datasets_bra_smart_inventory_new_dev_vtsa_dev_periodical_inspection_report_list',
    periodical_inspection_data: 'datasets_bra_smart_inventory_dev_inventory_qas_periodical_inspection_report_data',
    dispatch_notes_list: 'datasets_bra_smart_inventory_dev_inventory_qas_dispatch_notes_list',
    dispatch_notes_third_party_data: 'datasets_bra_smart_inventory_dev_inventory_qas_dispatch_notes_delivery_to_third_party_data',
    dispatch_notes_rigprep_report_data:'datasets_bra_smart_inventory_dev_inventory_qas_dispatch_notes_rigprep_report_data',
    dispatch_notes_material_modification_data:'datasets_bra_smart_inventory_dev_inventory_qas_dispatch_notes_material_modification_data',
    delivery_notes_mat_physical_transfer_report_data: 'datasets_bra_smart_inventory_dev_inventory_qas_delivery_notes_mat_physical_transfer_data',
    spot_inspection_report: 'datasets_bra_smart_inventory_dev_inventory_qas_spot_inspection_report',
    mill_receipt: 'datasets_bra_smart_inventory_dev_inventory_qas_mill_receipt_report_data',
    mill_receipt_list: 'datasets_bra_smart_inventory_dev_inventory_qas_mill_receipt_list',
    material_requisition_list: 'datasets_bra_smart_inventory_dev_inventory_qas_material_requisition_inspection_list',
    material_requisition_report_data: 'datasets_bra_smart_inventory_dev_inventory_qas_material_requisition_rigprep_inspection_report_data',
    material_requisition_delivery_third_party_data: 'datasets_bra_smart_inventory_dev_inventory_qas_material_requisition_delivery_third_party_data',
    material_requisition_mat_modification_data: 'datasets_bra_smart_inventory_dev_inventory_qas_material_requisition_mat_modification_data',
    material_requisition_mat_physical_transfer_data: 'datasets_bra_smart_inventory_dev_inventory_qas_material_requisition_mat_physical_transfer_data',
    delivery_notes: 'datasets_bra_smart_inventory_dev_inventory_qas_delivery_notes_data_2',
    rigprep_report: 'datasets_bra_smart_inventory_dev_inventory_qas_rigprep_report_data',
    material_description: 'material_description',
    pipe_data: 'pipe_data_dev',
    nominal_data: 'datasets_bra_smart_inventory_general_pipe_data_nominal_data', 
    slow_moving_pipes_report_data:'datasets_bra_smart_inventory_dev_inventory_qas_slow_moving_pipes_report_data',
    material_physical_transfer_report_data:'datasets_bra_smart_inventory_dev_inventory_qas_physical_transfer_report_data',
    title_transfer_report_data:'datasets_bra_smart_inventory_dev_inventory_qas_title_transfer_report_data'
  },
  staging: {
    rig_return_report_list: 'datasets_bra_smart_inventory_qas_vtsa_inventory_rig_return_report_list',
    rig_return_report_data: 'datasets_bra_smart_inventory_qas_vtsa_inventory_rig_return_report_data',
    receive_inspection_list: 'datasets_bra_smart_inventory_qas_vtsa_inventory_receiving_inspection_report_list',
    receive_inspection_data: 'datasets_bra_smart_inventory_qas_vtsa_inventory_receiving_inspection_report_data',
    periodical_inspection_data: 'datasets_bra_smart_inventory_qas_vtsa_inventory_periodical_inspection_report_data',
    modified_products_report_data: 'datasets_bra_smart_inventory_qas_vtsa_inventory_modified_product_data',
    dispatch_notes_list: 'datasets_bra_smart_inventory_qas_vtsa_inventory_dispatch_notes_list',
    dispatch_notes_third_party_data: 'datasets_bra_smart_inventory_qas_vtsa_inventory_dispatch_notes_delivery_to_third_party_data',
    dispatch_notes_rigprep_report_data:'datasets_bra_smart_inventory_qas_vtsa_inventory_dispatch_notes_rigprep_report_data',
    dispatch_notes_material_modification_data:'datasets_bra_smart_inventory_qas_vtsa_inventory_dispatch_notes_material_modification_data',
    delivery_notes_mat_physical_transfer_report_data: 'datasets_bra_smart_inventory_qas_vtsa_inventory_delivery_notes_mat_physical_transfer_data',
    spot_inspection_report: 'datasets_bra_smart_inventory_qas_vtsa_inventory_spot_inspection_report',
    mill_receipt: 'datasets_bra_smart_inventory_qas_vtsa_inventory_mill_receipt_report_data',
    mill_receipt_list: 'datasets_bra_smart_inventory_qas_vtsa_inventory_mill_receipt_list',
    material_requisition_list: 'datasets_bra_smart_inventory_qas_vtsa_inventory_material_requisition_inspection_list',
    material_requisition_report_data: 'datasets_bra_smart_inventory_qas_vtsa_inventory_material_requisition_rigprep_inspection_report_data',
    material_requisition_delivery_third_party_data: 'datasets_bra_smart_inventory_qas_vtsa_inventory_material_requisition_delivery_third_party_data',
    material_requisition_mat_modification_data: 'datasets_bra_smart_inventory_qas_vtsa_inventory_material_requisition_mat_modification_data',
    material_requisition_mat_physical_transfer_data: 'datasets_bra_smart_inventory_qas_vtsa_inventory_material_requisition_mat_physical_transfer_data',
    delivery_notes: 'datasets_bra_smart_inventory_qas_vtsa_inventory_delivery_notes_data_2',
    rigprep_report: 'datasets_bra_smart_inventory_qas_vtsa_inventory_rigprep_report_data',
    material_description: 'material_description',
    pipe_data: 'pipe_data_dev',
    nominal_data: 'datasets_bra_smart_inventory_general_pipe_data_nominal_data', 
    slow_moving_pipes_report_data:'datasets_bra_smart_inventory_qas_vtsa_inventory_slow_moving_pipes_report_data',
    material_physical_transfer_report_data:'datasets_bra_smart_inventory_qas_vtsa_inventory_physical_transfer_report_data',
    title_transfer_report_data:'datasets_bra_smart_inventory_qas_vtsa_inventory_title_transfer_report_data'
  },
  production: {
    rig_return_report_list: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_rig_return_report_list',
    rig_return_report_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_rig_return_report_data',
    modified_products_report_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_modified_product_report_data',
    receive_inspection_list: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_receiving_inspection_report_list',
    receive_inspection_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_receiving_inspection_report_data',
    periodical_inspection_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_periodical_inspection_report_data',
    dispatch_notes_list: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_dispatch_notes_list',
    dispatch_notes_third_party_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_dispatch_notes_delivery_to_third_party_data',
    dispatch_notes_rigprep_report_data:'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_dispatch_notes_rigprep_report_data',
    dispatch_notes_material_modification_data:'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_dispatch_notes_material_modification_data',
    spot_inspection_report: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_spot_inspection_report',
    mill_receipt: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_mill_receipt_report_data',
    mill_receipt_list: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_mill_receipt_report_list',
    material_requisition_list: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_material_requisition_inspection_list',
    material_requisition_report_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_material_requisition_rigprep_inspection_report_data',
    material_requisition_delivery_third_party_data: 'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_material_requisition_delivery_third_party_data',
    material_requisition_mat_modification_data: 'datasets_bra_smart_inventory_qas_vtsa_inventory_material_requisition_mat_modification_data',
    delivery_notes: 'datasets_bra_smart_inventory_qas_vtsa_inventory_delivery_notes_data_2',
    rigprep_report: 'datasets_bra_smart_inventory_qas_vtsa_inventory_rigprep_report_data',
    material_description: 'material_description',
    pipe_data: 'pipe_data_dev',
    nominal_data: 'datasets_bra_smart_inventory_general_pipe_data_nominal_data', 
    slow_moving_pipes_report_data:'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_slow_moving_pipes_report_data',
    material_physical_transfer_report_data:'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_physical_transfer_report_data',
    title_transfer_report_data:'datasets_bra_smart_inventory_production_smartengo_prod_vtsa_dashboard_title_transfer_report_data'
  },
}