import React, { useState, useEffect, useContext } from 'react'

import { toast } from 'react-toastify'

import { UserContext } from './UserStore'

import kpiApi from 'api/kpi'

import { kpi_uri, type } from '../utils/constants'
import { exportAsExcelFileMultiSheet } from '../utils/excel'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import _ from 'lodash'

const initialState = {
  kpiType: type.KPI,
  kpis: [],
  adminKpis: [],
  endUser: null,
  isAdmin: false,
  period: '',
  value: undefined,
}

const endUserInitialOptions = [
  {
    id: 'ADNOC ONSHORE',
    value: 1,
    label: 'ADNOC ONSHORE',
  },

  {
    id: 'ADNOC OFFSHORE',
    value: 2,
    label: 'ADNOC OFFSHORE'
  }
]

export const KpiContext = React.createContext(initialState)


export const KpiProvider = ({ children }) => {

  const { currentUser } = useContext(UserContext)
  const { idToken } = useContext(UserContext)
  const { t } = useTranslation()

  const [kpiType, setKpiType] = useState(type.KPI)
  const [openAdminModal, setOpenAdminModal] = useState(false)
  const [openExportModal, setOpenExportModal] = useState(false)
  const [isAlreadyFilledAndNotOk, setisAlreadyFilledAndNotOk] = useState(false)
  const [kpis, setKpis] = useState([])
  const [adminKpis, setAdminKpis] = useState([])
  const [endUser, setEndUser] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [totalScore, setTotalScore] = useState(0)
  const [maxScore, setMaxScore] = useState(0)
  const [pageLoading, setPageLoading] = useState(true)
  const [modalLoading, setModalLoading] = useState(true)
  const [historyKpi, setHistoryKpi] = useState()
  const [historyData, setHistoryData] = useState([])
  const [period, setPeriod] = useState('')
  const [value, setValue] = useState(undefined)
  const [exportExcelFile, setExportExcelFile] = useState(false)
  const [initDate, setInitDate] = useState(undefined)
  const [endDate, setEndDate] = useState(undefined)
  const [year, setYear] = useState(null)
  const [month, setMonth] = useState(null)
  const [semester, setSemester] = useState(null)
  const [quarter, setQuarter] = useState(null)
  const [isToSave, setIsToSave] = useState(false)
  const [idKpi, setIdKpi] = useState(null)
  const [endUserOptions, setEndUserOptions] = useState([])
  const [isAverage, setIsAverage] = useState(false)

  const getNumberProp = (prop, defaultValue = 0) => prop || defaultValue
  const getColorProp = (prop, defaultValue = '#000') => prop || defaultValue

  const getAvaliableEndUsers = useCallback(() => {
    const customers = _.get(currentUser, 'roles.CUSTOMERS', {})
    return _.chain(customers)
      .pickBy((value, key) => key !== 'id')
      .map(group => _.chain(group)
        .pickBy((value, key) => key !== 'id')
        .map(({ id }) => id)
        .uniq()
        .value()
      )
      .flatten()
      .uniq()
      .value()
  }, [currentUser])

  const getAvaliableEndUsersOptions = () => {
    const avaliable = getAvaliableEndUsers()
    return endUserInitialOptions.filter(endUser => avaliable.includes(endUser ? endUser.id : ''))
  }


  const includeFieldsAdmKpiArray = useCallback((adminKpis, kpis) => {
    const kpisWithScore = []
    for (let adminKpi of adminKpis) {
      const notAdminKpi = kpis.find(kpi => kpi.kpi_id === adminKpi.kpi_id) || { type: '' }
      kpisWithScore.push({
        ...adminKpi,
        score: getNumberProp(notAdminKpi.score),
        lastScore: getNumberProp(notAdminKpi.lastScore),
        colorStatus: getColorProp(notAdminKpi.colorStatus),
        translateDscKey: 'kpis.dsc_' + adminKpi.type.toLowerCase() + '.' + adminKpi.id,
        translateNameKey: 'kpis.name_' + adminKpi.type.toLowerCase() + '.' + adminKpi.id
      })
    }
    setAdminKpis(kpisWithScore)
  }, [])

  const includeFieldsKpiArray = (kpis) => {
    const kpisWithTranslateKey = []
    for (let kpi of kpis) {
      kpisWithTranslateKey.push({
        ...kpi,
        translateDscKey: 'kpis.dsc_' + kpi.type.toLowerCase() + '.' + kpi.id,
        translateNameKey: 'kpis.name_' + kpi.type.toLowerCase() + '.' + kpi.id
      })
    }
    setKpis(kpisWithTranslateKey)
  }

  const setTotalAndMaxScore = (kpis) => {
    setTotalScore(kpis.reduce(function (total, kpi) {
      return total + kpi.score
    }, 0))
    setMaxScore(kpis.reduce(function (total, kpi) {
      return total + kpi.maxScore
    }, 0))
  }

  const initUpInsertVariables = () => {
    setValue(null)
    setPeriod('')
    setIdKpi(null)
    setYear(null)
    setMonth(null)
    setSemester(null)
    setQuarter(null)
    setisAlreadyFilledAndNotOk(false)
    setIsToSave(false)
  }

  const formatExcelExport = (data, columns) => {
    const columnsName = columns.map(item => item.name)
    let translate = []
    columnsName.forEach((column) => {
      translate.push(translate[column] = column)
    })
    const arrayToExcel = []
    data.forEach((row) => {
      const objRow = {}
      columns.forEach(column => {
        objRow[translate[column.name]] = row[column.prop]
      })
      arrayToExcel.push(objRow)
    })
    return arrayToExcel
  }

  const formatDate = () => {
    let date = new Date()
    let localeDate = date.toLocaleDateString()
    let localeTime = date.toLocaleTimeString()
    return '_' + localeDate.replace(/\//g, '') + '-' + localeTime.replace(/:/g, '')
  }

  const formatChartDate = useCallback((date) => {
    if (new Date(date).getUTCMonth() === 0)
      return t('kpis.month_abbr.january') + new Date(date).getUTCFullYear()
    if (new Date(date).getUTCMonth() === 1)
      return t('kpis.month_abbr.february') + new Date(date).getUTCFullYear()
    if (new Date(date).getUTCMonth() === 2)
      return t('kpis.month_abbr.march') + new Date(date).getUTCFullYear()
    if (new Date(date).getUTCMonth() === 3)
      return t('kpis.month_abbr.april') + new Date(date).getUTCFullYear()
    if (new Date(date).getUTCMonth() === 4)
      return t('kpis.month_abbr.may') + new Date(date).getUTCFullYear()
    if (new Date(date).getUTCMonth() === 5)
      return t('kpis.month_abbr.june') + new Date(date).getUTCFullYear()
    if (new Date(date).getUTCMonth() === 6)
      return t('kpis.month_abbr.july') + new Date(date).getUTCFullYear()
    if (new Date(date).getUTCMonth() === 7)
      return t('kpis.month_abbr.august') + new Date(date).getUTCFullYear()
    if (new Date(date).getUTCMonth() === 8)
      return t('kpis.month_abbr.september') + new Date(date).getUTCFullYear()
    if (new Date(date).getUTCMonth() === 9)
      return t('kpis.month_abbr.october') + new Date(date).getUTCFullYear()
    if (new Date(date).getUTCMonth() === 10)
      return t('kpis.month_abbr.november') + new Date(date).getUTCFullYear()
    if (new Date(date).getUTCMonth() === 11)
      return t('kpis.month_abbr.december') + new Date(date).getUTCFullYear()
  }, [t])

  const formatExcelMultiSheets = useCallback((response) => {
    let excelScoreData; let excelValueData
    if (response.score) {
      let columnsScore = []
      Object.keys(response.score[0]).forEach(item => {
        columnsScore.push({ name: item.toUpperCase(), prop: item })
      })
      excelScoreData = formatExcelExport(response.score, columnsScore)
    }

    if (response.value) {
      let columnsValue = []
      Object.keys(response.value[0]).forEach(item => {
        columnsValue.push({ name: item.toUpperCase(), prop: item })
      })
      excelValueData = formatExcelExport(response.value, columnsValue)
    }
    return [{ name: t('kpis.table.worksheet.score.title'), data: excelScoreData }, { name: t('kpis.table.worksheet.value.title'), data: excelValueData }]
  }, [t])

  // /**
  // * Effection for init kpi data
  // */
  // useEffect(() => {
  //   const hydrate = async () => {
  //     try {
  //       setPageLoading(true)
  //       console.log(endUser)
  //       const responseKpis = await kpiApi.getKpis(kpi_uri.GET_CURRENT_KPIS, { kpiType: kpiType, customerId: endUser }, idToken)
  //       await setTotalAndMaxScore(responseKpis.kpis)
  //       await includeFieldsKpiArray(responseKpis.kpis)
  //       const responseAdminKpis = await kpiApi.getKpis(kpi_uri.GET_ADMIN_KPIS, { kpiType: kpiType, customerId: endUser }, idToken)
  //       await includeFieldsAdmKpiArray(responseAdminKpis.kpis, responseKpis.kpis)
  //     }
  //     catch (error) {
  //       setPageLoading(false)
  //       throw new Error(error)
  //     }
  //     finally {
  //       setPageLoading(false)
  //     }
  //   }

  //   hydrate().catch(err => {
  //     toast.error(err.message)
  //     console.log(err)
  //   })
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effection for kpi data
   */
  useEffect(() => {
    const hydrate = async () => {
      try {
        setPageLoading(true)
        const avaliableEndUsers = getAvaliableEndUsersOptions()
        const endUserForRequest = endUser ? endUser : (avaliableEndUsers && avaliableEndUsers[0] && avaliableEndUsers[0].value)
        setEndUserOptions(avaliableEndUsers)
        setEndUser(endUserForRequest)
        const responseKpis = await kpiApi.getKpis(kpi_uri.GET_CURRENT_KPIS, { kpiType: kpiType, customerId: endUserForRequest  }, idToken)
        await setTotalAndMaxScore(responseKpis.kpis)
        await includeFieldsKpiArray(responseKpis.kpis.sort((a, b) => a.idOrder - b.idOrder)) 
        const responseAdminKpis = await kpiApi.getKpis(kpi_uri.GET_ADMIN_KPIS, { kpiType: kpiType, customerId: endUserForRequest }, idToken)
        await includeFieldsAdmKpiArray(responseAdminKpis.kpis.sort((a, b) => a.idOrder - b.idOrder), responseKpis.kpis)
      }
      catch (error) {
        setPageLoading(false)
        throw new Error(error)
      }
      finally {
        setPageLoading(false)
      }
    }

    hydrate().catch(err => {
      toast.error(err.message)
      console.log(err)
    })
  }, [kpiType, endUser, isAdmin, isToSave]) // eslint-disable-line react-hooks/exhaustive-deps

  const formatKpi = () => {
    const kpi = isAverage 
                ? { kpi: { type: kpiType, input_year_average : Number(value), period: period, customerId: endUser, kpi_id: idKpi },
                           user: { id: currentUser.id, name: currentUser.name }
                  }
                : { kpi: { type: kpiType, value: Number(value), period: period, customerId: endUser, kpi_id: idKpi },
                    user: { id: currentUser.id, name: currentUser.name }
                  }
    return kpi
  }

  /**
   * Effection for kpi data
   */
  useEffect(() => {
    const hydrate = async () => {
      try {
        setModalLoading(true)
        if (isToSave) {
          
          const response = isAverage ? await kpiApi.upInsertKpis(kpi_uri.UP_INSERT_YEAR_AVERAGE, formatKpi(), idToken)
                                     : await kpiApi.upInsertKpis(kpi_uri.UP_INSERT_KPI, formatKpi(), idToken)
          if (response) {
            setOpenAdminModal(false)
            toast.success('Kpi updated Successfully')
            await initUpInsertVariables()
          }
        }
      }
      catch (error) {
        setModalLoading(false)
        initUpInsertVariables()
        throw new Error(error)
      }
      finally {
        setModalLoading(false)
      }
    }

    hydrate().catch(err => {
      toast.error(err.message)
      console.log(err)
    })

  }, [isToSave]) // eslint-disable-line react-hooks/exhaustive-deps


  /**
   * Effections for History Kpi
   */

  useEffect(() => {
    const hydrate = async () => {
      try {
        setModalLoading(true)
        if (historyKpi) {
          const responseKpis = await kpiApi.getKpiHistory(kpi_uri.GET_KPI_HISTORY, { kpiType: kpiType, kpiId: historyKpi.id, customerId: endUser }, idToken)
          responseKpis.kpiHistory.sort((a, b) => (a.period < b.period) ? -1 : 1)
          const chartData = responseKpis.kpiHistory.map((value, key) => ({
            id: key,
            value: Number(value.value),
            date: formatChartDate(value.period), 
            colorStatus: value.colorStatus
          }))
          setHistoryData(chartData)
        }
      }
      catch (error) {
        setModalLoading(false)
        throw new Error(error)
      }
      finally {
        setModalLoading(false)
      }
    }

    hydrate().catch(err => {
      toast.error(err.message)
      console.log(err)
    })
  }, [historyKpi]) // eslint-disable-line react-hooks/exhaustive-deps

  /* *
  * Effections for Export as Excel File
   */

  useEffect(() => {
    const hydrate = async () => {
      try {
        setModalLoading(true)
        if (exportExcelFile && initDate && endDate) {
          const response = await kpiApi.getKpisFromDate(kpi_uri.GET_KPI_FROM_DATE, { kpiType: kpiType, customerId: endUser, initDate: moment(new Date(initDate)).format('YYYY-MM-DD'), endDate: moment(new Date(endDate)).format('YYYY-MM-DD') }, idToken)
          if (response) {
            exportAsExcelFileMultiSheet(formatExcelMultiSheets(response), kpiType === type.KPI ? t('kpis.name') + formatDate() : t('kpis.reciprocal_kpis') + formatDate())
            setOpenExportModal(false)
            setInitDate(undefined)
            setEndDate(undefined)
          }
        }
        setModalLoading(false)
      }
      catch (error) {
        setModalLoading(false)
        throw new Error(error)

      }
      finally {
        setExportExcelFile(false)
        setModalLoading(false)
      }
    }

    hydrate().catch(err => {
      toast.error(err.message)
      console.log(err)
    })
  }, [exportExcelFile]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderStore =
    <KpiContext.Provider value={{
      kpis, setKpis,
      adminKpis, setAdminKpis,
      historyKpi, setHistoryKpi,
      historyData, setHistoryData,
      kpiType, setKpiType,
      isAdmin, setIsAdmin,
      endUser, setEndUser,
      idKpi, setIdKpi,
      totalScore,
      maxScore,
      value, setValue,
      modalLoading,
      pageLoading,
      exportExcelFile, setExportExcelFile,
      openAdminModal, setOpenAdminModal,
      isAlreadyFilledAndNotOk, setisAlreadyFilledAndNotOk,
      isToSave, setIsToSave,
      openExportModal, setOpenExportModal,
      period, setPeriod,
      initDate, setInitDate,
      endDate, setEndDate,
      year, setYear,
      month, setMonth,
      semester, setSemester,
      quarter, setQuarter,
      isAverage, setIsAverage,
      endUserOptions, setEndUserOptions,
    }}>
      {children}
    </KpiContext.Provider>

  return renderStore
}