import axios from 'axios'
import { decompressResponse } from '../utils/decompress'
import { addLogger } from 'utils/logger'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json'
  }
})

addLogger(instance, true)

const formatAuthorization = idToken => idToken + '.' + btoa(JSON.stringify({ 'id': process.env.REACT_APP_ID }))

export default {
  getDeliveryNotesRigPrepReportData: (params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      '/report-data/delivery-notes/rig-prep',
      params,
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getDeliveryNotesMatPhysicalTransferReportData: (params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      '/report-data/delivery-notes/mat-physical-transfer',
      params,
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getDeliveryNotesMatModificationReportData: (params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      '/report-data/delivery-notes/mat-modification',
      params,
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
  getDeliveryNotesDeliveryThirdPartyReportData: (params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      '/report-data/delivery-notes/delivery-third-party',
      params,
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
  }),
}