import React, { useContext, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import styled from 'styled-components'

import api from 'api/reports'

import { FlexView, Button, Modal, Card, LoadingOverlay, Icon  } from 'components/common'
import { TextArea, Select } from 'components/form'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'

import { reportTypes, reports_uri, hiddenReportsStatus } from 'utils/constants'

const Title = styled.div`
    font-family: Roboto;
    font-size: 24px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`
const Subtitle = styled.div`
    font-family: Roboto;
    font-size: 15px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`

const CustomButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding:10px 0px;
    margin: 8px 8px;
    width: 200px;
    &:active {
        position:relative;
        top:1px;
    }
`
const CloseButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding: 0px 0px;
    margin: 0px 0px;
    background-color: transparent;
    &:active {
        position:relative;
        top:1px;
    }
`
const ReportVisibilityCard = styled(Card)`
width: 50rem;
`

const ReportVisibilityModal = ({ type, isOpen, reportId, onOutsideClick, onCancel, status }) => {

  const { t } = useTranslation()
  const { pageLoading, setPageLoading } = useContext(AppContext)
  const { idToken, currentUser } = useContext(UserContext)
  const [ justification, setJustification] = useState('')
  const [ reportType, setReportType ] = useState('')
  const [ reportIds, setReportIds ] = useState([])
  const [ idReport, setIdReport ] = useState(null)

  const title = status === hiddenReportsStatus.ARCHIVED ? t('REVEAL TITLE') : t('ARCHIVE TITLE')
  const subtitle = status === hiddenReportsStatus.ARCHIVED ? t('REVEAL SUBTITLE') : t('ARCHIVE SUBTITLE')
  const textAreaLabel = status === hiddenReportsStatus.ARCHIVED ? t('REVEAL LABEL') : t('ARCHIVE LABEL')
  const buttonLabel = status === hiddenReportsStatus.ARCHIVED ? t('REVEAL') : t('ARCHIVE')
  
  const onJustificationChange = useCallback(event => setJustification(event.target.value), [])
  const formatIds = ids => _.orderBy(ids, 'value', 'asc')
  const formatReportTypes = types => _.orderBy(types, 'label', 'asc')
  const getBackgroundColor = () => status === hiddenReportsStatus.VISIBLE ? 'warning' : 'success'

  const onOutside = () => {
    setJustification('')
    setIdReport(null)
    setReportType('')
    onOutsideClick()
  }

  const setVisibility = useCallback(async () => {
    const params =  { report_type: type ? type : reportType, id_report: reportId ? reportId : idReport, 
                      responsible: currentUser.name, justification: justification }
    try {
      setPageLoading(true)
      let result
      if(status){
        result = status === hiddenReportsStatus.VISIBLE 
                        ? await api.archiveReports(reports_uri.HIDDEN_REPORTS_ARCHIVE, params, idToken)
                        : await api.revealReports(reports_uri.HIDDEN_REPORTS_REVEAL, params, idToken)
      }
      else {
        result = await api.archiveReports(reports_uri.HIDDEN_REPORTS_ARCHIVE, params, idToken)
      }
      
      if(result){
        onOutsideClick(true)
        if(!status){
          toast.success(t('Report Archived Successfully!'))
        }
        else {
          toast.success( status === hiddenReportsStatus.VISIBLE 
                         ? t('Report Archived Successfully!') 
                         : t('Report Successfully Revealed!'))
        }
        setJustification('')
        setPageLoading(false)
      }
    }  
    catch(e) {
      setPageLoading(false)
      toast.error( e.message)
    }
  }, [ currentUser, idReport, justification, idToken, onOutsideClick, reportId, reportType, 
       setPageLoading, t, status, type ])

  useEffect(() => {
    try {
      async function fetchData() {
        if(reportType){
          setPageLoading(true)
            const result = await api.getReportIdsByType(reports_uri.GET_AVAILABLE_REPORTS, { report_type: reportType }, idToken)
            if(result){
              const idReports = _.get(result, 'ids', {})
              setReportIds(formatIds(_.chain(idReports)
                      .map((value) => {
                        return { label: value.toString(), value: value}
                      })
                      .uniq()
                      .value()))
              setPageLoading(false)
            }
          }
      }
      fetchData()
    } catch (error) {
      console.log(error)
      toast.error(error.message)
      setPageLoading(false)
    } 
  }, [ reportType, idToken, setPageLoading ] )

  const validateWhatDisplay = () => {
    if(reportId){
      return <>
       <FlexView flexDirection='column' alignItems="center" justifyContent="flex-start" width="100%">
        <TextArea label={ textAreaLabel } width="100%" value={ justification } onChange={onJustificationChange} rows="10"></TextArea>
        <FlexView flexDirection='row' alignItems="center" justifyContent="flex-end" width="100%">
          <CustomButton backgroundColor="gray" color="white" onClick={ onOutside }>  
            {t('CANCEL')}  
          </CustomButton>
          <CustomButton  margin="0px 8px" backgroundColor={getBackgroundColor()} color="white" onClick={() => setVisibility() } 
                         disabled={!justification}>  
            { buttonLabel }  
          </CustomButton>
        </FlexView>
      </FlexView>
      </>
    }
    else {
      return  <>
      <FlexView flexDirection='row' width="100%" margin="0px" >
        <FlexView flexDirection='column' margin="2rem 0rem 0rem 0rem" alignItems="stretch" justifyContent="space-around">
          <Select width="18rem" placeholder={ reportType ? reportType.label :  t('Select the type of report') } 
                  label={ t('Select the type of report') } options={ formatReportTypes(reportTypes) } value={ reportType } 
                  onChange={reportType => { setReportType(reportType) }}  margin="0rem 2rem 0rem 0rem"/>
          { reportType 
            ? <Select width="18rem" placeholder={ idReport ? idReport.label :  t('Select the report ID') } label={ t('Select the report ID') } 
              options={ reportIds }value={ idReport } onChange={idReport => { setIdReport(idReport) }}  margin="0vh 2vh 0vh 0vh"/>
            : ''
          }     
        </FlexView>
        <TextArea label={ textAreaLabel } width="100%" value={ justification } onChange={onJustificationChange} rows="10"></TextArea>
      </FlexView>
      <FlexView flexDirection='row' alignItems="center" justifyContent="flex-end" width="100%">
        <CustomButton backgroundColor="gray" color="white" onClick={ onOutside }>  
          {t('CANCEL')}  
        </CustomButton>
        <CustomButton  margin="0px 8px" backgroundColor="warning" color="white" onClick={() => setVisibility() } 
                      disabled={!justification || !idReport || !reportType }>  
          { buttonLabel }  
        </CustomButton>
      </FlexView>
      </>
    }
  }

  return (
    <Modal isOpen={isOpen} onOutsideClick={ onOutside } onModalExit={onCancel}>
      <LoadingOverlay visible={pageLoading} />
      <ReportVisibilityCard flexDirection="column" alignItems="center" justifyContent="center">
        <FlexView flexDirection='row' width="100%" justifyContent="space-between" alignItems="flex-start" margin="0px 0px 30px 0px ">
          <FlexView flexDirection='row' justifyContent="flex-start" width="90%">
            <FlexView flexDirection='column' alignItems="flex-start" justifyContent="center" >
                <Title> <i> { title } </i>   </Title>
                <Subtitle> { subtitle } </Subtitle>
            </FlexView>
          </FlexView>
          <FlexView flexDirection='row' justifyContent="flex-end">
            <CloseButton onClick={ onOutside }>
              <Icon name="cross" width="20px" height="20px" color="error"/>
            </CloseButton>
          </FlexView>
        </FlexView>
        { validateWhatDisplay() }
      </ReportVisibilityCard>
    </Modal>
  )
}

export default ReportVisibilityModal