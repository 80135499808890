import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import UploadModal from './UploadModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const getColor = (props) => {
  if (props.isDragAccept) {
    return props.theme.colors.success
  }
  if (props.isDragReject) {
    return props.theme.colors.error
  }
  if (props.isDragActive) {
    return props.theme.colors.primary
  }
  return props.theme.colors.lightGray
}

const Container = styled.div`
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  padding: 8px;
  margin: 16px 16px 0px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`

const UploadBox = ({ onFileUpload }) => {
  const { t } = useTranslation()
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ accept: '.xls, .xlsx, .xltx, .xlsm, .xltm, .xlam, .xlsb, .pdf', multiple: false })
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    acceptedFiles.length && acceptedFiles[0].size <= 314572800 && setOpen(true)
  }, [acceptedFiles])

  const toggleModal = () => setOpen(currentState => !currentState)
  return <>
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })} data-cy="upload-box">
      <input {...getInputProps()} />
      <p>{t('Click or drag a file to upload')}</p>
      {/* {isDragReject && t("Sorry, this file type is not permitted!")} */}
    </Container>
      <UploadModal isOpen={isOpen} onOutsideClick={toggleModal} files={acceptedFiles} onFileUpload={onFileUpload} />
  </>
}

export default UploadBox