export default {
  SERVICE_STOCK_ADNOC: ({ t, lengthUnit, convertToCurrentLengthUnit }) => [
    {
      accessor: 'endUser',
      label: t('End User')
    },
    {
      accessor: 'sapReference',
      label: t('SAP Reference')
    },
    {
      accessor: 'dscMaterial',
      label: t('Material Description')
    },
     {
      label: t('Serial Number'),
      accessor: 'SerialNumber',
    },
    {
      label: t('Product'),
      accessor: 'Product',
    },
    {
      label: t('OD'),
      accessor: 'OD',
    },
    {
      label: t('Nominal Weight'),
      accessor: 'NominalWeight',
    },
    {
      label: t('Grade'),
      accessor: 'Grade',
    },
    {
      label: t('Connection'),
      accessor: 'Connection',
    },
    // {
    //   label: t('Drift Type'),
    //   accessor: 'DriftType',
    // },
    // {
    //   label: t('Range'),
    //   accessor: 'Range',
    // },
    // {
    //   Header: t('Coupling Option'),
    //   accessor: 'CouplingOption',
    // },
    // {
    //   label: t('SPEC'),
    //   accessor: 'SPEC'
    // },
    {
      label: t('Revision'),
      accessor: 'Revision',
    },
    {
      label: t('Storage Type'),
      accessor: 'StorageType',
    },
    {
      accessor: 'dscStatusMaterial',
      label: t('Material Status'),
      formatter: dscStatusMaterial => dscStatusMaterial && t(`stock.materialStatus.${dscStatusMaterial}`).toUpperCase()
    },
    {
      accessor: 'qtdPC',
      label: t('Pieces'),
      formatter: qtdPC => Number(qtdPC)
    },
    {
      accessor: 'totalFootage_ft',
      label: t('Total Length')  + ' [ft]',
      formatter: totalFootage => Number(totalFootage).toFixed(3)
    },
    {
      accessor: 'totalFootage_m',
      label: t('Total Length') + ' [m]',
      formatter: totalFootage => Number(totalFootage).toFixed(3)
    },
    {
      accessor: 'total_weight',
      label: t('Total Weight') + ' [kg]',
      formatter: total_weight => Number(total_weight).toFixed(3)
    },
    {
      accessor: 'owner_name',
      label: t('Ownership')
    },
    {
      accessor: 'entranceDateString',
      label: t('Entrance Date')
    },
    {
      accessor: 'pipeAge',
      label: t('Pipe Age')
    },
    {
      accessor: 'sapDescription',
      label: t('SAP Description')
    }
  ],
  SERVICE_STOCK_ADIPEC: ({ t, lengthUnit, convertToCurrentLengthUnit }) => [
    {
      accessor: 'sapReference',
      label: t('SAP Reference')
    },
    {
      accessor: 'dscMaterial',
      label: t('Material Description')
    },
    {
      accessor: 'dscStatusMaterial',
      label: t('Material Status'),
      formatter: dscStatusMaterial => dscStatusMaterial && t(`stock.materialStatus.${dscStatusMaterial}`).toUpperCase()
    },
    {
      accessor: 'qtdPC',
      label: t('Pieces'),
      formatter: qtdPC => Number(qtdPC)
    },
    {
      accessor: 'totalFootage',
      label: t('Total Estimated Length'),
      formatter: totalFootage => `${convertToCurrentLengthUnit(totalFootage/1000,  totalFootage/304.8, lengthUnit)}`
    },
    {
      accessor: 'dscLocation',
      label: t('Location')
    }
  ],
  SERVICE_STOCK_CHEVRON: ({ t, lengthUnit, convertToCurrentLengthUnit }) => [
    {
      accessor: 'dscMaterial',
      label: t('Material Description')
    },
    {
      accessor: 'dscStatusMaterial',
      label: t('Material Status'),
      formatter: dscStatusMaterial => dscStatusMaterial && t(`stock.materialStatus.${dscStatusMaterial}`).toUpperCase()
    },
    {
      accessor: 'qtdPC',
      label: t('Pieces'),
      formatter: qtdPC => Number(qtdPC)
    },
    {
      accessor: 'totalFootage',
      label: t('Total Estimated Length'),
      formatter: totalFootage => `${convertToCurrentLengthUnit(totalFootage/1000,  totalFootage/304.8, lengthUnit)}`
    },
    {
      accessor: 'dscLocation',
      label: t('Location')
    }
  ],
   SERVICE_STOCK_BHP: ({ t, lengthUnit, convertToCurrentLengthUnit }) => [
    {
      accessor: 'sapReference',
      label: t('SAP Reference')
    },
    {
      accessor: 'dscMaterial',
      label: t('Material Description')
    },
    {
      accessor: 'dscStatusMaterial',
      label: t('Material Status'),
      formatter: dscStatusMaterial => dscStatusMaterial && t(`stock.materialStatus.${dscStatusMaterial}`).toUpperCase()
    },
    {
      accessor: 'owner_name',
      label: t('Ownership') + '1'
    },
    {
      accessor: 'owner_name_2',
      label: t('Ownership') + '2'
    },
    {
      accessor: 'qtdPC',
      label: t('Pieces'),
      formatter: qtdPC => Number(qtdPC)
    },
    {
      accessor: 'totalFootage',
      label: t('Total Estimated Length'),
      formatter: totalFootage => `${convertToCurrentLengthUnit(totalFootage/1000,  totalFootage/304.8, lengthUnit)}`
    },
    {
      accessor: 'dscLocation',
      label: t('Location')
    }
  ], 
  SERVICE_STOCK_TOTAL_MYANMAR: ({ t, lengthUnit,  convertToCurrentLengthUnit }) => [
    {
      accessor: 'sapReference',
      label: t('SAP Reference')
    },
    {
      accessor: 'dscMaterial',
      label: t('Material Description')
    },
    {
      accessor: 'dscStatusMaterial',
      label: t('Material Status'),
      formatter: dscStatusMaterial => dscStatusMaterial && t(`stock.materialStatus.${dscStatusMaterial}`).toUpperCase()
    },
    {
      accessor: 'qtdPC',
      label: t('Pieces'),
      formatter: qtdPC => Number(qtdPC)
    },
    {
      accessor: 'totalFootage',
      label: t('Total Estimated Length'),
      formatter: totalFootage => `${convertToCurrentLengthUnit(totalFootage/1000,  totalFootage/304.8, lengthUnit)}`
    }
  ],
  SERVICE_STOCK_TOTAL_ANGOLA: ({ t, lengthUnit, convertToCurrentLengthUnit }) => [
    {
      label: t('Block Name'),
      accessor: 'blockName'
    },
    {
      label: t('Project Type'),
      accessor: 'projectType'
    },
    {
      accessor: 'sapReference',
      label: t('SAP Reference')
    },
    {
      accessor: 'dscMaterial',
      label: t('Material Description')
    },
    {
      accessor: 'dscStatusMaterial',
      label: t('Material Status'),
      formatter: dscStatusMaterial => dscStatusMaterial && t(`stock.materialStatus.${dscStatusMaterial}`).toUpperCase()
    },
    {
      accessor: 'qtdPC',
      label: t('Pieces'),
      formatter: qtdPC => Number(qtdPC)
    },
    {
      accessor: 'totalFootage',
      label: t('Total Estimated Length'),
      formatter: totalFootage => `${convertToCurrentLengthUnit(totalFootage/1000,  totalFootage/304.8, lengthUnit)}`
    },
    {
      label: t('Site'),
      accessor: 'site',
    },
    {
      label: t('Zone'),
      accessor: 'zone',
    },
    {
      label: t('Area'),
      accessor: 'area',
    },
    {
      label: t('Rack'),
      accessor: 'rack',
    }
  ],
  SERVICE_STOCK_ENI_MYANMAR: ({ t, lengthUnit, convertToCurrentLengthUnit }) => [
    {
      accessor: 'sapReference',
      label: t('SAP Reference')
    },
    {
      accessor: 'dscMaterial',
      label: t('Material Description')
    },
    {
      accessor: 'dscStatusMaterial',
      label: t('Material Status'),
      formatter: dscStatusMaterial => dscStatusMaterial && t(`stock.materialStatus.${dscStatusMaterial}`).toUpperCase()
    },
    {
      accessor: 'qtdPC',
      label: t('Pieces'),
      formatter: qtdPC => Number(qtdPC)
    },
    {
      accessor: 'totalFootage',
      label: t('Total Estimated Length'),
      formatter: ({ totalFootage_m, totalFootage_ft }) => `${convertToCurrentLengthUnit(totalFootage_m, totalFootage_ft, lengthUnit)}`,
    }
  ],
}