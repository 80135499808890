import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import moment from 'moment'

import { FlexView, Button, Modal, Card, LoadingOverlay, Icon } from 'components/common'
import { DatePicker, MultiSelect} from 'components/form'

import { ExportContext } from 'stores/ExportStore'

import { optionsConsumption, internalReportsType } from 'utils/constants'

const Title = styled.div`
    font-family: Roboto;
    font-size: 24px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`
const Subtitle = styled.div`
    font-family: Roboto;
    font-size: 15px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`

const CustomButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding:10px 0px;
    margin: 8px 0px;
    width: 200px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    &:active {
        position:relative;
        top:1px;
    }
`
const CloseButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding: 0px 0px;
    margin: 0px 0px;
    background-color: transparent;
    &:active {
        position:relative;
        top:1px;
    }
`
const ExportModalCard = styled(Card)`
width: 528px;
`

const Label = styled.div`
  padding-bottom: 15px;
`

const ExportModal = ({ title, subtitle, label, isOpen, onOutsideClick, onCancel, type, stock }) => {

  const { t } = useTranslation()
  const { initDate, endDate, setInitDate, setEndDate, modalLoading, setModalLoading, setValue,
          multiValue, setMultiValue, exportInternalReports, setIsPdf } = useContext(ExportContext)
  const [isExportConfirmed, setIsExportConfirmed] = useState(false)

  const callExport = () => {
    if(!isExportConfirmed){
      setIsExportConfirmed(true)
      setModalLoading(true)
      exportInternalReports(type, stock).catch(e => {
        console.log(e)
      }).finally(() => {
        setModalLoading(false)
        setIsExportConfirmed(false)
        onOutside()
      })
    }  
  }

  const onOutside = () => {
    setValue(undefined)
    setMultiValue([])
    setInitDate(undefined)
    setEndDate(undefined)
    setIsPdf(false)
    onOutsideClick()
  }

  const validateErrorMessage = () => {
    if(type === internalReportsType.CONSUMPTION){
      if(initDate > endDate)
        return t('The end date must be greater than the start date')
      else if(initDate > moment() || endDate > moment())
        return t('Future dates are invalid')
      return ''
    }
    else if (type === internalReportsType.RECEIPT){
      if(initDate > endDate)
        return t('The end date must be greater than the start date')
      else if(initDate > moment() || endDate > moment())
        return t('Future dates are invalid')
      return ''
    }
    else if (type === internalReportsType.STOCK_LEVEL){
      if(initDate > moment())
        return t('Future dates are invalid')
      return ''
    }
    return ''
  }

  return (
    <Modal isOpen={isOpen} onOutsideClick={ onOutside } onModalExit={onCancel}>
      <LoadingOverlay visible={modalLoading} />
      <ExportModalCard flexDirection="column" alignItems="center" justifyContent="center">
          <FlexView flexDirection='row' width="100%" justifyContent="space-between" alignItems="flex-start" margin="0px 0px 30px 0px ">
            <FlexView flexDirection='row' justifyContent="flex-start" width="90%">
              <FlexView flexDirection='column' alignItems="flex-start" justifyContent="center" >
                  <Title> <i> { title } </i>   </Title>
                  <Subtitle> { subtitle } </Subtitle>
              </FlexView>
            </FlexView>
            <FlexView flexDirection='row' justifyContent="flex-end">
              <CloseButton onClick={ onOutside }>
                <Icon name="cross" width="20px" height="20px" color="error"/>
              </CloseButton>
            </FlexView>
          </FlexView>
          <FlexView flexDirection='column' alignItems="center" justifyContent="center" width="100%">
            
            {
              type === internalReportsType.CONSUMPTION ?
              <FlexView flexDirection="column" alignItems="center" justifyContent="center">
                  <MultiSelect width="300px" options={ optionsConsumption } label={t('Select Options')} selectedValues={multiValue} ordered="true"
                  onChange={(value) => setMultiValue(value)}></MultiSelect>
              </FlexView>
              : ''
            }
            <Label> {label} </Label>
                {
                  type !== internalReportsType.STOCK_LEVEL
                  ? <FlexView margin="8px"> 
                      <DatePicker value={initDate} onChange={setInitDate} label={t('export_modal.date_from')}></DatePicker> 
                      <DatePicker value={endDate} onChange={setEndDate} label={t('export_modal.date_to')}></DatePicker>
                    </FlexView>
                  : <FlexView margin="8px"> 
                      <DatePicker value={initDate} onChange={setInitDate} label=''></DatePicker> 
                    </FlexView>
                }
            <font size="3" color="red"> { validateErrorMessage() } </font>
            <CustomButton onClick={() => callExport() } disabled={ validateErrorMessage()}>  {t('kpis.export_uc')}  </CustomButton>
           
          </FlexView>
      </ExportModalCard>
    </Modal>
  )
}

export default ExportModal