import axios from 'axios'

import { decompressResponse } from 'utils/decompress'
import { addLogger } from 'utils/logger'

const SHOULD_DECOMPRESS = false

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,  
  headers: {
    'Content-Type': 'application/json'
  }
})

addLogger(instance, false)

const formatAuthorization = idToken => idToken + '.' + btoa(JSON.stringify({ 'id': process.env.REACT_APP_ID }))

const getDefaultHeader = (idToken) => ({
  headers: { Authorization: formatAuthorization(idToken) }
})

const isSuccessfulResponse = (response) => response && response.status === 200
const isUploadError = (response) => response && response.data.isUploadError
const hasMessageError = (response) => response && response.data.messageError

const manageResponse = (response, shouldDecompress) => {
  if (isSuccessfulResponse(response)) {
    if (shouldDecompress) {
      return decompressResponse(response.data)
    }
    return response.data.body ? response?.data?.body : response.data
  } else {
    throw new Error('Couldn\'t retrieve data')
  }
}

const manageError = (error) => {
  console.log(error)
  if (isUploadError(error.response)) {
    throw error.response.data 
  }
  else if(hasMessageError(error.response)){
    throw new Error(error.response.data.messageError)
  }
  else {
    throw new Error(error)
  }
}

export default {
    getCompleteStock: async (params, idToken) => {
      try {
        const response = await instance.post('/stock-status/get-complete-stock', params, getDefaultHeader(idToken))
        return manageResponse(response, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)   
      }
  }, 
  upsertPlannedStock: async (params, idToken) => {
    try {
      const response = await instance.post('/stock-status/upsert-planned-stock/bulk', params, getDefaultHeader(idToken))
      return manageResponse(response, SHOULD_DECOMPRESS)
    } catch (error) {
      manageError(error)   
    }
  }, 
  deletePlannedStock: async (params, idToken) => {
      try {
        const response = await instance.post('/stock-status/delete-planned-stock', params, getDefaultHeader(idToken))
        return manageResponse(response, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)   
      }
  }
}