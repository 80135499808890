import React, { useContext, useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import { LoadingOverlay, FlexView, Icon } from 'components/common'
import StockTable from 'components/stock/StockTable'

import UnitSelect from 'containers/common/UnitSelect'
import ExportModal from 'containers/common/ExportModal'
import StockTabs from 'containers/layout/StockTabs'
import ChartsCard from 'containers/stock/ChartsCard'
import FilterDisplay from 'containers/stock/FilterDisplay'
import FiltersModal from 'containers/stock/FiltersModal'

import useStockFilters from 'hooks/useStockFilters'

import { AppContext } from 'stores/AppStore'
import { ExportContext } from 'stores/ExportStore'
import { StockContext } from 'stores/StockStore'

import { internalReportsType } from 'utils/constants'

const StockPage = ({ stockKey }) => {
  const { t } = useTranslation()
  const { stocks, setPageSubtitle } = useContext(AppContext)
  const { getStockData, exportToExcel, exportToPdf } = useContext(StockContext)
  const { downloadBackup } = useContext(ExportContext)
  const stock = useMemo(() => _.find(stocks, { key: stockKey }), [stocks, stockKey])
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const filterOptions = useStockFilters(stock.key)
  const [isBackupDownloading, setIsBackupDownloading] = useState(false)

  const toggleModal = () => setShowModal(currentState => !currentState)
  const toggleExportModal = () => setShowExportModal(currentState => !currentState)
  const downloadExcelBackup  = () => setIsBackupDownloading(true)

  const { setIsPdf } = useContext(ExportContext)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(t(`stock.names.${stock.key}`) + ' - ' + t('Service Stock'))
    }
  }, [stock, setPageSubtitle, t])

  useEffect(() => {
    setLoading(true)
    getStockData(stock.route, stock.wid).catch(e => {
      console.log(e)
    }).finally(() => setLoading(false))
  }, [stock, getStockData])

  useEffect(() => {
    if (isBackupDownloading) {
      setLoading(true)
      downloadBackup(stock).catch(e => {
        console.log(e)
      }).finally(() => {
        setLoading(false)
        setIsBackupDownloading(false)
      })
    }
  }, [isBackupDownloading, downloadBackup, stock])

  const onExcelExportClick = () => {
    if(stockKey === 'SERVICE_STOCK_ADNOC')
      setShowExportModal(prev => !prev)
    else
      exportToExcel(filterOptions, stock.key)
  }

  const onPdfExportClick = () => {
    if(stockKey === 'SERVICE_STOCK_ADNOC'){
      setIsPdf(true)
      setShowExportModal(prev => !prev)
    }
    else 
      exportToPdf(filterOptions, stock.key)
  }

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FilterDisplay options={filterOptions} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <UnitSelect />
        {stock.hasExcelBackup
          ? <Icon name="download" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Download Backup Information')} onClick={downloadExcelBackup} />
          : null
        }
        <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal} />
        <Icon name="xls" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Export to Excel')} onClick={onExcelExportClick} />
        <Icon name="pdf" width="28px" height="28px" margin="0px 0px 0px 0px" tooltip={t('Export to PDF')} onClick={onPdfExportClick} />
      </FlexView>
    </FlexView>
    <ChartsCard />
    <StockTable stockKey={stock.key} />
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={filterOptions} />
    <ExportModal title={t('Export Service Stock Level')} label={t('Choose the extraction date')+':'}  type={ internalReportsType.STOCK_LEVEL } isOpen={showExportModal} 
    stock={stock} onOutsideClick={toggleExportModal} />
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default StockPage