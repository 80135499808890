import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import apiReports from 'api/reports'
import apiDataReport from 'api/report-data'

import { LoadingOverlay, FlexView, Icon, Card } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import StockTabs from 'containers/layout/StockTabs'
import ExportModal from 'containers/common/ExportModal'
import UnitSelect from 'containers/common/UnitSelect'

import { generateSpreadsheet } from 'provider/excelGeneration'

import { ExportContext } from 'stores/ExportStore'
import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'

import { reports_uri, filenames, dispatchTypeId, internalReportsType } from 'utils/constants'
import { getFileName } from 'utils/helpers'

const formatReports = reports => _.chain(reports)
  .filter(({ owner_name }) => owner_name)
  .map((report, index, filteredReports) => ({
    ...report,
    name: `Delivery_Notes_${report.closure_date ? moment(report.closure_date).format('DD_MM_YY') : 'Undated'}_${report.owner_name || ''}`,
  }))
  .orderBy('closure_date', 'desc')
  .value()

const DeliveryNotesPage = ({ stockKey }) => {
  const { t } = useTranslation()
  const { stocks, setPageSubtitle, datasets } = useContext(AppContext)
  const { endUsers, idToken } = useContext(UserContext)
  const stock = useMemo(() => _.find(stocks, { key: stockKey }), [stocks, stockKey])
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)
  const [showExportModal, setShowExportModal] = useState(false)
  const toggleExportModal = () => setShowExportModal(currentState => !currentState)
  const { setIsPdf } = useContext(ExportContext)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(t(`stock.names.${stock.key}`) + ' - ' + t('Delivery Notes'))
    }
  }, [stock, setPageSubtitle, t])

  const deliveryNotesReportDataMap = {
    [dispatchTypeId.Delivery3rdParty]: {
      reportDataFunction: (report) => apiDataReport.getDeliveryNotesDeliveryThirdPartyReportData({
        reportId: report.id,
        revision: report.revision,
        ownerId: report.owner_id,
        coPuchaseOrder: report.co_purchase_order
      }, idToken),
      filename: (reportId) => getFileName(filenames['DELIVERY_NOTES_DELIVERY_TO_THIRD_PARTY'], reportId),
    },
    [dispatchTypeId.MaterialModification]: {
      reportDataFunction: (report) => apiDataReport.getDeliveryNotesMatModificationReportData({
        reportId: report.id,
        revision: report.revision,
        ownerId: report.owner_id,
        coPuchaseOrder: report.co_purchase_order
      }, idToken),
      filename: (reportId) => getFileName(filenames['DELIVERY_NOTES_MATERIAL_MODIFICATION'], reportId),
    },
    [dispatchTypeId.RigPreparation]: {
      reportDataFunction: (report) => apiDataReport.getDeliveryNotesRigPrepReportData({
        reportId: report.id,
        revision: report.revision,
        ownerId: report.owner_id,
        coPuchaseOrder: report.co_purchase_order
      }, idToken),
      filename: (reportId) => getFileName(filenames['DELIVERY_NOTES_RIG_PREP'], reportId),
    },
    [dispatchTypeId.MaterialPhysicalTransfer]: {
      reportDataFunction: (report) => apiDataReport.getDeliveryNotesMatPhysicalTransferReportData({
        reportId: report.id,
        revision: report.revision,
        ownerId: report.owner_id,
        coPuchaseOrder: report.co_purchase_order
      }, idToken),
      filename: (reportId) => getFileName(filenames['DELIVERY_NOTES_MATERIAL_PHYSICAL_TRANSFER'], reportId),
    }
  }

  const getReportData = useCallback(async (report) => {
    setLoading(true)
    try {
      const fetchDataFunction = deliveryNotesReportDataMap[report.dispatch_type_id].reportDataFunction
      const data = await fetchDataFunction(report)
      const reportFileName =  deliveryNotesReportDataMap[report.dispatch_type_id].filename(report.id)
      await generateSpreadsheet(reportFileName, data)
      toast.info(t('documents.popupInfo'))
    } catch (error) {
      console.log(error)
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [deliveryNotesReportDataMap, t])

  const onDownloadClick = useCallback(report => () => {
    getReportData(report)
  }, [getReportData])

  useEffect(() => {
    setLoading(true)
    apiReports.getDeliveryNotesList(reports_uri.GET_DELIVERY_NOTES, {}, idToken).then(data => {
      setReports(formatReports(data))
    }).catch(error => {
      toast.error(error.message)
    }).finally(() => {
      setLoading(false)
    })
  }, [endUsers, datasets, idToken])

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      }
    },
    {
      accessor: 'name',
      Header: t('Name'),
      customHeaderProps: {
        style: {
          minWidth: '250px'
        }
      }
    },
    {
      accessor: 'dispatch_type',
      Header: t('Material Requisition Type'),
      customHeaderProps: {
        style: {
          minWidth: '250px'
        }
      }
    },
    {
      accessor: 'co_purchase_order',
      Header: t(' Customer PO#'),
      customHeaderProps: {
        style: {
          minWidth: '250px'
        }
      }
    },
    {
      Header: t('Delivery Notes Date'),
      accessor: ({ closure_date }) => closure_date ? moment(closure_date).format('DD/MM/YYYY') : '',
      sortType: 'date',
      customHeaderProps: {
        style: {
          minWidth: '160px'
        }
      }
    },
    {
      accessor: 'mr_ref',
      Header: t('MR Reference'),
      customHeaderProps: {
        style: {
          minWidth: '200px'
        }
      }
    },
    {
      id: 'download',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(row.original)} />
      </FlexView>
    }
  ], [t, onDownloadClick])

  const onExcelExportClick = () => {
    setShowExportModal(prev => !prev)
  }

  const onPdfExportClick = () => {
    setIsPdf(true)
    setShowExportModal(prev => !prev)
  }

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <UnitSelect />
        <Icon name="xls" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Export to Excel')} onClick={onExcelExportClick} />
        <Icon name="pdf" width="28px" height="28px" margin="0px 0px 0px 0px" tooltip={t('Export to PDF')} onClick={onPdfExportClick} />
      </FlexView>
    </FlexView>
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <FilterableTable columns={columns} data={reports} />
    </Card>
    <LoadingOverlay visible={loading} />
    <ExportModal title={t('Extract Consumption Report')} label="" isInterval={true} isOpen={showExportModal} type={internalReportsType.CONSUMPTION } onOutsideClick={toggleExportModal} />
  </FlexView>

}

export default DeliveryNotesPage