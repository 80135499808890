import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import styled from 'styled-components'

import blockedStock from 'api/blocked-stock'

import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import ResponsiveFilterableTable from 'components/common/ResponsiveFilterableTable'

import BlockedCardContainer from 'containers/blocked-stock/BlockedCardContainer'

import { DialogModal, Select, Toggle } from 'components/form'

import StockTabs from 'containers/layout/StockTabs'
import UploadContainer from 'containers/blocked-stock/UploadContainer'

import { useWindowSize } from 'hooks/useWindowSize'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { BlockedStockContext } from 'stores/BlockedStockStore'

const PageHeader = styled(FlexView)`
   flex-direction: row;
   align-items: center; 
   justify-content: flex-end; 
   align-self: stretch; 
   flex: 1;
   margin: 0px 0px 0px 15px;
`

const BlockedStockPage = ({ stockKey }) => {
    const { t } = useTranslation()

    const { stocks, setPageSubtitle, setWid, endUser, setEndUser, pageLoading, setPageLoading } = useContext(AppContext)
    const { idToken, inputEndUsers } = useContext(UserContext)
    const { blockedStockData, getBlockedStockData } = useContext(BlockedStockContext)

    const [showModal, setShowModal] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [idToDelete, setIdToDelete] = useState(null)
    const [deadSpace, setDeadSpace ] = useState(0)
    const { height } = useWindowSize(0)

    const stock = useMemo(() => _.find(stocks, { key: stockKey }), [stocks, stockKey])

    useEffect(() => {
        setDeadSpace(400)
    }, [])
      
    useEffect(() => {
        if (stock) {
            setWid(stock.wid)
            setEndUser(_.first(inputEndUsers).value ? _.first(inputEndUsers).value : null)
            setPageSubtitle(t(`stock.names.${stock.key}`) + ' - ' + t('Blocked Stock Status'))
        }
    }, [stock, setPageSubtitle, t, setWid, setEndUser, inputEndUsers])
      
    const deleteStock = useCallback( async () => {
        try {
            if(idToDelete){
                setShowModal(false)
                setPageLoading(true)
                const response = await blockedStock.deletePlannedStock({ "id": idToDelete }, idToken)
                if(response){
                    await getBlockedStockData()
                    setPageLoading(false)
                    toast.success(t('Data removed successfully')+'!')
                }
            }
        }
        catch(error){
          toast.error(error.message)
          setPageLoading(false)
        }
    }, [getBlockedStockData, idToDelete, idToken, setPageLoading, t])

    const onDeleteClick = useCallback(async (id) =>{
        setIdToDelete(id)
        setShowModal(true)
    }, [])
    const hideModal = () => setShowModal(false)

    const columns = useMemo(() => [
        {
            accessor: 'erp_ref',
            Header: t('SAP Reference'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'co_purchase_order',
            Header: t('Purchase Order'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'co_purchase_item',
            Header: t('Purchase Item'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'planned_quantity',
            Header: t('Planned Stock'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'blocked_quantity',
            Header: t('Blocked Stock'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }, 
            Cell: ({ cell: { value, row } }) => ( value > 0 ? <font> { value } </font> : <font color="red" style={{ fontWeight: 600 }}> { value } </font> ) 
        },
        {
            accessor: 'entered_quantity',
            Header: t('Entered Stock'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        }, 
        {
        id: 'delete',
        Header: '',
        disableFilters: true,
        customCellProps: {
            style: {
                textAlign: 'center'
            }
        },
        Cell: ({ cell: { value, row } }) => ( <Button alignItems="center" justifyContent="center" width="100%"  backgroundColor="transparent" margin="0px" padding="0px" onClick={ () => { onDeleteClick(row.original.id_planned) }} disabled={!row.original.id_planned}>
            <Icon name="trash" color="error" width="20px" height="20px" margin="0px 16px 0px 0px" />
        </Button>
        ), 
        show: true
        }
    ], [onDeleteClick, t])
    
    const plannedStockQty = useMemo(() => _.sumBy(blockedStockData, (b) => b.planned_quantity), [blockedStockData])
    const blockedStockQty = useMemo(() => _.sumBy(blockedStockData, (b) => b.blocked_quantity), [blockedStockData])
    const enteredStockQty = useMemo(() => _.sumBy(blockedStockData, (b) => b.entered_quantity), [blockedStockData])
    const blockedPercentage = useMemo(() => plannedStockQty ? ((blockedStockQty / plannedStockQty) * 100).toFixed(2) : 0, [blockedStockQty, plannedStockQty])

    useEffect(() => {
        getBlockedStockData()
    }, [getBlockedStockData])

    const validateWhatDisplay = () => {
        if(isAdmin){
            return <UploadContainer height={height - deadSpace} />  
        }
        else {
            return <>
            <BlockedCardContainer plannedQty={plannedStockQty} blockedQty={blockedStockQty} enteredQty={enteredStockQty} blockedPercentage={blockedPercentage}> </BlockedCardContainer>
            <Card alignSelf="stretch" padding="0px" margin="16px">
                <ResponsiveFilterableTable columns={columns} data={blockedStockData} deadSpace={deadSpace+100}/>
            </Card>
            </>
        }
    }

    return <FlexView flex="1" position="relative" alignSelf="stretch">
        <StockTabs stock={stock} />
        <FlexView flexDirection="row" justifyContent="space-between" alignItems="flex-end" alignSelf="stretch" padding="16px 16px 0 16px" >
            <PageHeader>
                <Select margin="0px 15px 0px 0px" value={endUser} options={inputEndUsers} onChange={setEndUser} placeHolder={endUser ? endUser : t('Select an option')} />
                <Toggle textOff={t('kpis.customer_view_uc')} textOn={t('kpis.administrator_view_uc')} state={isAdmin} onChange={setIsAdmin} alternate />
            </PageHeader>
        </FlexView>
        {validateWhatDisplay()}
        <LoadingOverlay visible={ pageLoading } />
        <DialogModal isOpen={showModal} title={t('Confirm')}
                     text={t('confirmDeletion')} onConfirm={deleteStock} onCancel={hideModal} danger
        />
    </FlexView>
}

export default BlockedStockPage