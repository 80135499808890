import React, { useContext, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import _ from 'lodash'

import { AppContext } from 'stores/AppStore'

import { FlexView, Icon } from 'components/common'

import UnitSelect from 'containers/common/UnitSelect'
import PipeTable from 'containers/pipe-data/PipeTable'
import ProductDescriptionCard from 'containers/pipe-data/ProductDescriptionCard'

import { StockContext } from 'stores/StockStore'

const PipeDataPage = ({ stockKey }) => {
  const { t } = useTranslation()
  const { stocks, setPageSubtitle } = useContext(AppContext)
  const { pipeData, pipeNominalData, loading } = useContext(StockContext)
  const stock = useMemo(() => _.find(stocks, { key: stockKey }), [stocks, stockKey])
  const history = useHistory()

  useEffect(() => {
    if (stock) {
      setPageSubtitle(t(`stock.names.${stock.key}`) + ' - ' + t('Pipe Data'))
    }
  }, [stock, setPageSubtitle, t])

  useEffect(() => {
    if (!(loading.pipeData || loading.pipeNominalData || pipeData.length || !_.isEmpty(pipeNominalData))) {
      toast.error(t('No Pipe Data Found'))
      history.replace(`/${stock.baseUrl}/service-stock`)
    }
  }, [pipeData, pipeNominalData, loading, history, stock.baseUrl, t])

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <FlexView margin="16px 16px 0px" flexDirection="row" justifyContent="space-between" alignItems="center" alignSelf="stretch">
      <FlexView flexDirection="row" fontSize="subtitle" color="gray" fontWeight="bold">
        <Icon name="arrow-left" width="24px" height="24px"  margin="0px 16px 0px 0px" onClick={history.goBack} />
        <div style={{ lineHeight: 1 }}>{t('Pipe Data')}</div>
      </FlexView>
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end">
        <UnitSelect />
        <Icon name="xls" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Export to Excel')} />
      </FlexView>
    </FlexView>
    <ProductDescriptionCard />
    <PipeTable />
  </FlexView>
}

export default PipeDataPage