import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'

import moment from 'moment'

import apiReports from 'api/reports'
import dataport from 'api/dataport'
import templateDataport from 'api/template-dataport'

import { LoadingOverlay, FlexView, Icon, Card } from 'components/common'

import FilterableTable from 'components/common/FilterableTable'

import ExportModal from 'containers/common/ExportModal'
import UnitSelect from 'containers/common/UnitSelect'
import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { ExportContext } from 'stores/ExportStore'
import { UserContext } from 'stores/UserStore'

import { getMillReceiptReportQuery } from 'utils/dataport-query'
import { getMillReceiptDownloadQuery } from 'utils/template-dataport-query'

import { internalReportsType } from 'utils/constants'
import { reports_uri } from 'utils/constants'


const loadFile = url => {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.click()
}

const formatReports = reports => _.chain(reports)
  .orderBy('entrance_date', 'desc')
  .value()

const MillReceiptsPage = ({ stockKey }) => {
  const { t } = useTranslation()
  const { stocks, setPageSubtitle, datasets, dataportTemplates, lengthUnit, weightUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit, formatNumberByCulture } = useContext(AppContext)
  const { endUsers, idToken } = useContext(UserContext)
  const stock = useMemo(() => _.find(stocks, { key: stockKey }), [stocks, stockKey])
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)

  const [showExportModal, setShowExportModal] = useState(false)

  const toggleExportModal = () => setShowExportModal(currentState => !currentState)
  
  const { setIsPdf } = useContext(ExportContext)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(t(`stock.names.${stock.key}`) + ' - ' + t('Mill Receipts'))
    }
  }, [stock, setPageSubtitle, t])

  const downloadReport = useCallback(async report => {
    setLoading(true)
    try {
      const query = getMillReceiptReportQuery(report.beid, report.revision, datasets)
      const jobId = await dataport.getJobId(query)
      const downloadQuery = getMillReceiptDownloadQuery(jobId, datasets, dataportTemplates, report.beid)
      const { url } = await templateDataport.loadTemplate(downloadQuery, idToken)
      loadFile(url)
      toast.info(t('documents.popupInfo'))
    }
    catch (error) {
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [datasets, dataportTemplates, idToken, t])

  const onDownloadClick = useCallback(beid => () => {
    downloadReport(beid)
  }, [downloadReport])

  useEffect(() => {
    setLoading(true)
    apiReports.getMillReceiptList(reports_uri.GET_MILL_RECEIPT, {}, idToken).then(data => {
      setReports(formatReports(data))
    }).catch(error => {
      toast.error(error.message)
    }).finally(() => {
      setLoading(false)
    })
  }, [endUsers, datasets, idToken])

  const columns = useMemo(() => [
    {
      accessor: 'beid',
      Header: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '40px'
        }
      }
    },
    {
      Header: t('PO #'),
      accessor: 'co_purchase_order',
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('PO Item #'),
      accessor: 'co_purchase_item',
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('SO #'),
      accessor: 'vtsa_sales_order',
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('SO Item #'),
      accessor: 'vtsa_order_item',
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Entrance Date'),
      accessor: ({ entrance_date }) => entrance_date ? moment(entrance_date).format('MMM D, YYYY, h:mm:ss A') : '',
      // Cell: ({ cell: { value } }) => value ? moment(value).format('MMM D, YYYY, h:mm:ss A') : '',
      sortType: 'date',
      customHeaderProps: {
        style: {
          minWidth: '120px'
        }
      }
    },
    {
      Header: t('MESC/CAT ID'),
      accessor: 'cat_id',
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
    },
    {
      Header: t('Material Description'),
      accessor: 'dsc_material',
      customHeaderProps: {
        style: {
          minWidth: '250px'
        }
      },
    },
    {
      Header: `${t('Pieces')}`,
      accessor: ({ pipe_count }) => `${formatNumberByCulture(pipe_count, 0)}`,
      // accessor: 'pipe_count',
      customHeaderProps: {
        style: {
          minWidth: '40px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Total Length') + ` (${lengthUnit})`,
      id: 'length',
      accessor: ({ pipes_length_m, pipes_length_ft }) => `${convertToCurrentLengthUnit(pipes_length_m, pipes_length_ft, lengthUnit)}`,
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: `${t('Weight') + ` (${weightUnit})`}`,
      id: 'weight',
      accessor: ({ pipes_weight }) => `${convertToCurrentWeightUnit(pipes_weight, 'kg')}`,
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      id: 'download',
      accessor: 'beid',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(row.original)} />
      </FlexView>
    }
  ], [t, onDownloadClick, weightUnit, lengthUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit, formatNumberByCulture])

  const onExcelExportClick = () => {
    setShowExportModal(prev => !prev)
  }

  const onPdfExportClick = () => {
    setIsPdf(true)
    setShowExportModal(prev => !prev)
  }

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <UnitSelect />
        <Icon name="xls" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Export to Excel')} onClick={onExcelExportClick} />
        <Icon name="pdf" width="28px" height="28px" margin="0px 0px 0px 0px" tooltip={t('Export to PDF')} onClick={onPdfExportClick} />
      </FlexView>
    </FlexView>
    <LoadingOverlay visible={loading} />
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <FilterableTable columns={columns} data={reports} />
    </Card>
    <ExportModal title={t('Extract Receipt Report') } label={t('Choose the search period')+':'} type={internalReportsType.RECEIPT } isOpen={showExportModal} onOutsideClick={toggleExportModal} />
  </FlexView>
}

export default MillReceiptsPage