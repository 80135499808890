import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Manager, Reference, Popper } from 'react-popper'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import styled from 'styled-components'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import MonthRangeView from './MonthRangeView'
import YearView from './YearView'
import TimeInput from './TimeInput'
import DateDisplay from './DateDisplay'
import { FlexView, Icon } from '../../common'

const Wrapper = styled.div`
  width: fit-content;
  font-family: 'Roboto';
  display: flex;
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  opacity: ${({ open }) => open ? '1' : '0'};
  transform: ${({ popperTransform, open }) => `${popperTransform} ${open ? 'translateX(0)' : 'scale(0.9)'} !important`};
  flex-direction: column;
  padding: 8px;
  background: #FFFFFF;
  box-shadow: ${({ theme }) => theme.boxShadows.high };
  overflow-y: auto;
  border-radius: 8px;
  z-index: 999;
  transition: all 0.2s ease;
`

const DateInput = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px 8px 8px;
  margin: ${({ label }) => label ? '8px 0px' : '0px'};
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  outline: none;

  span {
    color: ${({ theme }) => theme.colors.metalic};
  }
`

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  white-space: nowrap;
  font-weight: bold;
  margin-right: ${({ inline }) => inline ? '8px' : '0px'};
`

const DateRangePickerPopper = React.forwardRef(({ style, placement, isOpen, viewMode, renderView, timePicker, scheduleUpdate, t, startValue, startTime, onStartTimeChange, endValue, endTime, onEndTimeChange }, ref) => {
  useEffect(() => {
    scheduleUpdate()
  }, [isOpen, scheduleUpdate])

  return <Wrapper ref={ref} style={style} data-placement={placement} open={isOpen} popperTransform={style.transform}>
    <SwitchTransition>
      <CSSTransition
        key={viewMode}
        timeout={300}
        classNames="fade"
      >
        {renderView()}
      </CSSTransition>
    </SwitchTransition>
    <FlexView width="calc(100% - 16px)" flexDirection="row" alignItems="flex-end" justifyContent="space-between" padding="8px">
      <DateDisplay label={t('export_modal.date_from')} date={startValue} />
      {timePicker && <TimeInput value={startTime} onChange={onStartTimeChange} />}
    </FlexView>
    <FlexView width="calc(100% - 16px)" flexDirection="row" alignItems="flex-end" justifyContent="space-between" padding="8px">
      <DateDisplay label={t('export_modal.date_to')} date={endValue} />
      {timePicker && <TimeInput value={endTime} onChange={onEndTimeChange} />}
    </FlexView>
  </Wrapper>
})

const DateRangePicker = ({ startValue, endValue, onStartChange, onEndChange, timePicker, format, label, inline, margin }) => {
  const node = useRef(null)
  const { t } = useTranslation()
  const [startTime, setStartTime] = useState(startValue ? startValue.format('HH:mm') : '00:00')
  const [endTime, setEndTime] = useState(endValue ? endValue.format('HH:mm') : '23:59')
  const [currentView, setCurrentView] = useState(moment())
  const [viewMode, setViewMode] = useState('MONTH')
  const [isOpen, setOpen] = useState(false)
  const displayFormat = format || (timePicker ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')

  const toggleDatePicker = () => {
    startValue && setCurrentView(moment(startValue))
    setOpen(isOpen => !isOpen)
  }

  const onDayClick = day => {
    if (!startValue) {
      onStartChange(moment(`${day.format('DD/MM/YYYY')} ${startTime}`, 'DD/MM/YYYY HH:mm'))
    }
    else if (!endValue) {
      if (startValue.isBefore(day)) {
        onEndChange(moment(`${day.format('DD/MM/YYYY')} ${endTime}`, 'DD/MM/YYYY HH:mm'))
      }
      else {
        onEndChange(moment(`${startValue.format('DD/MM/YYYY')} ${endTime}`, 'DD/MM/YYYY HH:mm'))
        onStartChange(moment(`${day.format('DD/MM/YYYY')} ${startTime}`, 'DD/MM/YYYY HH:mm'))
      }
    }
    else {
      onStartChange(moment(`${day.format('DD/MM/YYYY')} ${startTime}`, 'DD/MM/YYYY HH:mm'))
      onEndChange(null)
    }
  }

  const onStartTimeChange = newTime => {
    setStartTime(newTime)
    onStartChange(moment(`${startValue.format('DD/MM/YYYY')} ${newTime}`, 'DD/MM/YYYY HH:mm'))
  }

  const onEndTimeChange = newTime => {
    setEndTime(newTime)
    onEndChange(moment(`${endValue.format('DD/MM/YYYY')} ${newTime}`, 'DD/MM/YYYY HH:mm'))
  }

  const toggleView = () => {
    setViewMode(currentViewMode => currentViewMode === 'MONTH' ? 'YEAR' : 'MONTH')
  }

  const onMonthClick = month => {
    setCurrentView(moment(month))
    setViewMode('MONTH')
  }

  const onPrevMonth = () => {
    setCurrentView(moment(currentView).subtract(1, 'month'))
  }

  const onNextMonth = () => {
    setCurrentView(moment(currentView).add(1, 'month'))
  }

  const onPrevYear = () => {
    setCurrentView(moment(currentView).subtract(1, 'year'))
  }

  const onNextYear = () => {
    setCurrentView(moment(currentView).add(1, 'year'))
  }

  const renderView = () => (
    viewMode === 'MONTH' ? <MonthRangeView startValue={startValue} endValue={endValue} onDayClick={onDayClick} onMonthClick={toggleView} currentMonth={currentView} onPrevMonth={onPrevMonth} onNextMonth={onNextMonth} />
    : <YearView value={startValue} onMonthClick={onMonthClick} onYearClick={toggleView} currentYear={currentView} onPrevYear={onPrevYear} onNextYear={onNextYear} />
  )

  const getStartPlaceholder = () => startValue ? startValue.format(displayFormat) : <span>{displayFormat}</span>

  const getEndPlaceholder = () => endValue ? endValue.format(displayFormat) : <span>{displayFormat}</span>

  const handleOutsideClick = e => {
    node && node.current && !node.current.contains(e.target) && setOpen(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    setViewMode('MONTH')
  }, [isOpen])

  return (
    <Manager>
      <FlexView
        flexDirection={inline ? 'row' : 'column'}
        alignItems={inline ? 'center' : 'flex-start'}
        justifyContent="flex-start"
        position="relative"
        margin={margin}
        width="fit-content"
        ref={node}
      >
        {label && <Label inline={inline}>{label}</Label>}
        <Reference>
          {({ ref }) => (
            <DateInput ref={ref} onClick={toggleDatePicker} label={label}>
              <Icon name="calendar" width="24px" height="24px" margin="0px 16px 0px 0px" />
              {getStartPlaceholder()}
              <Icon name="arrow-right" width="16px" height="16px" onClick={onNextMonth} margin="0px 16px" />
              {getEndPlaceholder()}
            </DateInput>
          )}
        </Reference>
        <Popper
          placement="right"
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          {({ ref, style, scheduleUpdate, placement }) => <DateRangePickerPopper {...{ style, placement, isOpen, viewMode, renderView, timePicker, scheduleUpdate, t, startValue, startTime, onStartTimeChange, endValue, endTime, onEndTimeChange, ref }} />}
        </Popper>
      </FlexView>
    </Manager>
  )
}

DateRangePicker.propTypes = {
  /**
   * Selected start date value, a moment object
   */
  startValue: PropTypes.any,
  /**
   * Selected end date value, a moment object
   */
  endValue: PropTypes.any,
  /**
   * Function that is called when the start value is changed, being passed as parameter the selected date as a moment object
   */
  onStartChange: PropTypes.func,
  /**
   * Function that is called when the end value is changed, being passed as parameter the selected date as a moment object
   */
  onEndChange: PropTypes.func,
  /**
   * Defines if the component should allow the time to be chosen. If not, defaults the moment object time to 00:00
   */
  timePicker: PropTypes.bool,
  /**
   * Format that the moment objects should be displayed, defaults to 'DD/MM/YYYY' (or 'DD/MM/YYYY HH:mm' with timePicker) when null
   */
  format: PropTypes.string,
  /**
   * Label that accompanies the input
   */
  label: PropTypes.string,
  /**
   * Defines if the label should be rendered in the same line as the input
   */
  inline: PropTypes.bool,
  /**
   * Override CSS margin property. Must be a valid CSS margin value as a string
   */
  margin: PropTypes.string
}

DateRangePicker.defaultProps = {
  width: 'fit-content',
  margin: '8px 0px'
}

export default DateRangePicker