import React, { useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'

import blockedStock from 'api/blocked-stock'

import { AppContext } from './AppStore'
import { UserContext } from './UserStore'

const initialState = {
  schemaErrors: [], 
  uploadData: [], 
  file: [],
  isSaving: false, 
  enable: false,
  blockedStockData: []
}

export const BlockedStockContext = React.createContext(initialState)

export const BlockedStockProvider = ({ children }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const { idToken } = useContext(UserContext)
  const { setPageLoading, wid, endUser } = useContext(AppContext)

  const [ file, setFile ] = useState([])
  const [ schemaErrors, setSchemaErrors ] = useState([])
  const [ uploadData, setUploadData ] = useState([]) 
  const [blockedStockData, setBlockedStockData] = useState([])

  const [ isSaving, setIsSaving ] = useState(false)
  const [ enable, setEnable ] = useState(true)
 
  history.listen((location, action) => {
    init()
  })

  const init = useCallback(()=> {
    setFile([])
    setSchemaErrors([])
    setUploadData([])
    setIsSaving(false)
    setEnable(true)
    setBlockedStockData([])
  }, [])  

  const getBlockedStockData = useCallback(async () => {
    try {
        if (wid && endUser) {
            setPageLoading(true)
            const response = await blockedStock.getCompleteStock({ "wid": wid, "end_user": endUser}, idToken)
            if(response){
                const sortedResponse = _.orderBy(response?.stock, ['erp_ref'], 'desc')
                setBlockedStockData(sortedResponse)
            }
        }
    } catch (error) {
        setPageLoading(false)
        console.error(error)
        toast.error(error.message || error)
    }
    finally {
        setPageLoading(false)
    }
  }, [wid, endUser, setPageLoading, idToken])
  
  const saveUploadedData = useCallback( async()=> {
    try {
      setPageLoading(true)
      setIsSaving(true)
      const params = {  
        "data": uploadData.map(obj=> ({ ...obj, end_user: endUser, wid: wid }))
      }
      const response = await blockedStock.upsertPlannedStock( params, idToken)
      if(response){
        await getBlockedStockData()
        setIsSaving(false)
        setPageLoading(false)
        toast.success(t('File upload successfully')+"!")
        init()
      }
    }
    catch(error){
      toast.error(error.message)
      setEnable(false)
      setPageLoading(false)
      console.log(error)
      setTimeout(() => setEnable(true), 2000)
    }
  }, [setPageLoading, uploadData, idToken, endUser, wid, getBlockedStockData, t, init])
  
  return <BlockedStockContext.Provider value={{
    ...initialState,
    schemaErrors, setSchemaErrors,
    uploadData, setUploadData, 
    isSaving, setIsSaving, 
    saveUploadedData, 
    enable,
    file, setFile, 
    blockedStockData, setBlockedStockData, 
    getBlockedStockData
  }}>
    {children}
  </BlockedStockContext.Provider>
}