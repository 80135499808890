import * as XLSX from 'xlsx'

const EXCEL_EXTENSION = '.xlsx'

export const exportAsExcelFile = (json, excelFileName) => {
    const worksheet = XLSX.utils.json_to_sheet(json)
    const workbook = { Sheets: { 'Data': worksheet }, SheetNames: ['Data'] }

    const options = { bookType: 'xlsx', type: 'array' }
    XLSX.writeFile(workbook, excelFileName + EXCEL_EXTENSION, options)
}

export const exportAsExcelFileMultiSheet = (json, excelFileName) => {
    const workbook = { SheetNames: [], Sheets: {} }

    json.forEach(function (row) {
        const sheetName = row.name
        const worksheet = XLSX.utils.json_to_sheet(row.data)

        workbook.SheetNames.push(sheetName)
        workbook.Sheets[sheetName] = worksheet
    })

    const options = { bookType: 'xlsx', type: 'array' }
    XLSX.writeFile(workbook, excelFileName + EXCEL_EXTENSION, options)
}
