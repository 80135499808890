import React from 'react'
import { AppProvider } from './stores/AppStore'
import { UserProvider } from './stores/UserStore'
import { ThemeProvider } from 'styled-components'
import { Analytics, Auth } from 'aws-amplify'
import awsConfig from './aws'
import theme from './utils/theme'
import Router from './Router'
import './i18n'
import { toast, Slide } from 'react-toastify'
import { ExportProvider } from 'stores/ExportStore'

toast.configure({
  autoClose: 5000,
  draggable: false,
  transition: Slide,
  hideProgressBar: false,
  closeButton: false
})

Auth.configure(awsConfig)
Analytics.configure({ disabled: true })

const App = () => (
  <ThemeProvider theme={theme}>
    <AppProvider>
      <UserProvider>
        <ExportProvider>
          <Router />
        </ExportProvider>
      </UserProvider>
    </AppProvider>
  </ThemeProvider>
)

export default App
