import React, { useContext, useState, useMemo, useEffect, useCallback } from 'react'
import { Modal, Card, FlexView, Button, LoadingOverlay } from 'components/common'
import { TextArea, Select } from 'components/form'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { transformFileToBase64 } from 'utils/conversion'
import stockApi from 'api/stock'
import _ from 'lodash'
import { toast } from 'react-toastify'

const UploadModal = ({ isOpen, onOutsideClick, files, onFileUpload }) => {
  const { t } = useTranslation()
  const { currentUser, endUsers, idToken } = useContext(UserContext)
  const [description, setDescription] = useState('')
  const [endUser, setEndUser] = useState(null)
  const [loading, setLoading] = useState(false)

  const filterEndUserOptions = useMemo(() =>  endUsers.filter(value => value !== "VALLOUREC" && value !== "ADOS"), [endUsers])
  const endUserOptions = useMemo(() => filterEndUserOptions.map(value => ({ label: value, value })), [filterEndUserOptions])

  const file = useMemo(() => files.length ? files[0] : null, [files])

  useEffect(() => endUserOptions.length && setEndUser(endUserOptions[0].value), [endUserOptions])

  const onUploadClick = () => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = async (e) => {
      try {
        setLoading(true)
        const base64 = transformFileToBase64(e.target.result)
        const extension = `.${file.name.split('.').slice(-1)[0]}`
        const type = file.type
        await stockApi.uploadReport({
          content: base64,
          type,
          extension,
          fileDetails: {
            name: file.name,
            description,
            endUser,
            uploadedBy: currentUser.name
          }
        }, idToken)
        toast.success(t('documents.uploadSuccessful', { name: file.name }))
        setDescription('')
        onFileUpload()
        onOutsideClick()
      }
      catch (error) {
        console.log(error)
        toast.error(error.message)
      }
      finally {
        setLoading(false)
      }
    }
  }

  const onDescriptionChange = useCallback(event => setDescription(event.target.value), [])

  return (
    <Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
      <Card width="80vw" maxWidth="700px" position="relative">
      <LoadingOverlay visible={loading} borderRadius="card" />
        <FlexView fontSize="subtitle" fontWeight="bold" alignSelf="stretch">{t('Document Upload')}</FlexView>
        <FlexView fontSize="small" color="gray" alignSelf="stretch" margin="0px 0px 8px">{_.get(file, 'name', '')}</FlexView>
        <TextArea label={t('Description')} value={description} onChange={onDescriptionChange} width="100%" rows="10" />
        <Select label={t('End User')} options={endUserOptions} value={endUser} onChange={setEndUser} width="100%" />
        <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" alignSelf="stretch">
          <Button padding="6px 14px" onClick={onOutsideClick} outline>{t('Cancel')}</Button>
          <Button backgroundColor="primary" color="white" margin="0px 0px 0px 8px" onClick={onUploadClick} disabled={description.length === 0 || !endUser}>{t('Upload')}</Button>
        </FlexView>
      </Card>
    </Modal>
  )
}

export default UploadModal
