import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import dataport from 'api/dataport'
import templateDataport from 'api/template-dataport'
import apiReports from 'api/reports'

import { LoadingOverlay, FlexView, Icon, Card } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'

import { reports_uri } from 'utils/constants'
import { getRigReturnReportDataQuery } from 'utils/dataport-query'
import { getRigReturnReportDownloadQuery } from 'utils/template-dataport-query'

const loadFile = url => {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.click()
}

const formatReports = reports => _.orderBy(reports, 'closure_date', 'desc')

const RigReturnInspectionReportsPage = ({ stockKey }) => {
  const { t } = useTranslation()
  const { stocks, setPageSubtitle, datasets, dataportTemplates, formatNumberByCulture } = useContext(AppContext)
  const { endUsers, idToken } = useContext(UserContext)
  const stock = useMemo(() => _.find(stocks, { key: stockKey }), [stocks, stockKey])
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(t(`stock.names.${stock.key}`) + ' - ' + t('Rig Return Inspection Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const downloadReport = useCallback(async report => {
    setLoading(true)
    try {
      console.log(report)
      const query = getRigReturnReportDataQuery(report.rrlid, report.revision, datasets)
      const jobId = await dataport.getJobId(query)
      const downloadQuery = getRigReturnReportDownloadQuery(jobId, datasets, dataportTemplates, report.rrlid)
      const { url } = await templateDataport.loadTemplate(downloadQuery, idToken)
      loadFile(url)
      toast.info(t('documents.popupInfo'))
    }
    catch (error) {
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [datasets, dataportTemplates, idToken, t])

  const onDownloadClick = useCallback(report => () => {
    downloadReport(report)
  }, [downloadReport])

  useEffect(() => {
    setLoading(true)
    apiReports.getRigReturnInspectionList(reports_uri.GET_RIG_RETURN, {}, idToken).then(data => {
      setReports(formatReports(data))
    }).catch(error => {
      toast.error(error.message)
    }).finally(() => {
      setLoading(false)
    })
  }, [endUsers, datasets, idToken])

  const columns = useMemo(() => [
    {
      accessor: 'rrlid',
      Header: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      }
    },
    {
      accessor: 'name',
      Header: t('Name'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      id: 'closure_date',
      Header: t('Rig Return Date'),
      accessor: ({ closure_date }) => closure_date ? moment(closure_date).format('MMM D, YYYY, h:mm:ss A') : '',
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      accessor: 'rr_ref',
      Header: t('MR Reference'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      // accessor: 'nt_quantity',
      accessor: ({ nt_quantity }) => `${formatNumberByCulture(nt_quantity, 0)}`,
      Header: t('Non Traceable'),
      customHeaderProps: {
        style: {
          minWidth: '100px'
        }
      }
    },
    {
      // accessor: 'pipe_count',
      accessor: ({ pipe_count }) => `${formatNumberByCulture(pipe_count, 0)}`,
      Header: t('Inspected'),
      customHeaderProps: {
        style: {
          minWidth: '70px'
        }
      }
    },
    {
      // accessor: 'accept_count',
      accessor: ({ accept_count }) => `${formatNumberByCulture(accept_count, 0)}`,
      Header: t('Accepted'),
      customHeaderProps: {
        style: {
          minWidth: '70px'
        }
      }
    },
    {
      // accessor: 'reject_count',
      accessor: ({ reject_count }) => `${formatNumberByCulture(reject_count, 0)}`,
      Header: t('Rejected'),
      customHeaderProps: {
        style: {
          minWidth: '70px'
        }
      }
    },
    {
      // accessor: 'repair_count',
      accessor: ({ repair_count }) => `${formatNumberByCulture(repair_count, 0)}`,
      Header: t('To Repair'),
      customHeaderProps: {
        style: {
          minWidth: '75px'
        }
      }
    },
    {
      id: 'download',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(row.original)} />
      </FlexView>
    }
  ], [t, onDownloadClick, formatNumberByCulture])

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <FilterableTable columns={columns} data={reports} />
    </Card>
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default RigReturnInspectionReportsPage