import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import dataport from 'api/dataport'
import templateDataport from 'api/template-dataport'
import apiReports from 'api/reports'

import { LoadingOverlay, FlexView, Icon, Card } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'

import { dispatchTypeId, reports_uri } from 'utils/constants'
import {
  getMaterialRequisitionReportDataFromDayQuery,
  getMatRequisitionDeliveryThirdPartyReportDataFromDayQuery,
  getMatRequisitionMatModificationReportDataFromDayQuery,
  getMatRequisitionMatPhysicalTransferReportDataFromDayQuery
} from 'utils/dataport-query'
import {
  getMatRequisitionMatModificationReportDownloadQuery,
  getMatRequisitionDeliveryThirdPartyReportDownloadQuery,
  getMaterialRequisitionReportDownloadQuery, 
  getMaterialRequisitionMatPhysicalTransferReportDownloadQuery
} from 'utils/template-dataport-query'

const loadFile = url => {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.click()
}

const nameReport = (dispatchTypeName, closure_date) => `${dispatchTypeName}_${closure_date ? moment(closure_date).format('DD_MM_YY') : 'Undated'}`

const materialRequisitionTypeMap = {
  [dispatchTypeId.Delivery3rdParty]: {
    dataQueryFunction: (report, datasets) => getMatRequisitionDeliveryThirdPartyReportDataFromDayQuery(report, datasets),
    downloadQueryFunction: (jobId, datasets, dataportTemplates, id) => getMatRequisitionDeliveryThirdPartyReportDownloadQuery(jobId, datasets, dataportTemplates, id),
    nameFunction: (closure_date) => nameReport('Delivery_to_3d_Party_workshop_Dispatch_Inspection_Report', closure_date)
  },
  [dispatchTypeId.MaterialModification]: {
    dataQueryFunction: (report, datasets) => getMatRequisitionMatModificationReportDataFromDayQuery(report, datasets),
    downloadQueryFunction: (jobId, datasets, dataportTemplates, id) => getMatRequisitionMatModificationReportDownloadQuery(jobId, datasets, dataportTemplates, id),
    nameFunction: (closure_date) => nameReport('Material_Modification_Dispatch_Inspection_Report', closure_date)
  },
  [dispatchTypeId.RigPreparation]: {
    dataQueryFunction: (report, datasets) => getMaterialRequisitionReportDataFromDayQuery(report, datasets),
    downloadQueryFunction: (jobId, datasets, dataportTemplates, id) => getMaterialRequisitionReportDownloadQuery(jobId, datasets, dataportTemplates, id),
    nameFunction: (closure_date) => nameReport('Rig_prep_Dispatch_Inspection_Report', closure_date)
  },
  [dispatchTypeId.MaterialPhysicalTransfer]: {
    dataQueryFunction: (report, datasets) => getMatRequisitionMatPhysicalTransferReportDataFromDayQuery(report, datasets),
    downloadQueryFunction: (jobId, datasets, dataportTemplates, id) => getMaterialRequisitionMatPhysicalTransferReportDownloadQuery(jobId, datasets, dataportTemplates, id),
    nameFunction: (closure_date) => nameReport('Material_Physical_Transfer_Inspection_Report', closure_date)
  }
}

const formatReports = (reports) => _.chain(reports)
  .map((report, index, filteredReports) => ({
    ...report,
    name: materialRequisitionTypeMap[report.dispatch_type_id].nameFunction(report.closure_date),
    rplc_ids: _.chain(filteredReports)
      .filter(({ id }) => id === report.id)
      .map(({ id }) => id)
      .value()
  }))
  .orderBy('closure_date', 'desc')
  .value()

const MaterialRequisitionInspectionReportsPage = ({ stockKey }) => {
  const { t } = useTranslation()
  const { stocks, setPageSubtitle, datasets, dataportTemplates } = useContext(AppContext)
  const { idToken } = useContext(UserContext)
  const stock = useMemo(() => _.find(stocks, { key: stockKey }), [stocks, stockKey])
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(t(`stock.names.${stock.key}`) + ' - ' + t('Material Requisition Inspection Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const downloadReport = useCallback(async report => {
    setLoading(true)
    try {
      const query = materialRequisitionTypeMap[report.dispatch_type_id].dataQueryFunction(report, datasets)
      const jobId = await dataport.getJobId(query)
      const downloadQuery = materialRequisitionTypeMap[report.dispatch_type_id].downloadQueryFunction(jobId, datasets, dataportTemplates, report.id)
      const { url } = await templateDataport.loadTemplate(downloadQuery, idToken)
      loadFile(url)
      toast.info(t('documents.popupInfo'))
    }
    catch (error) {
      console.log(error)
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [datasets, dataportTemplates, idToken, t])

  const onDownloadClick = useCallback(report => () => {
    downloadReport(report)
  }, [downloadReport])

  useEffect(() => {
    setLoading(true)
    apiReports.getMaterialRequisitionList(reports_uri.GET_MATERIAL_REQUISITION, {}, idToken).then(data => {
      console.log(data)
      setReports(formatReports(data))
    }).catch(error => {
      console.log(error)
      toast.error(error.message)
    }).finally(() => {
      setLoading(false)
    })
  }, [idToken])

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      }
    },
    {
      accessor: 'name',
      Header: t('Name'),
      customHeaderProps: {
        style: {
          minWidth: '250px'
        }
      }
    },
    {
      Header: t('Material Requisition Date'),
      accessor: ({ closure_date }) => closure_date ? moment(closure_date).format('DD/MM/YYYY') : '',
      sortType: 'date',
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      accessor: 'mr_ref',
      Header: t('MR Reference'),
      customHeaderProps: {
        style: {
          minWidth: '200px'
        }
      }
    },
    {
      id: 'download',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(row.original)} />
      </FlexView>
    }
  ], [t, onDownloadClick])

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <FilterableTable columns={columns} data={reports} />
    </Card>
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default MaterialRequisitionInspectionReportsPage