export const getFileName = (filename, reportId) => filename + reportId

export const truncateField = (values, numOfValuesToShow = 1) => {
    if (values && values.length === 1) {
        return values[0]
    }

    if (values && values[0]) {
        const valuesToShow = values.slice(0, numOfValuesToShow)
        const remainingValuesQtdy = values.length - numOfValuesToShow
        const valuesToShowText = valuesToShow.join(', ')
        return `${valuesToShowText} and ${remainingValuesQtdy} more...`
    }

    return ''
}