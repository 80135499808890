import React from 'react'
import { FlexView } from 'components/common'
import Header from 'containers/layout/Header'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from 'containers/router/PrivateRoute'
import { KpiProvider } from 'stores/KpiStore'
import { PipeAgeProvider } from 'stores/PipeAgeStore'
import { StockProvider } from 'stores/StockStore'
import Home from 'pages/Home'
import Login from 'pages/Login'
import StockPage from 'pages/Stock'
import PipeDataPage from 'pages/PipeData'
import MaterialRequisitionInspectionReportsPage from 'pages/MaterialRequisitionReports'
import MaterialRequisitionStatusPage from 'pages/MaterialRequisitionStatus'
import RigReturnInspectionReportsPage from 'pages/RigReturnInspectionReports'
import DeliveryNotesPage from 'pages/DeliveryNotes'
import InspectionReportsPage from 'pages/InspectionReports'
import MillReceiptsPage from 'pages/MillReceipts'
import ReceivingInspectionReports from 'pages/ReceivingInspectionReports'
import TrackingOfGoods from 'pages/TrackingOfGoods'
import KpisGlobalView from 'pages/KpisGlobalView'
import PeriodicalInspectionReports from 'pages/PeriodicalInspectionReports'
import PipeAgePage from 'pages/PipeAge'
import CollaborativePlannig from 'pages/CollaborativePlanning'
import SlowMovingPipesPage from 'pages/SlowMovingPipes'
import TitleTransferPage from 'pages/TitleTransfer'
import MaterialPhysicalTransferPage from 'pages/MaterialPhysicalTransfer'
import ModifiedProductsInspectionReports from 'pages/ModifiedProductsInspectionReports'
import ArchivedReports from 'pages/ArchivedReports'
import BlockedStockPage from 'pages/BlockedStock'
import { BlockedStockProvider } from 'stores/BlockedStockStore'

const Router = () => {
  return (
    <FlexView height="100vh">
      <BrowserRouter>
        <Header />
        <StockProvider>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <PrivateRoute path="/adnoc">
              <Switch>
                <PrivateRoute path="/adnoc/service-stock" exact>
                  <StockPage stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/service-stock/pipe-data" exact>
                  <PipeDataPage stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/tracking-goods" exact>
                  <TrackingOfGoods stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/inspection-reports" exact>
                  <InspectionReportsPage stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/mill-receipts" exact>
                  <MillReceiptsPage stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/receiving-inspection-reports" exact>
                  <ReceivingInspectionReports stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/periodical-inspection-reports" exact>
                  <PeriodicalInspectionReports stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/delivery-notes" exact>
                  <DeliveryNotesPage stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/material-requisition-inspection-reports" exact>
                  <MaterialRequisitionInspectionReportsPage stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/material-requisition-status" exact>
                  <MaterialRequisitionStatusPage stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/rig-return-inspection-reports" exact>
                  <RigReturnInspectionReportsPage stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/modified-products-inspection-reports" exact>
                  <ModifiedProductsInspectionReports stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/archived-reports" exact>
                  <ArchivedReports stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/slow-moving-pipes" exact>
                  <SlowMovingPipesPage stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/title-transfer" exact>
                  <TitleTransferPage stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/material-physical-transfer" exact>
                  <MaterialPhysicalTransferPage stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/collaborative-planning" exact>
                  <CollaborativePlannig stockKey="SERVICE_STOCK_ADNOC" />
                </PrivateRoute>
                <PrivateRoute path="/adnoc/pipe-age-view" exact>
                   <PipeAgeProvider>
                      <PipeAgePage stockKey="SERVICE_STOCK_ADNOC" /> 
                   </PipeAgeProvider>
                </PrivateRoute>
                <PrivateRoute path="/adnoc/slow-moving-pipes" exact>
                   <PipeAgeProvider>
                      <PipeAgePage stockKey="SERVICE_STOCK_ADNOC" /> 
                   </PipeAgeProvider>
                </PrivateRoute>
                <PrivateRoute path="/adnoc/title-transfer" exact>
                   <PipeAgeProvider>
                      <PipeAgePage stockKey="SERVICE_STOCK_ADNOC" /> 
                   </PipeAgeProvider>
                </PrivateRoute>
                <PrivateRoute path="/adnoc/material-physical-transfer" exact>
                   <PipeAgeProvider>
                      <PipeAgePage stockKey="SERVICE_STOCK_ADNOC" /> 
                   </PipeAgeProvider>
                </PrivateRoute>
                <PrivateRoute path="/adnoc/kpis" exact>
                  <KpiProvider>
                    <KpisGlobalView stockKey="SERVICE_STOCK_ADNOC" />
                  </KpiProvider>
                </PrivateRoute>
                <PrivateRoute path="/adnoc/blocked-stock" exact>
                  <BlockedStockProvider>
                  <BlockedStockPage stockKey="SERVICE_STOCK_ADNOC" />
                  </BlockedStockProvider>
                </PrivateRoute>
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </PrivateRoute>
            <PrivateRoute path="/adipec">
              <Switch>
                <PrivateRoute path="/adipec/service-stock" exact>
                  <StockPage stockKey="SERVICE_STOCK_ADIPEC" />
                </PrivateRoute>
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </PrivateRoute>
            <PrivateRoute path="/chevron">
              <Switch>
                <PrivateRoute path="/chevron/service-stock" exact>
                  <StockPage stockKey="SERVICE_STOCK_CHEVRON" />
                </PrivateRoute>
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </PrivateRoute>
            <PrivateRoute path="/bhp">
              <Switch>
                <PrivateRoute path="/bhp/service-stock" exact>
                  <StockPage stockKey="SERVICE_STOCK_BHP" />
                </PrivateRoute>
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </PrivateRoute>
            <PrivateRoute path="/total">
              <Switch>
                <PrivateRoute path="/total/service-stock" exact>
                  <StockPage stockKey="SERVICE_STOCK_TOTAL_MYANMAR" />
                </PrivateRoute>
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </PrivateRoute>
            <PrivateRoute path="/total-angola">
              <Switch>
                <PrivateRoute path="/total-angola/service-stock" exact>
                  <StockPage stockKey="SERVICE_STOCK_TOTAL_ANGOLA" />
                </PrivateRoute>
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </PrivateRoute>
            <PrivateRoute path="/eni">
              <Switch>
                <PrivateRoute path="/eni/service-stock" exact>
                  <StockPage stockKey="SERVICE_STOCK_ENI_MYANMAR" />
                </PrivateRoute>
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </PrivateRoute>
            <PrivateRoute path="/" exact>
              <PrivateRoute exact path='/'>
                <Home />
              </PrivateRoute>
              <Route>
                <Redirect to="/" />
              </Route>
            </PrivateRoute>
          </Switch>
        </StockProvider>
      </BrowserRouter>
    </FlexView>
  )
}

export default Router