import React, { useState, useContext, useCallback, useMemo } from 'react'
import dataport from 'api/dataport'
import stock from 'api/stock'
import { UserContext } from './UserStore'
import { AppContext } from './AppStore'
import { getNominalDataQuery, getPipeDataQuery } from 'utils/dataport-query'
import { toast } from 'react-toastify'
import { exportAsTablePdfFile } from 'utils/pdf'
import { exportAsExcelFile } from 'utils/excel'
import _ from 'lodash'
import moment from 'moment'

const sliceArrayInMultipleArrays = array => {
  const chunk = 250
  const arrOfArrays = []
  let temparray = []
  for (let i=0, j=array.length; i<j; i+=chunk) {
    temparray = array.slice(i,i+chunk)
    arrOfArrays.push(temparray)
  }
  return arrOfArrays
}

export const StockContext = React.createContext({})

export const StockProvider = ({ children }) => {
  const { datasets } = useContext(AppContext)
  const { idToken } = useContext(UserContext)
  const [stockData, setStockData] = useState({
    tableData: [],
    monthlyData: {
      in: [],
      out: [],
      res: []
    }
  })
  const [pipeData, setPipeData] = useState([])
  const [pipeNominalData, setPipeNominalData] = useState({})
  const [loading, setLoading] = useState({
    pipeData: false,
    pipeNominalData: false,
  })
  const [filters, setFilters] = useState({})

  const formatMultipleRequests = useCallback((arrOfQueries) => arrOfQueries.map(query => dataport.asyncQuery(query)), [])

  const getStockData = useCallback((stockRoute, wid = null) => new Promise((resolve, reject) => {
    setStockData({
      tableData: [],
      monthlyData: {
        in: [],
        out: [],
        res: []
      }
    })
    setFilters({})
    stock.getStockData(stockRoute, wid, idToken).then(data => {
      setStockData({
        ...data,
        tableData: _.map(data.tableData, item => ({
          ...item,
          entranceDateString: moment(item.entranceDate).format('DD/MM/YYYY')
        }))
      })
      resolve()
    }).catch(e => {
      reject(e)
    })
  }), [idToken])

  const getPipeNominalData = useCallback(sapReference => {
    const query = getNominalDataQuery(sapReference, datasets)
    setLoading(currentState => ({
      ...currentState,
      pipeNominalData: true
    }))
    dataport.asyncQuery(query).then(data => {
      data.length && setPipeNominalData(data[0] || {})
    }).catch(error => {
      setPipeNominalData({})
      toast.error(error.message)
    }).finally(() => {
      setLoading(currentState => ({
        ...currentState,
        pipeNominalData: false
      }))
    })
  }, [datasets])

  const getPipeData = useCallback( valids => {
    const arrOfValidArrays = sliceArrayInMultipleArrays(valids)
    const arrOfQueries = arrOfValidArrays.map(arrOfValids => getPipeDataQuery(arrOfValids, datasets))
    const arrOfRequests = formatMultipleRequests(arrOfQueries)
    setLoading(currentState => ({
      ...currentState,
      pipeData: true
    }))
    Promise.all(arrOfRequests).then(data => {
      setPipeData(data.flat())
    }).catch(error => {
      setPipeData([])
      toast.error(error.message)
    }).finally(() => {
      setLoading(currentState => ({
        ...currentState,
        pipeData: false
      }))
    })
  }, [datasets, formatMultipleRequests])

  const downloadCertificate = useCallback(path => new Promise((resolve, reject) => {
    stock.getCertificateDownloadUrl(path, idToken).then(url => {
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.click()
    }).catch(e => {
      reject(e)
    })
  }), [idToken])

  const filteredStockData = useMemo(() => {
    const filteredTableData = _.filter(stockData.tableData, item => {
      let match = true
      _.chain(filters)
        .pickBy(filter => filter.length > 0)
        .each((filter, key) => {
          const itemValue = _.get(item, key)
          match = match && _.includes(filter, itemValue)
        })
        .value()
      return match
    })
    return {
      ...stockData,
      tableData: filteredTableData
    }
  }, [filters, stockData])

  const exportToExcel = useCallback((columnsConfig, stockKey) => {
    const excelData = _.map(filteredStockData.tableData, row => (
      _.reduce(columnsConfig, (acc, { accessor, label, formatter }) => {
        const value = _.get(row, accessor, '')
        return {
          ...acc,
          [label]: formatter ? formatter(value) : value
        }
      }, {})
    ))
    exportAsExcelFile(excelData, `${stockKey} ${moment().format('YYYY-MM-DD HH-mm-ss')}`)
  }, [filteredStockData])

  const exportToPdf = useCallback((columnsConfig, stockKey) => {
    const header = {
        title: `${stockKey} ${moment().format('YYYY-MM-DD HH-mm-ss')}`,
        subTitle: ' ',
        logo: 'Smart'
    }
    const headers = _.map(columnsConfig, 'label')
    const body = _.map(filteredStockData.tableData, row => (
      _.reduce(columnsConfig, (acc, { accessor, formatter }) => {
        const value = _.get(row, accessor)
        return [
          ...acc,
          value ? (formatter ? formatter(value) : value) : '-'
        ]
      }, [])
    ))
    const reportData = [headers, ...body]
    const options = {
        pageOrientation: 'landscape',
        pageSize: 'A3',
        watermark: false,
        zebraStyle: true
    }
    exportAsTablePdfFile(header, reportData, options)
  }, [filteredStockData])

  return <StockContext.Provider value={{
    loading,
    stockData,
    filteredStockData,
    exportToExcel,
    exportToPdf,
    pipeData,
    pipeNominalData,
    filters,
    setFilters,
    downloadCertificate,
    getStockData,
    getPipeNominalData,
    getPipeData
  }}>
    {children}
  </StockContext.Provider>
}