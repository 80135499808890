import React, { useContext, useMemo, useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import { userGuide } from 'utils/constants'

import stockApi from 'api/stock'

import LogoImg from '../../assets/images/logo-small.svg'

import Header from '../../components/layout/Header'

import { UserContext } from '../../stores/UserStore'
import { AppContext } from '../../stores/AppStore'

const Logo = styled.div`
  align-self: center;
  text-align: center;
  font-family: Roboto;
  span {
    font-weight: 100;
  }
  span:first-child {
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 4px;
    font-weight: bold;
  }
  font-size: 24px;
  margin: 32px 0px 16px;
`

const AppHeader = () => {
  const { t } = useTranslation()
  const { isHeaderVisible, pageTitle, pageSubtitle } = useContext(AppContext)

  const { authState, signOut, currentUser, settings, idToken } = useContext(UserContext)
  const history = useHistory()

  const redirect = useCallback(path => () => {
    history.push(path)
  }, [history])

  const [userSettings, setUserSettings] = useState({})

  const downloadUserGuide = useCallback((key) => new Promise((resolve, reject) => {
    stockApi.getUserGuideDownloadUrl(key, process.env.REACT_APP_VERSION, idToken).then(url => {
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.click()
    }).catch(e => {
      reject(e)
    })
  }), [idToken])


  useEffect(() => {
    const options = [
      { key: 'version', label: 'v' + process.env.REACT_APP_VERSION, onClick: () => { } },
      { key: 'sign-out', label: t('Sign Out'), onClick: signOut, divider: true }
    ]
    _.map(settings, function (item, i) {
      if (item.settings.find(p => p.name === userGuide)) {
        options.splice(options.length - 1, 0,
          { key: item.name, label: t('User Guide') + ' - ' + item.description, divider: true, onClick: () => downloadUserGuide(item.name) }
        )
      }
    })
    setUserSettings({ username: currentUser ? currentUser.name : currentUser, options: options })

  }, [currentUser, signOut, t, setUserSettings, settings, downloadUserGuide])

  const defaultSideMenuOptions = useMemo(() => [
    {
      key: 'stock-adnoc',
      label: t('stock.names.SERVICE_STOCK_ADNOC'),
      role: 'SERVICE_STOCK_ADNOC',
      items: [
        {
          key: 'service-stock',
          icon: 'box',
          label: t('Service Stock'),
          role: 'id',
          onClick: redirect('/adnoc/service-stock')
        },
        {
          key: 'tracking-goods',
          icon: 'folder',
          label: t('Tracking of Goods'),
          role: 'TRACKING_OF_GOODS',
          onClick: redirect('/adnoc/tracking-goods')
        },
        {
          key: 'inspection-reports',
          icon: 'search',
          label: t('Inspection Reports'),
          role: '',
          onClick: redirect('/adnoc/inspection-reports')
        },
        {
          key: 'mill-receipts',
          icon: 'document',
          label: t('Mill Receipts'),
          role: 'INVENTORY_REPORT.MILL_RECEIPTS',
          onClick: redirect('/adnoc/mill-receipts')
        },
        {
          key: 'receiving-inspection-reports',
          icon: 'search',
          label: t('Receiving Inspection Reports'),
          role: 'INVENTORY_REPORT.RECEIVING_INSPECTION_REPORT',
          onClick: redirect('/adnoc/receiving-inspection-reports')
        },
        {
          key: 'periodical-inspection-reports',
          icon: 'search',
          label: t('Periodical Inspection Reports'),
          role: 'INVENTORY_REPORT.PERIODICAL_INSPECTION_REPORT',
          onClick: redirect('/adnoc/periodical-inspection-reports')
        },
        {
          key: 'delivery-notes',
          icon: 'message-open',
          label: t('Delivery Notes'),
          role: 'INVENTORY_REPORT.DELIVERY_NOTES',
          onClick: redirect('/adnoc/delivery-notes')
        },
        {
          key: 'rig-return-inspection-reports',
          icon: 'ship',
          label: t('Rig Return Inspection Reports'),
          role: 'INVENTORY_REPORT.RIG_RETURN_REPORT',
          onClick: redirect('/adnoc/rig-return-inspection-reports')
        },
        {
          key: 'archived-reports',
          icon: 'ship',
          label: t('Archived Reports'),
          role: 'HIDDEN_REPORTS',
          onClick: redirect('/adnoc/archived-reports')
        },
        {
          key: 'modified-products-inspection-reports',
          icon: 'ship',
          label: t('Modified Products Inspection Reports'),
          role: 'INVENTORY_REPORT.MODIFIED_PRODUCTS_INSPECTION_REPORT',
          onClick: redirect('/adnoc/modified-products-inspection-reports')
        },
        {
          key: 'material-requisition-inspection-reports',
          icon: 'ship',
          label: t('Material Requisition Inspection Reports'),
          role: 'INVENTORY_REPORT.RIG_PREP_REPORT',
          onClick: redirect('/adnoc/material-requisition-inspection-reports')
        },
        {
          key: 'material-requisition-status',
          icon: 'mr-status',
          label: t('Material Requisition Status'),
          role: 'INVENTORY_REPORT.MATERIAL_REQUISITION_STATUS',
          onClick: redirect('/adnoc/material-requisition-status')
        },
        {
          key: 'collaborative-planning',
          icon: 'folder',
          label: t('Collaborative Planning'),
          role: 'COLLABORATIVE_PLANING',
          onClick: redirect('/adnoc/collaborative-planning'),
        },
        {
          key: 'pipe-age-view',
          icon: 'pipe-age',
          label: 'Pipe Age View',
          role: 'PIPE_AGE_MANAGEMENT.PIPE_AGE_VIEW',
          onClick: redirect('/adnoc/pipe-age-view')
        },
        {
          key: 'slow-moving-pipes',
          icon: 'pipe-age',
          label: 'Slow Moving Pipes',
          role: 'PIPE_AGE_MANAGEMENT.SLOW_MOVING_PIPES',
          onClick: redirect('/adnoc/slow-moving-pipes')
        },
        {
          key: 'title-transfer',
          icon: 'pipe-age',
          label: 'Title Transfer',
          role: 'PIPE_AGE_MANAGEMENT.TITLE_TRANSFER',
          onClick: redirect('/adnoc/title-transfer')
        },
        {
          key: 'material-physical-transfer',
          icon: 'pipe-age',
          label: 'Material Physical Transfer',
          role: 'PIPE_AGE_MANAGEMENT.MATERIAL_PHYSICAL_TRANSFER',
          onClick: redirect('/adnoc/material-physical-transfer')
        },
        {
          key: 'kpi',
          icon: 'chart',
          label: t('KPI Global View'),
          role: 'KPIS',
          onClick: redirect('/adnoc/kpis')
        },
        {
          key: 'blocked-stock',
          icon: 'box',
          label: t('Blocked Stock Status'),
          role: 'ORDER_FORECASTING',
          onClick: redirect('/adnoc/blocked-stock')
        }
      ]
    },
    {
      key: 'stock-eni',
      label: t('stock.names.SERVICE_STOCK_ENI_MYANMAR'),
      role: 'SERVICE_STOCK_ENI_MYANMAR',
      items: [
        {
          key: 'service-stock',
          icon: 'box',
          label: t('Service Stock'),
          role: 'id',
          onClick: redirect('/eni/service-stock')
        }
      ]
    },
    {
      key: 'stock-total-myanmar',
      label: t('stock.names.SERVICE_STOCK_TOTAL_MYANMAR'),
      role: 'SERVICE_STOCK_TOTAL_MYANMAR',
      items: [
        {
          key: 'service-stock',
          icon: 'box',
          label: t('Service Stock'),
          role: 'id',
          onClick: redirect('/total/service-stock')
        }
      ]
    },
    {
      key: 'stock-total-angola',
      label: t('stock.names.SERVICE_STOCK_TOTAL_ANGOLA'),
      role: 'SERVICE_STOCK_TOTAL_ANGOLA',
      items: [
        {
          key: 'service-stock',
          icon: 'box',
          label: t('Service Stock'),
          role: 'id',
          onClick: redirect('/total-angola/service-stock')
        }
      ]
    },
    {
      key: 'stock-adipec',
      label: t('stock.names.SERVICE_STOCK_ADIPEC'),
      role: 'SERVICE_STOCK_ADIPEC',
      items: [
        {
          key: 'service-stock',
          icon: 'box',
          label: t('Service Stock'),
          role: 'id',
          onClick: redirect('/adipec/service-stock')
        }
      ]
    },
    {
      key: 'stock-chevron',
      label: t('stock.names.SERVICE_STOCK_CHEVRON'),
      role: 'SERVICE_STOCK_CHEVRON',
      items: [
        {
          key: 'service-stock',
          icon: 'box',
          label: t('Service Stock'),
          role: 'id',
          onClick: redirect('/chevron/service-stock')
        }
      ]
    },
    {
      key: 'stock-bhp',
      label: t('stock.names.SERVICE_STOCK_BHP'),
      role: 'SERVICE_STOCK_BHP',
      items: [
        {
          key: 'service-stock',
          icon: 'box',
          label: t('Service Stock'),
          role: 'id',
          onClick: redirect('/bhp/service-stock')
        }
      ]
    }, 
/*     {
      key: 'stock-enventure',
      label: t('stock.names.SERVICE_STOCK_ENVENTURE'),
      role: 'SERVICE_STOCK_ENVENTURE',
      items: [
        {
          key: 'service-stock',
          icon: 'box',
          label: t('Service Stock'),
          role: 'id',
          onClick: redirect('/enventure/service-stock')
        }, 
        {
          key: 'tracking-goods',
          icon: 'folder',
          label: t('Tracking of Goods'),
          role: 'TRACKING_OF_GOODS',
          onClick: redirect('/enventure/tracking-goods')
        },
        {
          key: 'inspection-reports',
          icon: 'search',
          label: t('Inspection Reports'),
          role: '',
          onClick: redirect('/enventure/inspection-reports')
        },
        {
          key: 'mill-receipts',
          icon: 'document',
          label: t('Mill Receipts'),
          role: 'INVENTORY_REPORT.MILL_RECEIPTS',
          onClick: redirect('/enventure/mill-receipts')
        },
        {
          key: 'receiving-inspection-reports',
          icon: 'search',
          label: t('Receiving Inspection Reports'),
          role: 'INVENTORY_REPORT.RECEIVING_INSPECTION_REPORT',
          onClick: redirect('/enventure/receiving-inspection-reports')
        },
        {
          key: 'periodical-inspection-reports',
          icon: 'search',
          label: t('Periodical Inspection Reports'),
          role: 'INVENTORY_REPORT.PERIODICAL_INSPECTION_REPORT',
          onClick: redirect('/enventure/periodical-inspection-reports')
        },
        {
          key: 'delivery-notes',
          icon: 'message-open',
          label: t('Delivery Notes'),
          role: 'INVENTORY_REPORT.DELIVERY_NOTES',
          onClick: redirect('/enventure/delivery-notes')
        },
        {
          key: 'rig-return-inspection-reports',
          icon: 'ship',
          label: t('Rig Return Inspection Reports'),
          role: 'INVENTORY_REPORT.RIG_RETURN_REPORT',
          onClick: redirect('/enventure/rig-return-inspection-reports')
        },
        {
          key: 'archived-reports',
          icon: 'ship',
          label: t('Archived Reports'),
          role: 'HIDDEN_REPORTS',
          onClick: redirect('/enventure/archived-reports')
        },
        {
          key: 'modified-products-inspection-reports',
          icon: 'ship',
          label: t('Modified Products Inspection Reports'),
          role: 'INVENTORY_REPORT.MODIFIED_PRODUCTS_INSPECTION_REPORT',
          onClick: redirect('/enventure/modified-products-inspection-reports')
        },
        {
          key: 'material-requisition-inspection-reports',
          icon: 'ship',
          label: t('Material Requisition Inspection Reports'),
          role: 'INVENTORY_REPORT.RIG_PREP_REPORT',
          onClick: redirect('/enventure/material-requisition-inspection-reports')
        },
        {
          key: 'material-requisition-status',
          icon: 'mr-status',
          label: t('Material Requisition Status'),
          role: 'INVENTORY_REPORT.MATERIAL_REQUISITION_STATUS',
          onClick: redirect('/enventure/material-requisition-status')
        },
        {
          key: 'collaborative-planning',
          icon: 'folder',
          label: t('Collaborative Planning'),
          role: 'COLLABORATIVE_PLANING',
          onClick: redirect('/enventure/collaborative-planning'),
        },
        {
          key: 'pipe-age-view',
          icon: 'pipe-age',
          label: 'Pipe Age View',
          role: 'PIPE_AGE_MANAGEMENT.PIPE_AGE_VIEW',
          onClick: redirect('/enventure/pipe-age-view')
        },
        {
          key: 'slow-moving-pipes',
          icon: 'pipe-age',
          label: 'Slow Moving Pipes',
          role: 'PIPE_AGE_MANAGEMENT.SLOW_MOVING_PIPES',
          onClick: redirect('/enventure/slow-moving-pipes')
        },
        {
          key: 'title-transfer',
          icon: 'pipe-age',
          label: 'Title Transfer',
          role: 'PIPE_AGE_MANAGEMENT.TITLE_TRANSFER',
          onClick: redirect('/enventure/title-transfer')
        },
        {
          key: 'material-physical-transfer',
          icon: 'pipe-age',
          label: 'Material Physical Transfer',
          role: 'PIPE_AGE_MANAGEMENT.MATERIAL_PHYSICAL_TRANSFER',
          onClick: redirect('/enventure/material-physical-transfer')
        },
        {
          key: 'kpi',
          icon: 'chart',
          label: t('KPI Global View'),
          role: 'KPIS',
          onClick: redirect('/enventure/kpis')
        }
      ]
    } */
  ], [t, redirect])

  const sideMenuSettings = {
    appLogoComponent: <Logo>
      <span>Smartengo</span>
      <span>INVENTORY</span>
    </Logo>,
    itemGroups: _.chain(defaultSideMenuOptions)
      .map(stock => ({
        ...stock,
        items: _.filter(
          stock.items,
          ({ role }) => _.get(currentUser, `roles.${stock.role}.${role}`)
        )
      }))
      .filter(({ role }) => _.get(currentUser, `roles.${role}`))
      .value()
  }

  const apps = currentUser && currentUser.applications.map(app => {
    return {
      key: app.id,
      label: app.label,
      iconSrc: app.icon,
      onClick: () => window.location.href = app.link
    }
  })

  const home = {
    onClick: () => window.location.href = process.env.REACT_APP_HOME_URL
  }

  return isHeaderVisible && authState === 'SIGNED_IN' ?
    <Header
      title={pageTitle}
      homeIcon={home}
      subtitle={pageSubtitle}
      appIconSrc={LogoImg}
      onAppIconClick={redirect('/')}
      userSettings={userSettings}
      sideMenuSettings={sideMenuSettings}
      apps={apps} />
    : null
}

export default AppHeader