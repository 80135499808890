// RIG PREP
const DELIVERY_NOTES_RIG_PREP_HEADER_LABEL_CONFIG = [
    { id: 'id', label: 'ID List', cellPosition: 'A5', mergedCells: 'A5:B5' },
    { id: 'date', label: 'Rig Prep Date', cellPosition: 'A6', mergedCells: 'A6:B6' },
    { id: 'mr_ref', label: 'MR Number', cellPosition: 'A7', mergedCells: 'A7:B7' },
    { id: 'field', label: 'Field Name', cellPosition: 'A8', mergedCells: 'A8:B8' },
    { id: 'rig', label: 'Rig Name', cellPosition: 'A9', mergedCells: 'A9:B9' },
    { id: 'well', label: 'Well Name', cellPosition: 'A10', mergedCells: 'A10:B10' },
    { id: 'ltpa', label: 'LTPA Number', cellPosition: 'A11', mergedCells: 'A11:B11' },
    { id: 'owner_name', label: 'MATERIAL OWNERSHIP', cellPosition: 'A12', mergedCells: 'A12:B12' },
    { id: 'end_user', label: 'END USER', cellPosition: 'A13', mergedCells: 'A13:B13' },
    { id: 'total_weight', label: 'OVERALL WEIGHT', cellPosition: 'A14', mergedCells: 'A14:B14' },
    { id: 'co_purchase_order', label: 'Customer PO#', cellPosition: 'A15', mergedCells: 'A15:B15' },
]

const DELIVERY_NOTES_RIG_PREP_HEADER_CONTENT_CONFIG = [
    { id: 'id', accessor: 'id', cellPosition: 'C5', mergedCells: 'C5:D5' },
    { id: 'date', accessor: 'date', cellPosition: 'C6', mergedCells: 'C6:D6' },
    { id: 'mr_ref', accessor: 'mr_ref', cellPosition: 'C7', mergedCells: 'C7:D7' },
    { id: 'field', accessor: 'field', cellPosition: 'C8', mergedCells: 'C8:D8' },
    { id: 'rig', accessor: 'rig', cellPosition: 'C9', mergedCells: 'C9:D9' },
    { id: 'well', accessor: 'well', cellPosition: 'C10', mergedCells: 'C10:D10' },
    { id: 'ltpa', accessor: 'ltpa', cellPosition: 'C11', mergedCells: 'C11:D11' },
    { id: 'owner_name', accessor: 'owner_name', cellPosition: 'C12', mergedCells: 'C12:D12' },
    { id: 'end_user', accessor: 'end_user', cellPosition: 'C13', mergedCells: 'C13:D13' },
    { id: 'total_weight', accessor: 'total_weight', cellPosition: 'C14', mergedCells: 'C14:D14' },
    { id: 'co_purchase_order', accessor: 'co_purchase_order', cellPosition: 'C15', mergedCells: 'C15:D15' },
]

//  PHYSICAL TRANSFER
const DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER_HEADER_LABEL_CONFIG = [
    { id: 'id', label: 'ID List', cellPosition: 'A5', mergedCells: 'A5:B5' },
    { id: 'date', label: 'Date', cellPosition: 'A6', mergedCells: 'A6:B6' },
    { id: 'mr_ref', label: 'MR Number', cellPosition: 'A7', mergedCells: 'A7:B7' },
    { id: 'ltpa', label: 'LTPA Number', cellPosition: 'A8', mergedCells: 'A8:B8' },
    { id: 'location', label: 'Delivery Location', cellPosition: 'A9', mergedCells: 'A9:B9' },
    { id: 'owner_name', label: 'MATERIAL OWNERSHIP', cellPosition: 'A10', mergedCells: 'A10:B10' },
    { id: 'end_user', label: 'END USER', cellPosition: 'A11', mergedCells: 'A11:B11' },
    { id: 'total_weight', label: 'OVERALL WEIGHT', cellPosition: 'A12', mergedCells: 'A12:B12' },
    { id: 'co_purchase_order', label: 'Customer PO#', cellPosition: 'A13', mergedCells: 'A13:B13' },
]

const DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER_HEADER_HEADER_CONTENT_CONFIG = [
    { id: 'id', accessor: 'id', cellPosition: 'C5', mergedCells: 'C5:D5' },
    { id: 'date', accessor: 'date', cellPosition: 'C6', mergedCells: 'C6:D6' },
    { id: 'mr_ref', accessor: 'mr_ref', cellPosition: 'C7', mergedCells: 'C7:D7' },
    { id: 'ltpa', accessor: 'ltpa', cellPosition: 'C8', mergedCells: 'C8:D8' },
    { id: 'location', accessor: 'location', cellPosition: 'C9', mergedCells: 'C9:D9' },
    { id: 'owner_name', accessor: 'owner_name', cellPosition: 'C10', mergedCells: 'C10:D10' },
    { id: 'end_user', accessor: 'end_user', cellPosition: 'C11', mergedCells: 'C11:D11' },
    { id: 'total_weight', accessor: 'total_weight', cellPosition: 'C12', mergedCells: 'C12:D12' },
    { id: 'co_purchase_order', accessor: 'co_purchase_order', cellPosition: 'C13', mergedCells: 'C13:D13' },
]

// MAT MODIFICATION

const DELIVERY_NOTES_MAT_MODIFICATION_HEADER_LABEL_CONFIG = [
    { id: 'id', label: 'ID List', cellPosition: 'A5', mergedCells: 'A5:B5' },
    { id: 'date', label: 'Material Preparation Date', cellPosition: 'A6', mergedCells: 'A6:B6' },
    { id: 'mr_ref', label: 'MR Number', cellPosition: 'A7', mergedCells: 'A7:B7' },
    { id: 'tparty_name', label: 'Third Party Workshop Name', cellPosition: 'A8', mergedCells: 'A8:B8' },
    { id: 'tparty_loc', label: 'Third Party Workshop Location', cellPosition: 'A9', mergedCells: 'A9:B9' },
    { id: 'ltpa', label: 'LTPA Number', cellPosition: 'A10', mergedCells: 'A10:B10' },
    { id: 'owner_name', label: 'MATERIAL OWNERSHIP', cellPosition: 'A11', mergedCells: 'A11:B11' },
    { id: 'end_user', label: 'END USER', cellPosition: 'A12', mergedCells: 'A12:B12' },
    { id: 'total_weight', label: 'OVERALL WEIGHT', cellPosition: 'A13', mergedCells: 'A13:B13' },
    { id: 'co_purchase_order', label: 'Customer PO#', cellPosition: 'A14', mergedCells: 'A14:B14' },
]

const DELIVERY_NOTES_MAT_MODIFICATION_HEADER_CONTENT_CONFIG = [
    { id: 'id', accessor: 'id', cellPosition: 'C5', mergedCells: 'C5:D5' },
    { id: 'date', accessor: 'date', cellPosition: 'C6', mergedCells: 'C6:D6' },
    { id: 'mr_ref', accessor: 'mr_ref', cellPosition: 'C7', mergedCells: 'C7:D7' },
    { id: 'tparty_name', accessor: 'tparty_name', cellPosition: 'C8', mergedCells: 'C8:D8' },
    { id: 'locattparty_locion', accessor: 'tparty_loc', cellPosition: 'C9', mergedCells: 'C9:D9' },
    { id: 'ltpa', accessor: 'ltpa', cellPosition: 'C10', mergedCells: 'C10:D10' },
    { id: 'owner_name', accessor: 'owner_name', cellPosition: 'C11', mergedCells: 'C11:D11' },
    { id: 'end_user', accessor: 'end_user', cellPosition: 'C12', mergedCells: 'C12:D12' },
    { id: 'total_weight', accessor: 'total_weight', cellPosition: 'C13', mergedCells: 'C13:D13' },
    { id: 'co_purchase_order', accessor: 'co_purchase_order', cellPosition: 'C14', mergedCells: 'C14:D14' },
]

// DELIVERY TO THIRD PARTY
const DELIVERY_NOTES_DELIVERY_THIRD_PARTY_HEADER_LABEL_CONFIG = [
    { id: 'id', label: 'ID List', cellPosition: 'A5', mergedCells: 'A5:B5' },
    { id: 'date', label: 'Material Preparation Date', cellPosition: 'A6', mergedCells: 'A6:B6' },
    { id: 'mr_ref', label: 'MR Number', cellPosition: 'A7', mergedCells: 'A7:B7' },
    { id: 'tparty_name', label: 'Third Party Workshop Name', cellPosition: 'A8', mergedCells: 'A8:B8' },
    { id: 'tparty_loc', label: 'Third Party Workshop Location', cellPosition: 'A9', mergedCells: 'A9:B9' },
    { id: 'ltpa', label: 'LTPA Number', cellPosition: 'A10', mergedCells: 'A10:B10' },
    { id: 'owner_name', label: 'MATERIAL OWNERSHIP', cellPosition: 'A11', mergedCells: 'A11:B11' },
    { id: 'end_user', label: 'END USER', cellPosition: 'A12', mergedCells: 'A12:B12' },
    { id: 'total_weight', label: 'OVERALL WEIGHT', cellPosition: 'A13', mergedCells: 'A13:B13' },
    { id: 'co_purchase_order', label: 'Customer PO#', cellPosition: 'A14', mergedCells: 'A14:B14' },
]

const DELIVERY_NOTES_DELIVERY_THIRD_PARTY_CONTENT_CONFIG = [
    { id: 'id', accessor: 'id', cellPosition: 'C5', mergedCells: 'C5:D5' },
    { id: 'date', accessor: 'date', cellPosition: 'C6', mergedCells: 'C6:D6' },
    { id: 'mr_ref', accessor: 'mr_ref', cellPosition: 'C7', mergedCells: 'C7:D7' },
    { id: 'tparty_name', accessor: 'tparty_name', cellPosition: 'C8', mergedCells: 'C8:D8' },
    { id: 'locattparty_locion', accessor: 'tparty_loc', cellPosition: 'C9', mergedCells: 'C9:D9' },
    { id: 'ltpa', accessor: 'ltpa', cellPosition: 'C10', mergedCells: 'C10:D10' },
    { id: 'owner_name', accessor: 'owner_name', cellPosition: 'C11', mergedCells: 'C11:D11' },
    { id: 'end_user', accessor: 'end_user', cellPosition: 'C12', mergedCells: 'C12:D12' },
    { id: 'total_weight', accessor: 'total_weight', cellPosition: 'C13', mergedCells: 'C13:D13' },
    { id: 'co_purchase_order', accessor: 'co_purchase_order', cellPosition: 'C14', mergedCells: 'C14:D14' },
]

const deliveryNotesMap = {
    'DELIVERY_NOTES_DELIVERY_THIRD_PARTY': {
        headerLabelConfig: DELIVERY_NOTES_DELIVERY_THIRD_PARTY_HEADER_LABEL_CONFIG,
        headerContentConfig: DELIVERY_NOTES_DELIVERY_THIRD_PARTY_CONTENT_CONFIG
    },
    'DELIVERY_NOTES_MAT_MODIFICATION': {
        headerLabelConfig: DELIVERY_NOTES_MAT_MODIFICATION_HEADER_LABEL_CONFIG,
        headerContentConfig: DELIVERY_NOTES_MAT_MODIFICATION_HEADER_CONTENT_CONFIG
    },
    'DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER': {
        headerLabelConfig: DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER_HEADER_LABEL_CONFIG,
        headerContentConfig: DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER_HEADER_HEADER_CONTENT_CONFIG
    },
    'DELIVERY_NOTES_RIG_PREP': {
        headerLabelConfig: DELIVERY_NOTES_RIG_PREP_HEADER_LABEL_CONFIG,
        headerContentConfig: DELIVERY_NOTES_RIG_PREP_HEADER_CONTENT_CONFIG
    },
}

const deliveryNotesFilenameMap = {
    'DELIVERY_NOTES_DELIVERY_THIRD_PARTY': 'Delivery to Third Party Workshop DELIVERY NOTE',
    'DELIVERY_NOTES_MAT_MODIFICATION': 'Material Modification DELIVERY NOTE',
    'DELIVERY_NOTES_MAT_PHYSICAL_TRANSFER': '18-Months Threshold - PHYSICAL TRANSFER ITEMS DELIVERY NOTES',
    'DELIVERY_NOTES_RIG_PREP': 'RIG PREPARATION DELIVERY NOTE'
}


export const getDeliveryNotesHeaderConfig = (deliveryNotesType) => {
    return {
        headerLabelConfig: deliveryNotesMap[deliveryNotesType].headerLabelConfig,
        headerContentConfig: deliveryNotesMap[deliveryNotesType].headerContentConfig
    }
}

export const getDeliveryNotesTitle = (deliveryNotesType) => deliveryNotesFilenameMap[deliveryNotesType]