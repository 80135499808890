import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import moment from 'moment'
import _ from 'lodash'

import dataport from 'api/dataport'
import apiReports from 'api/reports'
import templateDataport from 'api/template-dataport'

import { LoadingOverlay, FlexView, Icon, Card } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'

import { getSlowMovingDataQuery } from 'utils/dataport-query'
import { getSlowMovingInspectionDownloadQuery } from 'utils/template-dataport-query'

import { reports_uri } from 'utils/constants'

const loadFile = url => {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.click()
}

const formatReports = reports => reports.map(report => {
    const formattedDate = report.trigger_date ? moment(report.trigger_date).format('DD/MM/YYYY') : 'Undated'
    return {
      id: report.id,
      trigger_date: report.trigger_date,
      name: `Slow_Moving_Pipe_List_${formattedDate}`,
    }
  })

const SlowMovingPipesPage = ({ stockKey }) => {
  const { t } = useTranslation()
  const { stocks, setPageSubtitle, datasets, dataportTemplates } = useContext(AppContext)
  const { idToken } = useContext(UserContext)
  const stock = useMemo(() => _.find(stocks, { key: stockKey }), [stocks, stockKey])
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(t(`stock.names.${stock.key}`) + ' - ' + t('Slow Moving Pipes'))
    }
  }, [stock, setPageSubtitle, t])

  const downloadReport = useCallback(async id => {
    setLoading(true)
    try {
      const query = getSlowMovingDataQuery(id, datasets)
      const jobId = await dataport.getJobId(query)
      const downloadQuery = getSlowMovingInspectionDownloadQuery(jobId, datasets, dataportTemplates, id)
      const { url } = await templateDataport.loadTemplate(downloadQuery, idToken)
      loadFile(url)
      toast.info(t('documents.popupInfo'))
    }
    catch (error) {
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [datasets, dataportTemplates, idToken, t])

  const onDownloadClick = useCallback(id => () => {
    downloadReport(id)
  }, [downloadReport])

  useEffect(() => {
    setLoading(true)
    apiReports.getSlowMovingPipesList(reports_uri.GET_SLOW_MOVING_PIPES, {}, idToken).then(data => {
      setReports(formatReports(data))
    }).catch(error => {
      toast.error(error.message)
    }).finally(() => {
      setLoading(false)
    })
  }, [datasets, idToken])

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: t('ID')
    },
    {
      accessor: 'name',
      Header: t('Name'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      Header: t('Extraction Date'),
      accessor: ({ trigger_date }) => trigger_date ? moment(trigger_date).format('DD/MM/YYYY') : '',
      // Cell: ({ cell: { value } }) => value ? moment(value).format('DD/MM/YYYY') : '',
      sortType: 'date',
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      id: 'download',
      accessor: 'id',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { value } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(value)} />
      </FlexView>
    }
  ], [t, onDownloadClick])

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <LoadingOverlay visible={loading} />
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <FilterableTable columns={columns} data={reports} />
    </Card>
  </FlexView>
}

export default SlowMovingPipesPage