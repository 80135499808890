import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import DATASETS from 'utils/datasets'
import DATAPORT_TEMPLATES from 'utils/dataport-templates'
import WID from 'utils/wid-by-env'

const initialState = {
  name: process.env.REACT_APP_NAME,
  subtitle: '',
  version: process.env.REACT_APP_VERSION
}

const datasets = DATASETS[process.env.REACT_APP_DATASETS_ENV]
const wid = WID[process.env.NODE_ENV]
const dataportTemplates = DATAPORT_TEMPLATES[process.env.REACT_APP_DATAPORT_TEMPLATES_ENV]

const stocks = [
  {
    title: 'ADNOC',
    route: 'service-stock-adnoc',
    wid: wid.VTSA,
    key: 'SERVICE_STOCK_ADNOC',
    baseUrl: 'adnoc',
    hasExcelBackup: true,
    endpoints: [
      'service-stock',
      'tracking-goods',
      'inspection-reports',
      'inspection-reports-menu',
      'mill-receipts',
      'delivery-notes',
      'receiving-inspection-reports',
      'periodical-inspection-reports',
      'material-requisition-inspection-reports',
      'material-requisition-status',
      'pipe-age',
      'pipe-age-view',
      'slow-moving-pipes',
      'title-transfer',
      'material-physical-transfer',
      'rig-return-inspection-reports',
      'modified-products-inspection-reports',
      'collaborative-planning',
      'kpis', 
      'archived-reports', 
      'blocked-stock'
    ]
  },
  {
    title: 'ENI',
    route: 'service-stock-ptct',
    wid: wid.ENI_MYANMAR,
    key: 'SERVICE_STOCK_ENI_MYANMAR',
    baseUrl: 'eni',
    endpoints: [
      'service-stock'
    ]
  },
  {
    title: 'TOTAL',
    route: 'service-stock-ptct',
    wid: wid.TOTAL_MYANMAR,
    key: 'SERVICE_STOCK_TOTAL_MYANMAR',
    baseUrl: 'total',
    endpoints: [
      'service-stock'
    ]
  },
  {
    title: 'TOTAL',
    route: 'service-stock-total-angola',
    wid: wid.TOTAL_ANGOLA,
    key: 'SERVICE_STOCK_TOTAL_ANGOLA',
    baseUrl: 'total-angola',
    endpoints: [
      'service-stock'
    ]
  },
  {
    title: 'ADIPEC',
    route: 'service-stock-adipec',
    wid: wid.ADIPEC,
    key: 'SERVICE_STOCK_ADIPEC',
    baseUrl: 'adipec',
    endpoints: [
      'service-stock'
    ]
  },
  {
    title: 'CHEVRON',
    route: 'service-stock-chevron',
    wid: wid.CHEVRON,
    key: 'SERVICE_STOCK_CHEVRON',
    baseUrl: 'chevron',
    endpoints: [
      'service-stock'
    ]
  },
  {
    title: 'BHP',
    route: 'service-stock-bhp',
    wid: wid.BHP,
    key: 'SERVICE_STOCK_BHP',
    baseUrl: 'bhp',
    endpoints: [
      'service-stock'
    ]
  }, 
/*   {
    title: 'ENVENTURE',
    route: 'service-stock-enventure',
    wid: wid.ENVENTURE,
    key: 'SERVICE_STOCK_ENVENTURE',
    baseUrl: 'enventure',
    hasExcelBackup: true,
    endpoints: [
      'service-stock', 
      'tracking-goods',
      'inspection-reports',
      'inspection-reports-menu',
      'mill-receipts',
      'delivery-notes',
      'receiving-inspection-reports',
      'periodical-inspection-reports',
      'material-requisition-inspection-reports',
      'material-requisition-status',
      'pipe-age',
      'pipe-age-view',
      'slow-moving-pipes',
      'title-transfer',
      'material-physical-transfer',
      'rig-return-inspection-reports',
      'modified-products-inspection-reports',
      'collaborative-planning',
      'kpis', 
      'archived-reports'
    ]
  } */
]

const nullValue = value => value === null || value === undefined || value === ''

export const AppContext = React.createContext(initialState)

export const AppProvider = ({ children }) => {
  const [redirectUrl, setRedirectUrl] = useState('')
  const [pageTitle, setPageTitle] = useState(initialState.name)
  const [pageSubtitle, setPageSubtitle] = useState(initialState.subtitle)
  const [isHeaderVisible, setHeaderVisibility] = useState(true)
  const [lengthUnit, setLengthUnit] = useState('m')
  const [weightUnit, setWeightUnit] = useState('kg')
  const { i18n } = useTranslation()
  const [ pageLoading, setPageLoading ] = useState(false)
  const [ wid, setWid ] = useState(null)
  const [ endUser, setEndUser ] = useState(null)

  moment.locale(i18n.language)

  const formatNumberByCulture = useCallback((value, digits) => {
    return new Intl.NumberFormat(i18n.language, { minimumFractionDigits: digits } ).format( value )
  }, [i18n.language])

  const convertToCurrentLengthUnit = useCallback((value_m,value_ft, unit) => {
    const nullMetricValue = nullValue(value_m)
    const nullImperialValue = nullValue(value_ft)
    // console.log({value_m, value_ft})
    const selectedValueNull = ((nullMetricValue && lengthUnit === 'm') || (nullImperialValue && lengthUnit === 'ft'))
    if (nullMetricValue && nullImperialValue) {
      return 0
    }

    if (selectedValueNull) {
      return 0
    }

    if (unit === 'm') {
      return formatNumberByCulture(value_m, 3)
    }  
    
    if (lengthUnit === 'ft') {
      return formatNumberByCulture(value_ft, 3)
    }
    return value_m
  }, [lengthUnit, formatNumberByCulture])

  const convertToCurrentLengthUnitWithoutCulture = useCallback((value_m,value_ft, unit) => {
    const nullMetricValue = nullValue(value_m)
    const nullImperialValue = nullValue(value_ft)
    // console.log({value_m, value_ft})
    const selectedValueNull = ((nullMetricValue && lengthUnit === 'm') || (nullImperialValue && lengthUnit === 'ft'))
    if (nullMetricValue && nullImperialValue) {
      return 0
    }

    if (selectedValueNull) {
      return 0
    }

    if (unit === 'm') {
      return Number((value_m).toFixed(3)) 
    }  
    
    if (lengthUnit === 'ft') {
      return Number((value_ft).toFixed(3))
    }
    return  Number((value_m).toFixed(3)) 
  }, [lengthUnit])

  const convertToCurrentWeightUnit = useCallback((value, unit) => {
    if (value === null || value === undefined || value === '') {
      return 0
    }
    if (unit === weightUnit) {
      return formatNumberByCulture(value, 3)
    }
    else if (weightUnit === 'lb') {
      return formatNumberByCulture(value*2.20462, 3)
    }
    return formatNumberByCulture(value/2.20462, 3)
  }, [weightUnit, formatNumberByCulture])

  const convertToCurrentWeightUnitWithoutCulture = useCallback((value, unit) => {
    if (value === null || value === undefined || value === '') {
      return 0
    }
    if (unit === weightUnit) {
      return Number((value).toFixed(3))
    }
    else if (weightUnit === 'lb') {
      return Number((value*2.20462).toFixed(3))
    }
    return Number((value/2.20462).toFixed(3))
  }, [weightUnit])

  return <AppContext.Provider value={{
    ...initialState,
    redirectUrl,
    pageTitle,
    pageSubtitle,
    isHeaderVisible,
    datasets,
    dataportTemplates,
    stocks,
    lengthUnit,
    weightUnit,
    convertToCurrentLengthUnit,
    convertToCurrentWeightUnit,
    setLengthUnit,
    setWeightUnit,
    setRedirectUrl,
    setPageTitle,
    setPageSubtitle,
    setHeaderVisibility, 
    formatNumberByCulture, 
    pageLoading, setPageLoading,
    convertToCurrentLengthUnitWithoutCulture, 
    convertToCurrentWeightUnitWithoutCulture, 
    wid, setWid, 
    endUser, setEndUser 
  }}>
    {children}
  </AppContext.Provider>
}