import { toast } from 'react-toastify'

import moment from 'moment'

import dataport from 'api/dataport'
import templateDataport from 'api/template-dataport'
import apiDataReport from 'api/report-data'

import { generateSpreadsheet } from 'provider/excelGeneration'

import { dispatchTypeId, filenames  } from 'utils/constants'
import { getFileName } from 'utils/helpers'

import {  getMaterialPhysicalTransferDataQuery, getMaterialRequisitionReportDataFromDayQuery,
          getMatRequisitionDeliveryThirdPartyReportDataFromDayQuery, getTitleTransferDataQuery,
          getMatRequisitionMatModificationReportDataFromDayQuery, getSlowMovingDataQuery,
          getMatRequisitionMatPhysicalTransferReportDataFromDayQuery, getMillReceiptReportQuery,
          getPeriodicalInspectionArchivedDataQuery, getReceivingInspectionArchivedDataQuery, 
          getRigReturnReportDataQuery, getModifiedProductsDataQuery 
} from 'utils/dataport-query'

import {  getMaterialPhysicalTransferInspectionDownloadQuery, getTitleTransferInspectionDownloadQuery, 
    getSlowMovingInspectionDownloadQuery, getPeriodicalInspectionReportDownloadQuery,
    getMatRequisitionDeliveryThirdPartyReportDownloadQuery, getReceiveInspectionReportDownloadQuery,
    getRigReturnReportDownloadQuery, getModifiedProductsReportDownloadQuery, 
    getMatRequisitionMatModificationReportDownloadQuery, getMaterialRequisitionReportDownloadQuery, 
    getMillReceiptDownloadQuery, getMaterialRequisitionMatPhysicalTransferReportDownloadQuery
} from 'utils/template-dataport-query'

const loadFile = url => {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.click()
}

export const reportGroups = {
  MATERIAL_PARAMS: 'MATERIAL_PARAMS',
  STANDARD: 'STANDARD',
  MATERIAL_REQUISITION: 'MAT_REQUISITION',
  DELIVERY_NOTES: 'DELIVERY_NOTES',
  PIPE_AGE: 'PIPE_AGE',
  MR_STATUS: 'MR_STATUS'
}

export const mappedReportGroups = {
  RECEIVING_INSP: reportGroups.MATERIAL_PARAMS,
  PERIODICAL_INSP: reportGroups.MATERIAL_PARAMS,
  RIG_RETURN_INSP: reportGroups.STANDARD,
  MODIFIED_PRODUCTS: reportGroups.STANDARD,
  MILL_RECEIPT: reportGroups.STANDARD,
  MATERIAL_REQUISITION: reportGroups.MAT_REQUISITION,
  DELIVERY_NOTES: reportGroups.DELIVERY_NOTES,
  SLOW_MOVING: reportGroups.PIPE_AGE,
  TITLE_TRANSFER: reportGroups.PIPE_AGE,
  MATERIAL_PHYSICAL_TRANSFER: reportGroups.PIPE_AGE,
  MR_STATUS: reportGroups.MR_STATUS
}

export const reportsQuery = {
  RECEIVING_INSP: getReceivingInspectionArchivedDataQuery,
  RIG_RETURN_INSP: getRigReturnReportDataQuery,
  MODIFIED_PRODUCTS: getModifiedProductsDataQuery,
  PERIODICAL_INSP: getPeriodicalInspectionArchivedDataQuery,
  MILL_RECEIPT: getMillReceiptReportQuery,
  SLOW_MOVING: getSlowMovingDataQuery,
  TITLE_TRANSFER: getTitleTransferDataQuery,
  MATERIAL_PHYSICAL_TRANSFER: getMaterialPhysicalTransferDataQuery,
}

export const reportsDownloadQuery = {
  RECEIVING_INSP: getReceiveInspectionReportDownloadQuery,
  RIG_RETURN_INSP: getRigReturnReportDownloadQuery,
  MODIFIED_PRODUCTS: getModifiedProductsReportDownloadQuery,
  PERIODICAL_INSP: getPeriodicalInspectionReportDownloadQuery,
  MILL_RECEIPT: getMillReceiptDownloadQuery,
  SLOW_MOVING: getSlowMovingInspectionDownloadQuery,
  TITLE_TRANSFER: getTitleTransferInspectionDownloadQuery,
  MATERIAL_PHYSICAL_TRANSFER: getMaterialPhysicalTransferInspectionDownloadQuery,
}

const nameReport = (dispatchTypeName, closure_date) => `${dispatchTypeName}_${closure_date ? moment(closure_date).format('DD_MM_YY') : 'Undated'}`

const materialRequisitionTypeMap = {
  [dispatchTypeId.Delivery3rdParty]: {
    dataQueryFunction: (report, datasets) => getMatRequisitionDeliveryThirdPartyReportDataFromDayQuery(report, datasets),
    downloadQueryFunction: (jobId, datasets, dataportTemplates, id) => getMatRequisitionDeliveryThirdPartyReportDownloadQuery(jobId, datasets, dataportTemplates, id),
    nameFunction: (closure_date) => nameReport('Delivery_to_3d_Party_workshop_Dispatch_Inspection_Report', closure_date)
  },
  [dispatchTypeId.MaterialModification]: {
    dataQueryFunction: (report, datasets) => getMatRequisitionMatModificationReportDataFromDayQuery(report, datasets),
    downloadQueryFunction: (jobId, datasets, dataportTemplates, id) => getMatRequisitionMatModificationReportDownloadQuery(jobId, datasets, dataportTemplates, id),
    nameFunction: (closure_date) => nameReport('Material_Modification_Dispatch_Inspection_Report', closure_date)
  },
  [dispatchTypeId.RigPreparation]: {
    dataQueryFunction: (report, datasets) => getMaterialRequisitionReportDataFromDayQuery(report, datasets),
    downloadQueryFunction: (jobId, datasets, dataportTemplates, id) => getMaterialRequisitionReportDownloadQuery(jobId, datasets, dataportTemplates, id),
    nameFunction: (closure_date) => nameReport('Rig_prep_Dispatch_Inspection_Report', closure_date)
  },
  [dispatchTypeId.MaterialPhysicalTransfer]: {
    dataQueryFunction: (report, datasets) => getMatRequisitionMatPhysicalTransferReportDataFromDayQuery(report, datasets),
    downloadQueryFunction: (jobId, datasets, dataportTemplates, id) => getMaterialRequisitionMatPhysicalTransferReportDownloadQuery(jobId, datasets, dataportTemplates, id),
    nameFunction: (closure_date) => nameReport('Material_Physical_Transfer_Inspection_Report', closure_date)
  }
}

export const downloadMaterialRequisitionInspection = async(report, datasets, dataportTemplates, idToken, setPageLoading, t, additionalInfo  ) => {
  try {
    const query = materialRequisitionTypeMap[additionalInfo.dispatch_type_id].dataQueryFunction(report, datasets)
    const jobId = await dataport.getJobId(query)
    const downloadQuery = materialRequisitionTypeMap[additionalInfo.dispatch_type_id].downloadQueryFunction(jobId, datasets, dataportTemplates, report.id_report)
    const { url } = await templateDataport.loadTemplate(downloadQuery, idToken)
    loadFile(url)
    setPageLoading(false)
    toast.info(t('documents.popupInfo'))
  }
  catch (error) {
    console.log(error)
    toast.error(error.message)
    setPageLoading(false)
  }
}

export const downloadStandardGroupReports = async(report, datasets, dataportTemplates, idToken, setPageLoading, t, additionalInfo) => {
  try{
    const mappedReportQuery =  reportsQuery[report.report_type]
    const query = mappedReportQuery(report.id_report, additionalInfo.revision, datasets)
    const jobId = await dataport.getJobId(query)
    const mappedDownloadQuery =  reportsDownloadQuery[report.report_type]
    const downloadQuery = mappedDownloadQuery(jobId, datasets, dataportTemplates, report.id_report)
    const { url } = await templateDataport.loadTemplate(downloadQuery, idToken)
    loadFile(url)
    setPageLoading(false)
    toast.info(t('documents.popupInfo'))
  } catch (error) {
    console.log(error)
    toast.error(error.message)
    setPageLoading(false)
  }
}

export const downloadMaterialParamsGroupReports = async(report, datasets, dataportTemplates, idToken, setPageLoading, t, additionalInfo) => {
  try{
    const mappedReportQuery =  reportsQuery[report.report_type]
    const query = mappedReportQuery(report.id_report, additionalInfo.dsc_material, additionalInfo.revision, datasets)
    const jobId = await dataport.getJobId(query)
    const mappedDownloadQuery =  reportsDownloadQuery[report.report_type]
    const downloadQuery = mappedDownloadQuery(jobId, datasets, dataportTemplates, report.id_report)
    const { url } = await templateDataport.loadTemplate(downloadQuery, idToken)
    loadFile(url)
    setPageLoading(false)
    toast.info(t('documents.popupInfo'))
  } catch (error) {
    console.log(error)
    toast.error(error.message)
    setPageLoading(false)
  }
}

export const downloadPipeAgeGroupReports = async(report, datasets, dataportTemplates, idToken, setPageLoading, t) => {
  try{
    const mappedReportQuery =  reportsQuery[report.report_type]
    const query = mappedReportQuery(report.id_report, datasets)
    const jobId = await dataport.getJobId(query)
    const mappedDownloadQuery =  reportsDownloadQuery[report.report_type]
    const downloadQuery = mappedDownloadQuery(jobId, datasets, dataportTemplates, report.id_report)
    const { url } = await templateDataport.loadTemplate(downloadQuery, idToken)
    loadFile(url)
    setPageLoading(false)
    toast.info(t('documents.popupInfo'))
  } catch (error) {
    console.log(error)
    toast.error(error.message)
    setPageLoading(false)
  }
}
  
const deliveryNotesReportDataMap = {
  [dispatchTypeId.Delivery3rdParty]: {
    reportDataFunction: (report, idToken, additionalInfo) => apiDataReport.getDeliveryNotesDeliveryThirdPartyReportData({
      reportId: report.id_report,
      revision: additionalInfo.revision,
      owner_id: additionalInfo.owner_id
    }, idToken),
    filename: (reportId) => getFileName(filenames['DELIVERY_NOTES_DELIVERY_TO_THIRD_PARTY'], reportId),
  },
  [dispatchTypeId.MaterialModification]: {
    reportDataFunction: (report, idToken, additionalInfo) => apiDataReport.getDeliveryNotesMatModificationReportData({
      reportId: report.id_report,
      revision: additionalInfo.revision,
      owner_id: additionalInfo.owner_id
    }, idToken),
    filename: (reportId) => getFileName(filenames['DELIVERY_NOTES_MATERIAL_MODIFICATION'], reportId),
  },
  [dispatchTypeId.RigPreparation]: {
    reportDataFunction: (report, idToken, additionalInfo) => apiDataReport.getDeliveryNotesRigPrepReportData({
      reportId: report.id_report,
      revision: additionalInfo.revision,
      owner_id: additionalInfo.owner_id
    }, idToken),
    filename: (reportId) => getFileName(filenames['DELIVERY_NOTES_RIG_PREP'], reportId),
  },
  [dispatchTypeId.MaterialPhysicalTransfer]: {
    reportDataFunction: (report, idToken, additionalInfo) => apiDataReport.getDeliveryNotesMatPhysicalTransferReportData({
      reportId: report.id_report,
      revision: additionalInfo.revision,
      owner_id: additionalInfo.owner_id
    }, idToken),
    filename: (reportId) => getFileName(filenames['DELIVERY_NOTES_MATERIAL_PHYSICAL_TRANSFER'], reportId),
  }
}

export const downloadDeliveryNotes = async(report, idToken, setPageLoading, t, additionalInfo ) => {
  try {
    const fetchDataFunction = deliveryNotesReportDataMap[additionalInfo.dispatch_type_id].reportDataFunction
    const data = await fetchDataFunction(report, idToken, additionalInfo)
    const reportFileName =  deliveryNotesReportDataMap[additionalInfo.dispatch_type_id].filename(report.id_report)
    await generateSpreadsheet(reportFileName, data)
    setPageLoading(false)
    toast.info(t('documents.popupInfo'))
  } catch (error) {
    console.log(error)
    toast.error(error.message)
    setPageLoading(false)
  }
}

export const downloadReports = async(report, datasets, dataportTemplates, idToken, setPageLoading, t, additionalInfo) => {
  try {
      if(mappedReportGroups[report.report_type] === reportGroups.PIPE_AGE){
        downloadPipeAgeGroupReports(report, datasets, dataportTemplates, idToken, setPageLoading, t)
      }
      else if(mappedReportGroups[report.report_type] === reportGroups.MAT_REQUISITION){
        downloadMaterialRequisitionInspection(report, datasets, dataportTemplates, idToken, setPageLoading, t, additionalInfo)
      }
      else if(mappedReportGroups[report.report_type] === reportGroups.DELIVERY_NOTES){
        downloadDeliveryNotes(report, idToken, setPageLoading, t, additionalInfo)
      }
      // else if(mappedReportGroups[report.report_type] === reportGroups.MATERIAL_PARAMS){
      //   downloadMaterialParamsGroupReports(report, datasets, dataportTemplates, idToken, setPageLoading, t, additionalInfo)
      // }
      else {
        downloadStandardGroupReports(report, datasets, dataportTemplates, idToken, setPageLoading, t, additionalInfo)
      }
  }
  catch (error) {
    console.log(error)
    toast.error(error.message)
    setPageLoading(false)
  }
}