import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import styled from 'styled-components'

import { FlexView } from 'components/common'
import Button from 'components/common/Button'
import UploadBox from 'components/blocked-stock/UploadBox'

import { BlockedStockContext } from 'stores/BlockedStockStore'

import { REFERENCE_SCREEN_SIZE } from '../../utils/constants'
import { exportErrorsToSheet } from 'utils/validator/xlsxSchemaValidation'

const Grid = styled(FlexView)`
  display: grid;
  grid-template-columns: repeat(1, 100%);

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(1, 100%);
  }

  @media screen and (max-width: 920px) {
    grid-template-columns: repeat(1, 100%);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 100%);
  }
`

const UploadContainer = ({ height }) => {
  const { t } = useTranslation()
  const { schemaErrors, saveUploadedData, file, setFile, enable } = useContext(BlockedStockContext)
  
    return (
      <>
        <Grid width="100%">
          <UploadBox fontSize={height > REFERENCE_SCREEN_SIZE ? 7 : 6} fileTypes=".xlsx" setFile={ setFile } 
                    text={ !_.isEmpty(file) ? file?.name : t('Click or drag an Excel File to upload')   } height={height}>
          </UploadBox>
        </Grid>
        <FlexView flexDirection="row" justifyContent="flex-end" alignItems="flex-end" width="100%">
        { schemaErrors.length === 0 
          ? <Button backgroundColor="primary" color="white" fontSize="big" margin="15px" width="15rem" onClick={() => saveUploadedData()} disabled={_.isEmpty(file) || !enable }> {t('Save').toUpperCase()} </Button>
          : <Button backgroundColor="error" color="white" fontSize="big" margin="15px" disabled={schemaErrors.length === 0}
                onClick={() => exportErrorsToSheet(schemaErrors)} width="15rem"> {t('Download Errors Log')} </Button>
        }
        </FlexView>
    </>
    )
}

export default UploadContainer