import Exceljs from 'exceljs'
import { saveAs } from 'file-saver'


import { getDeliveryNotesHeaderConfig, getDeliveryNotesTitle } from 'provider/configObjs'


// import { data } from './dummydata'

const getSolidFill = (color) => ({
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: color },
})

const getRangeMergeRange = (row, columns) => `${columns[0]}${row}:${columns[1]}${row}`

const DEFAULT_NUM_FMT = '0.000'

const FONT_NAME_BOLD = 'Arial Black'
const FONT_NAME_NORMAL = 'Arial'

const GREY_COLOR = 'D9D9D9'
const YELLOW_COLOR = 'FFF2CC'
const WHITE_COLOR = 'FFFFFF'
const DEFAULT_BORDER_COLOR = 'c9c9c9'


const YELLOW_FILL = getSolidFill(YELLOW_COLOR)
const GREY_FILL = getSolidFill(GREY_COLOR)
const WHITE_FILL = getSolidFill(WHITE_COLOR)

const BOTTOM_LEFT_ALIGNMENT = { vertical: 'bottom', horizontal: 'left' }
const MIDDLE_CENTER_ALIGNMENT = { vertical: 'middle', horizontal: 'center' }


const THIN_FULL_BORDER = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' }
}

// const MEDIUM_FULL_BORDER = {
//     top: { style: 'medium', },
//     left: { style: 'medium' },
//     bottom: { style: 'medium' },
//     right: { style: 'medium' }
// }

const DEFAULT_BORDER = {
    top: { style: 'thin', color: { argb: DEFAULT_BORDER_COLOR } },
    left: { style: 'thin', color: { argb: DEFAULT_BORDER_COLOR } },
    bottom: { style: 'thin', color: { argb: DEFAULT_BORDER_COLOR } },
    right: { style: 'thin', color: { argb: DEFAULT_BORDER_COLOR } }
}


// TODO: figure it out how to revert borders back to default
const CLEAR_STYLE = {
    alignment: BOTTOM_LEFT_ALIGNMENT,
    border: DEFAULT_BORDER,
    fill: WHITE_FILL,
    height: 15,
    font: {
        name: FONT_NAME_NORMAL,
        size: 11,
        bold: false
    }
}

const STANDADARD_COLUMNS_WIDTH = [
    { key: 'A', width: 10 },
    { key: 'B', width: 30 },
    { key: 'C', width: 20 },
    { key: 'D', width: 25 },
    { key: 'E', width: 20 },
    { key: 'F', width: 20 },
    { key: 'G', width: 70 },
    { key: 'H', width: 20 },
    { key: 'I', width: 40 },
    { key: 'J', width: 40 },
    { key: 'K', width: 20 },
    { key: 'L', width: 20 },
]

const TITLE_CONFIG = {
    cellPosition: 'A2',
    height: 35,
    mergedCells: 'A2:G2',
    font: {
        name: FONT_NAME_BOLD,
        size: 22,
        bold: true
    }
}

const HEADER_SUBTITLE_CONFIG = {
    content: 'Details',
    cellPosition: 'A4',
    height: 18,
    mergedCells: 'A4:D4',
    font: {
        name: FONT_NAME_BOLD,
        size: 14,
        bold: true
    }
}

const HEADER_LABEL_STYLE = {
    alignment: BOTTOM_LEFT_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: GREY_FILL,
    font: {
        name: FONT_NAME_BOLD,
        size: 12,
        bold: true
    }
}

const HEADER_LABEL_CONFIG = {
    cellPosition: 'A5',
    height: 30,
    mergedCells: 'A5:D5',
    font: {
        name: FONT_NAME_NORMAL,
        size: 14,
        bold: false
    }
}

const HEADER_CONTENT_STYLE = {
    numFmt: DEFAULT_NUM_FMT,
    alignment: BOTTOM_LEFT_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: YELLOW_FILL,
    font: {
        name: FONT_NAME_NORMAL,
        size: 12,
        bold: false
    }
}

const DATA_LABEL_CONFIG = {
    row: 17
}

const DATA_LABEL_STYLE = {
    alignment: MIDDLE_CENTER_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: GREY_FILL,
    height: 27.5,
    font: {
        name: FONT_NAME_BOLD,
        size: 11,
        bold: true
    }
}

const DATA_CONTENT_CONFIG = {
    row: 18
}

const DATA_CONTENT_STYLE = {
    numFmt: DEFAULT_NUM_FMT,
    alignment: BOTTOM_LEFT_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: YELLOW_FILL,
    height: 15,
    font: {
        name: FONT_NAME_NORMAL,
        size: 10,
        bold: false
    }
}

const DELIVERY_NOTES_SIGNATURE_HEADER_STYLE = {
    alignment: MIDDLE_CENTER_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: null,
    height: 30,
    font: {
        name: FONT_NAME_BOLD,
        size: 13,
        bold: true
    }
}

const DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE = {
    alignment: BOTTOM_LEFT_ALIGNMENT,
    border: THIN_FULL_BORDER,
    fill: null,
    height: 85,
    font: {
        name: FONT_NAME_NORMAL,
        size: 10,
        bold: false
    }
}

const DELIVERY_NOTES_SIGNATURE_GAP = 5

const DELIVERY_NOTES_RIG_PREP_DATA_LABEL_CONFIG = [
    { id: 'item_no', label: 'ITEM NO' },
    { id: 'co_purchase_item', label: 'CUSTOMER ITEM#' },
    { id: 'num_sales_order', label: 'VALLOUREC ORDER#' },
    { id: 'num_sales_item', label: 'VALLOUREC ITEM#' },
    { id: 'cat_id', label: 'MESC / CAT ID' },
    { id: 'dsc_material', label: 'MATERIAL' },
    { id: 'quantity_prep', label: 'TOTAL JOINTS' },
    { id: 'total_length', label: 'TOTAL MEASURED LENGTH [ft]' },
    { id: 'total_weight', label: 'TOTAL WEIGHT [kg]' },
]

const DELIVERY_NOTES_RIG_PREP_DATA_CONTENT_CONFIG = [
    { id: 'item_no', accessor: 'item_no' },
    { id: 'co_purchase_item', accessor: 'co_purchase_item' },
    { id: 'num_sales_order', accessor: 'num_sales_order' },
    { id: 'num_sales_item', accessor: 'num_sales_item' },
    { id: 'cat_id', accessor: 'cat_id' },
    { id: 'dsc_material', accessor: 'dsc_material' },
    { id: 'quantity_prep', accessor: 'quantity_prep' },
    { id: 'total_length', accessor: 'total_length' },
    { id: 'total_weight', accessor: 'total_weight' },
]

const DELIVERY_NOTES_SIGNATURE_SHIPPER_CONFIG = [
    { id: 'shipper', label: 'SHIPPER (Vallourec)', mergeColumns: ['C', 'F'], style: DELIVERY_NOTES_SIGNATURE_HEADER_STYLE },
    { id: 'name', label: 'Name:', mergeColumns: ['C', 'F'], style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE },
    { id: 'signature', label: 'Signature:', mergeColumns: ['C', 'F'], style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE },
    { id: 'date', label: 'Date:', mergeColumns: ['C', 'F'], style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE },
    { id: 'stamp', label: 'Stamp:', mergeColumns: ['C', 'F'], style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE },
]

const DELIVERY_NOTES_SIGNATURE_RECEIVER_CONFIG = [
    { id: 'receiver', label: 'RECEIVER', mergeColumns: ['G', 'H'], style: DELIVERY_NOTES_SIGNATURE_HEADER_STYLE },
    { id: 'name', label: 'Name:', mergeColumns: ['G', 'H'], style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE },
    { id: 'signature', label: 'Signature:', mergeColumns: ['G', 'H'], style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE },
    { id: 'date', label: 'Date:', mergeColumns: ['G', 'H'], style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE },
    { id: 'stamp', label: 'Stamp:', mergeColumns: ['G', 'H'], style: DELIVERY_NOTES_SIGNATURE_CONTENT_STYLE },
]

const FILE_EXTENSION = '.xlsx'

const workBookNames = {
    'DELIVERY_NOTES': 'Dispatch Details'
}

const tempType = 'DELIVERY_NOTES'

const createAndConfigureWorkbook = (workbookCreator = 'Smartengo Inventory') => {
    const workbook = new Exceljs.Workbook()
    workbook.creator = workbookCreator
    return workbook
}

const createWorksheet = (workbook, sheetName) => {
    // Create worksheets with headers and footers
    const sheet = workbook.addWorksheet(sheetName, {
        headerFooter: { firstHeader: "Hello Exceljs", firstFooter: "Hello World" }
    })

    return sheet
}

const charactersRegex = /^\D+/g
const numbersRegex = /[0-9]/g


const getRowFromCellPosition = (cellPosition) => cellPosition.replace(charactersRegex, '')

const getCellFromCellPosition = (cellPosition) => cellPosition.replace(numbersRegex, '')

const getRowAndCellPosition = (cellPosition) => ({ rowPosition: getRowFromCellPosition(cellPosition), cellPosition: getCellFromCellPosition(cellPosition) })

/**
 *
 * @param {Exceljs.Worksheet} ws 
 */
const mergeItemCells = (ws, cellToMerge) => {
    ws.mergeCells(cellToMerge)
}

/* Setters helpers */
const setCellFont = (cell, font) => cell.font = font
const setCellFill = (cell, fill) => cell.fill = fill
const setCellBorder = (cell, border) => cell.border = border
const setCellAlignment = (cell, alignment) => cell.alignment = alignment
const setObjectHeight = (object, height) => object.height = height

const setObjectStyle = (object, font, fill, border, alignment, height = null) => {
    setCellFont(object, font)
    setCellFill(object, fill)
    setCellBorder(object, border)
    setCellAlignment(object, alignment)
    setObjectHeight(object, height)
}

const setCellValue = (cell, content) => {
    cell.value = content
}

const setRowHeight = (row, height) => {
    row.height = height
}

/**
 *
 * @param {Exceljs.Worksheet} ws 
 * @param {*} widthObj
 */
const setColumnsWidth = (ws, widthObj) => {
    ws.columns = widthObj
}

const setNumFmt = (cell, format) => {
    cell.numFmt = format
}

/**
 * 
 * @param {Exceljs.Worksheet} ws 
 * @param {string} content
 * @param {*} config
 */

const addSingleItem = (ws, content, config) => {
    /*     console.log(config) */
    mergeItemCells(ws, config.mergedCells)

    const { rowPosition, } = getRowAndCellPosition(config.cellPosition)

    const itemRow = ws.getRow(rowPosition)
    const itemCell = ws.getCell(config.cellPosition)

    setRowHeight(itemRow, config.height)

    setCellFont(itemCell, config.font)
    setCellValue(itemCell, content)

    return itemCell
}

/**
* 
* @param {Exceljs.Worksheet} ws 
* @param {*} rowValues 
* @param {*} offset 
*/
const addHeaders = (ws, rowValues, headerConfig, styleConfig) => {
    const headersConfig = headerConfig.map(config => ({ ...HEADER_LABEL_CONFIG, ...config }))
    /*     console.log(headersConfig) */
    rowValues.forEach((rowVal, index) => {
        const addedItem = addSingleItem(ws, rowVal, headersConfig[index])
        const { numFmt, font, fill, border, alignment } = styleConfig
        setObjectStyle(addedItem, font, fill, border, alignment)
        setNumFmt(addedItem, numFmt)
    })
}

const getHeaderContentFromData = (config, data) => config.map(item => data[item.accessor])

const addDataLabels = (ws, row, labels) => {
    return ws.insertRow(row, labels)
}

// const styleDataLabelRow = (row, styleConfig) => {
//     const { font, fill, border, alignment, height } = styleConfig
//     setObjectStyle(row, font, fill, border, alignment, height)
// }

const formatDataContent = (data, config) => {
    return config.map(item => data[item.accessor])
}

const clearStyle = (obj) => {
    const { font, fill, border, alignment, height } = CLEAR_STYLE
    setObjectStyle(obj, font, fill, border, alignment, height)
}

const addStyleToRowCells = (row, font, fill, border, alignment, height) => {
    row.eachCell((cell, colNumber) => {
        setObjectStyle(cell, font, fill, border, alignment, height)
    })
}   

const addNumFmtToRowCells = (row, fmt) => {
    row.eachCell((cell, colNum) => {
        setNumFmt(cell, fmt)
    })
}

const addDataContent = (ws, initialRow, data, config, styleConfig) => {

    // const firstDataContentRow = ws.getRow(initialRow)
    const { font, fill, border, alignment, height, numFmt } = styleConfig
    // setObjectStyle(firstDataContentRow, font, fill, border, alignment, height)


    data.forEach((item, index) => {
        const rowStyle =  'o'
        const dataContent = formatDataContent(item, config)
        const rowIndexToInsert = initialRow + index
        const insertedRow = ws.insertRow(rowIndexToInsert, dataContent, rowStyle)

        addStyleToRowCells(insertedRow, font, fill, border, alignment, height)
        addNumFmtToRowCells(insertedRow, numFmt)
    })

    // remaining row with data content style
    const lastRowIndex = initialRow + data.length
    const rowToClear = ws.getRow(lastRowIndex)
    clearStyle(rowToClear)
    return lastRowIndex

}

const addSignatureField = (ws, row, config) => {
    config.forEach((item, index) => {
        const actualRow = row + index
        const mergeRange = getRangeMergeRange(actualRow, item.mergeColumns)
        mergeItemCells(ws, mergeRange)

        const cell = ws.getCell(item.mergeColumns[0] + actualRow)
        setCellValue(cell, item.label)

        const { font, fill, border, alignment, height } = item.style
        setObjectStyle(cell, font, fill, border, alignment, height)

        const rowObj = ws.getRow(actualRow)
        setRowHeight(rowObj, item.style.height)
    })
}

// TODO: review
const addStyleToDataLabelRow = (row, styleConfig) => {
    const { font, fill, border, alignment, height } = styleConfig
    addStyleToRowCells(row, font, fill, border, alignment, height)
    
}


export const generateSpreadsheet = async (name, reportData) => {
    try {
        console.log(reportData)
        const workbook = createAndConfigureWorkbook()
        const dispatchWorkSheet = createWorksheet(workbook, workBookNames[tempType])

        const title = getDeliveryNotesTitle(reportData.config.type)
        const { headerLabelConfig, headerContentConfig } = getDeliveryNotesHeaderConfig(reportData.config.type)

        // format columns sizes
        setColumnsWidth(dispatchWorkSheet, STANDADARD_COLUMNS_WIDTH)
        // title
        addSingleItem(dispatchWorkSheet, title, TITLE_CONFIG)
        //subtitle
        addSingleItem(dispatchWorkSheet, HEADER_SUBTITLE_CONFIG.content, HEADER_SUBTITLE_CONFIG)

        // add header labels
        const headerLabels = headerLabelConfig.map(config => config.label)
        addHeaders(dispatchWorkSheet, headerLabels, headerLabelConfig, HEADER_LABEL_STYLE)

        // add header content
        const headerContents = getHeaderContentFromData(headerContentConfig, reportData.data.header)
        addHeaders(dispatchWorkSheet, headerContents, headerContentConfig, HEADER_CONTENT_STYLE)


        // add data labels
        const dataLabels = DELIVERY_NOTES_RIG_PREP_DATA_LABEL_CONFIG.map(config => config.label)
        const dataLabelRow = addDataLabels(dispatchWorkSheet, DATA_LABEL_CONFIG.row, dataLabels)
        addStyleToDataLabelRow(dataLabelRow, DATA_LABEL_STYLE)

        // add data content
        const lastContentRow = addDataContent(dispatchWorkSheet, DATA_CONTENT_CONFIG.row, reportData.data.rows, DELIVERY_NOTES_RIG_PREP_DATA_CONTENT_CONFIG, DATA_CONTENT_STYLE)

        //add signature column for shipper
        addSignatureField(dispatchWorkSheet, lastContentRow + DELIVERY_NOTES_SIGNATURE_GAP, DELIVERY_NOTES_SIGNATURE_SHIPPER_CONFIG)
        //add signature column for receiver
        addSignatureField(dispatchWorkSheet, lastContentRow + DELIVERY_NOTES_SIGNATURE_GAP, DELIVERY_NOTES_SIGNATURE_RECEIVER_CONFIG)


        const buffer = await workbook.xlsx.writeBuffer()
        saveAs(new Blob([buffer]), name + FILE_EXTENSION)
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}
