/* import { UNIQUE_UPLOAD_PROPERTIES } from 'utils/constants'
 */const Ajv = require('ajv').default
const XLSX = require('xlsx')

/**
 * Validates the data for the current schema
 * @param {any[]} data To be analysed 
 * @returns True or the error message
 */
const validateData = (data, validate) => {
    const isValid = validate(data)
    return isValid ? [] : validate.errors
}

/**
 * Converts the uploadded spreadsheet to worksheet
 * @param {Event} evt The upload event
 * @returns The converted worksheet to workbook
 */
const fileToWorkSheet = (evt) => {
    const bstr = evt.target.result
    const wb = XLSX.read(bstr, { type: 'binary' })
    const wsName = wb.SheetNames[0]
    const ws = wb.Sheets[wsName]
    return ws
}

const unique = (arr) => [...new Set(arr)]

const hasDuplicatedValues = (data, uniqueValues) => uniqueValues?.length < data?.length


/**
 * Checks for duplicated values in the prop column
 * @param {any[]} data The data on which the validation will be applied
 * @param {any[]} uniqueValues The list of 
 * @param {String} prop The type of column
 * @returns All duplicated values on column
 */
/* const checkForDuplicates = (data, uniqueValues, prop) => {
    const errors = []
    let duplicatedLines = []

    if (hasDuplicatedValues(data, uniqueValues)) {
        uniqueValues.forEach(value => {
            duplicatedLines = data.map((item, index) => item[prop] === value ? index + 2 : null).filter(val => val !== null)
            if (duplicatedLines.length > 1) {
                errors.push({ Location: 'At lines: ' + duplicatedLines.toString(), Error: `Duplicate values (${value}) at ${prop} column` })
            }
        })
    }
    return errors
}
 */
/**
 * Checks within the file and gets the duplicated lines
 * @param {any[]} data The data on which the validation will be applied
 * @param {Schema} schema The validation schema
 * @param {String} type The type of validation
 * @returns 
 */
const checkIfHasErrors = (data, schema) => {
    const errors = []
/*     const propValues = data
        .map(item => item[UNIQUE_UPLOAD_PROPERTIES])
        .filter(val => val !== undefined)

    const allUniqueByColumnProp = unique(propValues)
 */
/*     // Unique ERP Ref 
    const duplicationErrors = checkForDuplicates(data, allUniqueByColumnProp, UNIQUE_UPLOAD_PROPERTIES)
    errors.push(...duplicationErrors) */

    // Not Unique ERP Ref, Purchase Order and Purchase Item 
    const notUniqueCombinationErrors = checkUniqueCombination(data)
    errors.push(...notUniqueCombinationErrors)

    const jsonErrors = checkJsonErrors(data, schema)
    errors.push(...jsonErrors)

    return { errors, data }
}

const getValidationFunction = (schema) => {
    const ajv = new Ajv({ allErrors: true, coerceTypes: true })
    require("ajv-errors")(ajv)
    const validate = ajv.compile(schema)
    return validate
}

/**
 * Checks within the file for generaal erros that the user should known
 * @param {any[]} data The data on which the validation will be applied
 * @param {Schema} schema The validation schema
 * @returns All erros found within the file
 */
const checkJsonErrors = (data, schema) => {
    const errors = []
    const validate = getValidationFunction(schema)
    data.forEach((item, index) => {
        const validationErrors = validateData(item, validate)
        if (validationErrors.length > 0) {
            validationErrors.map((error) => errors.push({ Location: 'At line: ' + (index + 2), Error: error.message }))
        }
    })

    return errors
}

const getUniqueCombinationKey = (item) => item["erp_ref"] + item["co_purchase_order"] + item["co_purchase_item"]

/**
 * Checks if the data in the file have duplicated ERP REF, PO and PI
 * @param {any[]} data The data on which the validation will be applied
 * @returns The Erp Ref errors in the file
 */
const checkUniqueCombination = (data) => {
    const errors = []
    const uniqueKeyValues = data.map(item => getUniqueCombinationKey(item))
    const uniqueValues = unique(uniqueKeyValues)

    uniqueValues.forEach(value => {
        const duplicatedCombination = uniqueKeyValues.map((item, i) => item === value ? i + 2 : null).filter(p => p !== null)
        if (hasDuplicatedValues(data, uniqueValues) && duplicatedCombination.length > 1) {
            errors.push({ Location: 'At lines: ' + duplicatedCombination.toString(), Error: 'Values combination of erp_ref, co_purchase_order and co_purchase_item are not unique in these lines' })
        }
    })
    return errors
}

/**
 * 
 * @param {File} acceptedFile The file which the user intend to upload
 */
export const xlsxValidator = (acceptedFile, cb, schema) => {
    const fileReader = new FileReader()
    fileReader.readAsBinaryString(acceptedFile)
    fileReader.onload = (evt) => {
        const data = XLSX.utils.sheet_to_json(fileToWorkSheet(evt), { header: 2 })
        data.map(item => { 
            item.quantity = item.pipe_qty_expected
            delete item.pipe_qty_expected 
            return item 
        })
        cb(checkIfHasErrors(data, schema))
    }
}

export const exportErrorsToSheet = async (data) => {
    const timestamp = new Date().getTime()
    const file_name = 'validation_errors_' + timestamp + '.xlsx'

    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'errors_found_on_validation')
    XLSX.writeFile(wb, file_name)
}