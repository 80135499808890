import _ from 'lodash'

/**
 *  File to keep all the dataport queries to avoid duplicates and make easier the visualization
 */
/**
 * Get values of 'il_created' that indicates which days inspections were made
 */
export const getSpotInspectionReportDatesQuery = (datasets) => {
  return {
    datasets: [datasets.spot_inspection_report],
    logic_op: 'AND',
    sort: ['created'],
    select: ['id', 'created'],
    columns: []
  }
}

/**
 * Gets the information of inspection made in the day of the argument
 * @param day: timestampz from postgres
 */
export const getSpotInspectionReportDataQuery = (id, datasets) => {
  return {
    datasets: [datasets.spot_inspection_report],
    logic_op: 'AND',
    sort: ['il_created'],
    select: ['id', 'created', 'co_purchase_order', 'co_purchase_item', 'num_sales_order', 'num_sales_item', 'cat_id', 'valid', 'dsc_material', 
    'mark_legibility_report', 'pin_report', 'box_report', 'int_body_report', 'ext_body_report', 'drift_report', 'final_report', 'responsible',
    'mark_defect', 'pin_defect', 'box_defect', 'int_body_defect', 'ext_body_defect', 'drift_defect', 'remarks', 'mark_legibility_photo', 'pin_photo',
    'box_photo', 'int_body_photo', 'ext_body_photo', 'drift_photo'
  ],
    columns: [{ slug: 'id', operator: 'equal', value: id }]
  }
}

export const getMillReceiptsListQuery = (endUsers, datasets) => {
  return {
    datasets: [datasets.mill_receipt_list],
    logic_op: 'OR',
    select: [
      'beid',
      'owner',
      'entrance_date',
      'dsc_material',
      'pipe_count',
      'pipes_length',
      'pipes_weight',
      'val_id',
      'cat_id',
      'co_purchase_item',
      'co_purchase_order',
      'vtsa_order_item',
      'vtsa_sales_order',
    ],
    columns: _.map(endUsers, endUser => ({
      slug: 'owner',
      operator: 'equal',
      value: endUser
    }))
  }
}

export const getMillReceiptReportQuery = (beid, rev, datasets) => {
  return {
    datasets: [datasets.mill_receipt],
    logic_op: 'AND',
    select: [
      'beid',
      'co_purchase_item',
      'co_purchase_order',
      'vtsa_order_item',
      'vtsa_sales_order',
      'cat_id',
      'owner',
      'entrance_date',
      'dsc_material',
      'pipe_count',
      'pipe_length',
      'pipe_weight',
      'val_id'
    ],
    columns: [
      { slug: 'beid', operator: 'equal', value: beid },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

/**
 * Get values of 'expected_date' that indicates which days rigprep were expected
 */
/* export const getRigPrepReportDataIdsQuery = (endUsers, datasets) => {
  return {
    datasets: [datasets.rigprep_report],
    logic_op: 'OR',
    sort: [],
    select: ['owner_name', 'closure_date', 'mr_ref', 'rplc_id', 'rpl_id'],
    columns: _.map(endUsers, endUser => ({
      slug: 'owner_name',
      operator: 'equal',
      value: endUser
    }))
  }
} */

export const getMaterialRequisitionReportDataIdsQuery = (datasets) => {
  return {
    datasets: [datasets.material_requisition_list],
    logic_op: 'OR',
    sort: [],
    select: ['id', 'mr_ref', 'closure_date', 'dispatch_type', 'dispatch_type_id'],
    columns: []
  }
}

export const getDispatchNotesDataIdsQuery = (endUsers, datasets) => {
  return {
    datasets: [datasets.dispatch_notes_list],
    logic_op: 'OR',
    sort: [],
    select: ['id', 'owner_name', 'dispatch_type', 'closure_date', 'owner_id','mr_ref', 'dsc_material', 'dispatch_type_id'],
    columns: _.map(endUsers, endUser => ({
      slug: 'owner_name',
      operator: 'equal',
      value: endUser
    }))
  }
}

export const getDispatchNotesDataFromDeliveryToThirdParty = (id, ownerId, rev, datasets) => {
  return {
    datasets: [datasets.dispatch_notes_third_party_data],
    logic_op: 'AND',
    sort: [],
    select: ['id', 'name', 'dsc_material', 'tparty_name', 'tparty_loc', 'mr_ref', 'ltpa', 'closure_date',  'twlc_quantity_prep', 'owner_id', 'owner_name', 'total_length', 'total_weight', 'co_purchase_order', 'co_purchase_item', 'num_sales_order', 'num_sales_item', 'cat_id'],
    columns: [
      { slug: 'id', operator: 'equal', value: id },
      { slug: 'owner_id', operator: 'equal', value: ownerId },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getDispatchNotesDataFromRigPrep = (id, ownerId, rev, datasets) => {
  return {
    datasets: [datasets.dispatch_notes_rigprep_report_data],
    logic_op: 'AND',
    sort: [],
    select: ['id', 'name', 'dsc_material', 'field', 'rig', 'well', 'mr_ref', 'ltpa', 'expected_date', 'closure_date',  'rplc_quantity_prep', 'owner_id', 'owner_name', 'total_length', 'total_weight', 'co_purchase_order', 'co_purchase_item', 'num_sales_order', 'num_sales_item', 'cat_id'],
    columns: [
      { slug: 'id', operator: 'equal', value: id },
      { slug: 'owner_id', operator: 'equal', value: ownerId },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getDeliveryNotesDataFromMatPhysicalTransfer = (id, ownerId, rev, datasets) => {
  return {
    datasets: [datasets.delivery_notes_mat_physical_transfer_report_data],
    logic_op: 'AND',
    sort: [],
    select: ['id', 'name', 'dsc_material', 'tparty_name', 'tparty_loc', 'mr_ref', 'ltpa', 'closure_date',  'ptlc_quantity_prep', 'owner_id', 'owner_name', 'total_length', 'total_weight', 'co_purchase_order', 'co_purchase_item', 'num_sales_order', 'num_sales_item', 'cat_id'],
    columns: [
      { slug: 'id', operator: 'equal', value: id },
      { slug: 'owner_id', operator: 'equal', value: ownerId },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getDispatchNotesDataFromMaterialModification = (id, ownerId, rev, datasets) => {
  return {
    datasets: [datasets.dispatch_notes_material_modification_data],
    logic_op: 'AND',
    sort: [],
    select: ['id', 'name', 'dsc_material', 'tparty_name', 'tparty_loc', 'mr_ref', 'ltpa', 'closure_date',  
             'mmlc_quantity_prep', 'owner_id', 'owner_name', 'total_length', 'total_weight', 'co_purchase_order', 
             'co_purchase_item', 'num_sales_order', 'num_sales_item', 'cat_id', 'end_user'],
    columns: [
      { slug: 'id', operator: 'equal', value: id },
      { slug: 'owner_id', operator: 'equal', value: ownerId },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getMaterialRequisitionReportDataFromDayQuery = (report, datasets) => {
  return {
    datasets: [datasets.material_requisition_report_data],
    logic_op: "AND",
    select: ["id",
      "wid",
      "rpl_name",
      "rplc_id",
      "field",
      "rig",
      "well",
      "mr_ref",
      "ltpa",
      "expected_date",
      "quantity",
      "status",
      "valid",
      "it_status",
      "dsc_material",
      "insp_status",
      "mark_legibility_report",
      "pin_report",
      "box_report",
      "int_body_report",
      "ext_body_report",
      "drift_report",
      "final_report",
      "insp_responsible",
      "measure_status",
      "measured_lenght",
      "makeup_loss",
      "effective_lenght",
      "measure_responsible",
      "co_purchase_order",
      "co_purchase_item",
      "num_sales_order",
      "num_sales_item",
      "cat_id",
      "mark_defect",
      "pin_defect",
      "box_defect",
      "int_body_defect",
      "ext_body_defect",
      "drift_defect",
      "remarks",
      "mark_legibility_photo",
      "pin_photo",
      "box_photo",
      "int_body_photo",
      "ext_body_photo",
      "drift_photo", 
      "int_diameter", 
      "out_diameter", 
      "instruments", 
      "end_user"
      ],
      columns: [{
        slug: 'id',
        operator: 'equal',
        value: report.id
      }, 
      { slug: 'revision', operator: 'equal', value: report.revision }]
  }
}

export const getMatRequisitionDeliveryThirdPartyReportDataFromDayQuery = (report, datasets) => {
  return {
    datasets: [datasets.material_requisition_delivery_third_party_data],
    logic_op: "AND",
    select: ["id",
      "wid",
      "twl_name",
      "twlc_id",
      "tparty_name",
      "tparty_loc",
      "mr_ref",
      "ltpa",
      "closure_date",
      "quantity",
      "status",
      "co_purchase_order",
      "co_purchase_item",
      "num_sales_order",
      "num_sales_item",
      "cat_id",
      "valid",
      "it_status",
      "dsc_material",
      "insp_status",
      "mark_legibility_report",
      "pin_report",
      "box_report",
      "int_body_report",
      "ext_body_report",
      "drift_report",
      "final_report",
      "insp_responsible",
      "measure_status",
      "measured_lenght",
      "makeup_loss",
      "effective_lenght",
      "measure_responsible",
      "mark_defect",
      "pin_defect",
      "box_defect",
      "int_body_defect",
      "ext_body_defect",
      "drift_defect",
      "remarks",
      "mark_legibility_photo",
      "pin_photo",
      "box_photo",
      "int_body_photo",
      "ext_body_photo",
      "drift_photo", 
      'int_diameter',
      'out_diameter', 
      'instruments', 
      'end_user'
    ],
    columns: [{
      slug: 'id',
      operator: 'equal',
      value: report.id
    }, 
    { slug: 'revision', operator: 'equal', value: report.revision }]
  }
}

export const getMatRequisitionMatModificationReportDataFromDayQuery = (report, datasets) => {
  return {
    datasets: [datasets.material_requisition_mat_modification_data],
    logic_op: "AND",
    select: ["id",
      "wid",
      "mml_name",
      "mmlc_id",
      "tparty_name",
      "tparty_loc",
      "mr_ref",
      "ltpa",
      "closure_date",
      "quantity",
      "status",
      "co_purchase_order",
      "co_purchase_item",
      "num_sales_order",
      "num_sales_item",
      "cat_id",
      "valid",
      "it_status",
      "dsc_material",
      "insp_status",
      "mark_legibility_report",
      "pin_report",
      "box_report",
      "int_body_report",
      "ext_body_report",
      "drift_report",
      "final_report",
      "insp_responsible",
      "measure_status",
      "measured_lenght",
      "makeup_loss",
      "effective_lenght",
      "measure_responsible",
      "mark_defect",
      "pin_defect",
      "box_defect",
      "int_body_defect",
      "ext_body_defect",
      "drift_defect",
      "remarks",
      "mark_legibility_photo",
      "pin_photo",
      "box_photo",
      "int_body_photo",
      "ext_body_photo",
      "drift_photo", 
      "int_diameter",
      "out_diameter", 
      "instruments", 
      "end_user"
      ],
      columns: [{
        slug: 'id',
        operator: 'equal',
        value: report.id
      },       
      { slug: 'revision', operator: 'equal', value: report.revision }]
  }
}

export const getMatRequisitionMatPhysicalTransferReportDataFromDayQuery = (report, datasets) => {
  return {
    datasets: [datasets.material_requisition_mat_physical_transfer_data],
    logic_op: "AND",
    select: [
      'id', 'wid', 'ptl_name', 'ptlc_id', 'tparty_name', 'tparty_loc', 'mr_ref', 'ltpa', 'closure_date', 'quantity', 'status', 'co_purchase_order', 'co_purchase_item', 'num_sales_order', 'num_sales_item', 'cat_id', 'valid', 'it_status', 'dsc_material',
      'insp_status', 'mark_legibility_report', 'pin_report', 'box_report', 'int_body_report', 'ext_body_report', 'drift_report', 'final_report', 'insp_responsible', 'measure_status', 'measured_lenght', 'makeup_loss', 'effective_lenght', 'measure_responsible', 'mark_defect', 
      'pin_defect', 'box_defect', 'int_body_defect', 'ext_body_defect', 'drift_defect', 'remarks', 'mark_legibility_photo', 'pin_photo', 'box_photo', 'int_body_photo', 'ext_body_photo', 'drift_photo', 'revision', 'int_diameter', 'out_diameter', 'instruments',  'end_user'],
      columns: [
        { slug: 'id', operator: 'equal', value: report.id },       
        { slug: 'revision', operator: 'equal', value: report.revision }
      ]
  }
}

/* export const getRigPrepReportDataFromDayQuery = (report, datasets) => {
  return {
    datasets: [datasets.rigprep_report],
    logic_op: 'OR',
    sort: [],
    select: [
      'wid',
      'rpl_name',
      'rpl_id',
      'rplc_id',
      'field',
      'rig', 'well',
      'mr_ref',
      'ltpa',
      'call_out_ref',
      'expected_date',
      'quantity',
      'status',
      'valid',
      'it_status',
      'dsc_material',
      'insp_status',
      'mark_legibility_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report', 'final_report',
      'insp_responsible',
      'measure_status',
      'measured_length',
      'makeup_loss',
      'owner_id',
      'effective_length',
      'measure_responsible',
      'owner_name',
    ],
    columns: _.map(_.get(report, 'rplc_ids', []), id => ({
      slug: 'rplc_id',
      operator: 'equal',
      value: id
    }))
  }
} */

/**
 * Get the parameters to template generator api to gen a daily report
 *
 */
/* export const getRigPrepReportDownloadQuery = (job, datasets) => {
  return {
    download: {
      id: 'rigPrep_report_template',
      description: '',
      datasetId: datasets.rigprep_report,
      columns: [],
      select: [
        'wid',
        'rpl_name',
        'rpl_id',
        'field',
        'rig', 'well',
        'mr_ref',
        'ltpa',
        'call_out_ref',
        'expected_date',
        'quantity',
        'status',
        'valid',
        'it_status',
        'dsc_material',
        'insp_status',
        'mark_legibility_report',
        'pin_report',
        'box_report',
        'int_body_report',
        'ext_body_report',
        'drift_report', 'final_report',
        'insp_responsible',
        'measure_status',
        'measured_length',
        'makeup_loss',
        'effective_length',
        'measure_responsible',
        'owner_name',
        'rplc_id'
      ],
      sort: [],
      jobID: job.id,
      totalRows: 200,
      extension: 'xlsx',
      token: process.env.REACT_APP_DATAPORT_API_KEY
    }
    // tslint:disable-next-line:semicolon
  }
} */

/**
 * Gets the material information of the argument catId
 * @param sapReference: sapReference of the material
 */
export const materialDescriptionDataQuery = (sapReference, datasets) => {
  return {
    datasets: [datasets.material_description],
    logic_op: 'AND',
    select: [
      'erp_reference',
      'values',
      'symbols',
      'descriptions',
      'length_mm',
      'weight_kg'
    ],
    columns: [{ slug: 'erp_reference', operator: 'equal', value: sapReference }]
  }
}

/**
 * Gets pipe data from
 * @param valids: array of Valids to query
 */
export const getPipeDataQuery = (valids, datasets) => {
  return {
    datasets: [datasets.pipe_data],
    logic_op: 'OR',
    select: [
      'val_id',
      'cat_id',
      'num_sales_order',
      'num_sales_item',
      'co_purchase_order',
      'co_purchase_item',
      'heat',
      'id_quality_certificate',
      'pipe_length',
      'pipe_weight',
      'id_plant',
      'certificate_path'
    ],
    // todo: find out how to filter passing an array of valids
    columns: valids.map(valid => ({ slug: 'val_id', operator: 'equal', value: valid }))
  }
}

/**
 * Gets the nominal material information of the argument catId
 * @param sapReference: sapReference of the material
 */
export const getNominalDataQuery = (sapReference, datasets) => {
  return {
    datasets: [datasets.nominal_data],
    logic_op: 'AND',
    select: ['makeup_loss', 'revision', 'standard', 'drift', 'weight_nominal', 'material_type', 'dth_reg_update', 'dth_reg_criation', 'length_nominal', 'type_material', 'connection', 'grade', 'wt_nominal', 'od_nominal', 'dsc_material', 'material', 'id_plant', 'storage_type', 'serial_number'],
    columns: [{ slug: 'material', operator: 'equal', value: sapReference }]
  }
}

export const getReceivingInspectionListQuery = (endUsers, datasets) => {
  return {
    datasets: [datasets.receive_inspection_list],
    logic_op: 'OR',
    select: [
      'rlid',
      'inspection_date',
      'customer_po',
      'customer_item',
      'owner',
      'inspected_pipe_count',
      'accept_count',
      'repair_count',
      'reject_count',
      'num_sales_order',
      'num_sales_item',
      'cat_id',
      'dsc_material'
    ],
    columns: _.map(endUsers, endUser => ({
      slug: 'owner',
      operator: 'equal',
      value: endUser
    }))
  }
}

export const getReceivingInspectionDataQuery = (rlid, dscMaterial, rev, datasets) => {
  return {
    datasets: [datasets.receive_inspection_data],
    logic_op: 'AND',
    select: [
      'id',
      'closure_date',
      'customer_po',
      'customer_item',
      'num_sales_order',
      'num_sales_item',
      'dsc_material',
      'valid',
      'mark_legibility_report',
      'coating_varnish_report',
      'thread_prot_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report',
      'final_report',
      'responsible',
      'mark_defect',
      'coat_varn_defect',
      'prot_defect',
      'pin_defect',
      'box_defect',
      'int_body_defect',
      'ext_body_defect',
      'drift_defect',
      'remarks',
      'mark_legibility_photo',
      'coating_varnish_photo',
      'thread_prot_photo',
      'pin_photo',
      'box_photo',
      'int_body_photo',
      'ext_body_photo',
      'drift_photo',
      'cat_id', 
      'instruments', 
      'end_user'
    ],
    columns: [
      { slug: 'id', operator: 'equal', value: rlid },
      { slug: 'dsc_material', operator: 'equal', value: dscMaterial },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getReceivingInspectionArchivedDataQuery = (rlid, rev, datasets) => {
  return {
    datasets: [datasets.receive_inspection_data],
    logic_op: 'AND',
    select: [
      'id',
      'closure_date',
      'customer_po',
      'customer_item',
      'num_sales_order',
      'num_sales_item',
      'dsc_material',
      'valid',
      'mark_legibility_report',
      'coating_varnish_report',
      'thread_prot_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report',
      'final_report',
      'responsible',
      'mark_defect',
      'coat_varn_defect',
      'prot_defect',
      'pin_defect',
      'box_defect',
      'int_body_defect',
      'ext_body_defect',
      'drift_defect',
      'remarks',
      'mark_legibility_photo',
      'coating_varnish_photo',
      'thread_prot_photo',
      'pin_photo',
      'box_photo',
      'int_body_photo',
      'ext_body_photo',
      'drift_photo',
      'cat_id', 
      'instruments', 
      'end_user'
    ],
    columns: [
      { slug: 'id', operator: 'equal', value: rlid },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getPeriodicalInspectionListQuery = (endUsers, datasets) => {
  return {
    datasets: [datasets.periodical_inspection_list],
    logic_op: 'OR',
    select: [
      'rlid',
      'inspection_date',
      'customer_po',
      'customer_item',
      'owner',
      'inspected_pipe_count',
      'accept_count',
      'repair_count',
      'reject_count',
      'num_sales_order',
      'num_sales_item',
      'cat_id',
      'dsc_material'
    ],
    columns: _.map(endUsers, endUser => ({
      slug: 'owner',
      operator: 'equal',
      value: endUser
    }))
  }
}

export const getPeriodicalInspectionDataQuery = (pilid, dscMaterial, rev, datasets) => {
  return {
    datasets: [datasets.periodical_inspection_data],
    logic_op: 'AND',
    select: [
      'id',
      'closure_date',
      'customer_po',
      'customer_item',
      'sales_order',
      'sales_item',
      'dsc_material',
      'valid',
      'mark_legibility_report',
      'coating_varnish_report',
      'thread_prot_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report',
      'final_report',
      'responsible',
      'mark_defect',
      'coat_varn_defect',
      'prot_defect',
      'pin_defect',
      'box_defect',
      'int_body_defect',
      'ext_body_defect',
      'drift_defect',
      'remarks',
      'mark_legibility_photo',
      'coating_varnish_photo',
      'thread_prot_photo',
      'pin_photo',
      'box_photo',
      'int_body_photo',
      'ext_body_photo',
      'drift_photo',
      'cat_id',
      'entrance_id', 
      'instruments', 
      'end_user'
    ],
    columns: [
      { slug: 'id', operator: 'equal', value: pilid },
      { slug: 'dsc_material', operator: 'equal', value: dscMaterial },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getPeriodicalInspectionArchivedDataQuery = (pilid, rev, datasets) => {
  return {
    datasets: [datasets.periodical_inspection_data],
    logic_op: 'AND',
    select: [
      'id',
      'closure_date',
      'customer_po',
      'customer_item',
      'sales_order',
      'sales_item',
      'dsc_material',
      'valid',
      'mark_legibility_report',
      'coating_varnish_report',
      'thread_prot_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report',
      'final_report',
      'responsible',
      'mark_defect',
      'coat_varn_defect',
      'prot_defect',
      'pin_defect',
      'box_defect',
      'int_body_defect',
      'ext_body_defect',
      'drift_defect',
      'remarks',
      'mark_legibility_photo',
      'coating_varnish_photo',
      'thread_prot_photo',
      'pin_photo',
      'box_photo',
      'int_body_photo',
      'ext_body_photo',
      'drift_photo',
      'cat_id',
      'entrance_id', 
      'instruments'
    ],
    columns: [
      { slug: 'id', operator: 'equal', value: pilid },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getRigReturnReportListQuery = (endUsers, datasets) => {
  return {
    datasets: [datasets.rig_return_report_list],
    logic_op: 'OR',
    select: [
      'rrlid',
      'name',
      'closure_date',
      'owner',
      'nt_quantity',
      'pipe_count',
      'accept_count',
      'repair_count',
      'reject_count',
      'rr_ref'
    ],
    columns: _.map(endUsers, endUser => ({
      slug: 'owner',
      operator: 'equal',
      value: endUser
    }))
  }
}

export const getRigReturnReportDataQuery = (rrlid, rev, datasets) => {
  return {
    datasets: [datasets.rig_return_report_data],
    logic_op: 'AND',
    select: [
      'closure_date',
      'field',
      'rig',
      'well',
      'rr_ref',
      'end_user',
      'nt_quantity',
      'co_purchase_order',
      'co_purchase_item',
      'num_sales_order',
      'num_sales_item',
      'dsc_material',
      'valid',
      'mark_legibility_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report',
      'final_report',
      'responsible',
      'id',
      'cat_id',
      'mark_defect',
      'coat_varn_defect',
      'prot_defect',
      'pin_defect',
      'box_defect',
      'int_body_defect',
      'ext_body_defect',
      'drift_defect',
      'remarks',
      'mark_legibility_photo',
      'coating_varnish_photo',
      'thread_prot_photo',
      'pin_photo',
      'box_photo',
      'int_body_photo',
      'ext_body_photo',
      'drift_photo', 
      'instruments'
    ],
    columns: [
      { slug: 'id', operator: 'equal', value: rrlid },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getModifiedProductsDataQuery = (mplid, rev, datasets) => {
  return {
    datasets: [datasets.modified_products_report_data],
    logic_op: 'AND',
    select: [
      'id',
      'wid',
      'mpl_name',
      'mplc_id',
      'er_ref',
      'closure_date',
      'status',
      'cat_id',
      'valid',
      'it_status',
      'dsc_material',
      'insp_status', 
      'mark_legibility_report',
      'pin_report',
      'box_report',
      'int_body_report',
      'ext_body_report',
      'drift_report',
      'final_report',
      'insp_responsible',
      'measure_status', 
      'measured_lenght', 
      'makeup_loss',
      'effective_lenght',
      'measure_responsible',
      'mark_defect',
      'pin_defect',
      'box_defect',
      'int_body_defect',
      'ext_body_defect',
      'drift_defect',
      'mark_legibility_photo',
      'coating_varnish_photo',
      'thread_prot_photo',
      'pin_photo',
      'box_photo',
      'int_body_photo',
      'ext_body_photo',
      'drift_photo',
      'remarks',
      'int_diameter', 
      'out_diameter', 
      'instruments', 
      'owner'
    ],
    columns: [
      { slug: 'id', operator: 'equal', value: mplid },
      { slug: 'revision', operator: 'equal', value: rev }
    ]
  }
}

export const getSlowMovingDataQuery = (id, datasets) => {
  return {
    datasets: [datasets.slow_moving_pipes_report_data],
    logic_op: 'AND',
    select: [
      'id', 'trigger_date', 'ltpa', 'co_purchase_order', 'co_purchase_item', 'num_sales_item', 'num_sales_order', 'cat_id', 'dsc_material', 
    'ownership', 'entrance_date', 'months_in_stock', 'pipe_count', 'pipes_length', 'pipes_weight', 'storage_type', 'end_user'
    ],
    columns: [ { slug: 'id', operator: 'equal', value: id }]
  }
}

export const getTitleTransferDataQuery = (id, datasets) => {
  return {
    datasets: [datasets.title_transfer_report_data],
    logic_op: 'AND',
    select: [
      'id', 'trigger_date', 'ltpa', 'co_purchase_order', 'co_purchase_item', 'num_sales_item', 'num_sales_order', 'cat_id', 'dsc_material', 
    'ownership', 'entrance_date', 'months_in_stock', 'pipe_count', 'pipes_length', 'pipes_weight', 'storage_type', 'end_user'
    ],
    columns: [ { slug: 'id', operator: 'equal', value: id }]
  }
}

export const getMaterialPhysicalTransferDataQuery = (id, datasets) => {
  return {
    datasets: [datasets.material_physical_transfer_report_data],
    logic_op: 'AND',
    select: [
      'id', 'trigger_date', 'ltpa', 'co_purchase_order', 'co_purchase_item', 'num_sales_item', 'num_sales_order', 'cat_id', 'dsc_material', 
    'ownership', 'entrance_date', 'months_in_stock', 'pipe_count', 'pipes_length', 'pipes_weight', 'storage_type', 'end_user'
    ],
    columns: [ { slug: 'id', operator: 'equal', value: id }]
  }
}