import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import dataport from 'api/dataport'
import apiReports from 'api/reports'
import templateDataport from 'api/template-dataport'

import { LoadingOverlay, FlexView, Icon, Card } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'

import { reports_uri } from 'utils/constants'
import { getPeriodicalInspectionDataQuery } from 'utils/dataport-query'
import { getPeriodicalInspectionReportDownloadQuery } from 'utils/template-dataport-query'

const loadFile = url => {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.click()
}

const formatReports = reports => _.chain(reports)
  .orderBy('inspection_date', 'desc')
  .value()

const PeriodicalInspectionReports = ({ stockKey }) => {
  const { t } = useTranslation()
  const { stocks, setPageSubtitle, datasets, dataportTemplates, formatNumberByCulture } = useContext(AppContext)
  const { endUsers, idToken } = useContext(UserContext)
  const stock = useMemo(() => _.find(stocks, { key: stockKey }), [stocks, stockKey])
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(t(`stock.names.${stock.key}`) + ' - ' + t('Periodical Inspection Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const downloadReport = useCallback(async report => {
    setLoading(true)
    try {
      const query = getPeriodicalInspectionDataQuery(report.pilid, report.dsc_material, report.revision, datasets)
      const jobId = await dataport.getJobId(query)
      const downloadQuery = getPeriodicalInspectionReportDownloadQuery(jobId, datasets, dataportTemplates, report.pilid)
      const { url } = await templateDataport.loadTemplate(downloadQuery, idToken)
      loadFile(url)
      toast.info(t('documents.popupInfo'))
    }
    catch (error) {
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [datasets, dataportTemplates, idToken, t])

  const onDownloadClick = useCallback(report => () => {
    downloadReport(report)
  }, [downloadReport])

  useEffect(() => {
    setLoading(true)
    apiReports.getPeriodicalInspectionList(reports_uri.GET_PERIODICAL_INSPECTION, {}, idToken).then(data => {
      setReports(formatReports(data))
    }).catch(error => {
      toast.error(error.message)
    }).finally(() => {
      setLoading(false)
    })
  }, [endUsers, datasets, idToken])

  const columns = useMemo(() => [
    {
      accessor: 'entrance_id',
      Header: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '40px'
        }
      }
    },
    {
      Header: t('PO #'),
      accessor: 'customer_po',
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('PO Item #'),
      accessor: 'customer_item',
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('SO #'),
      accessor: 'sales_order',
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('SO Item #'),
      accessor: 'sales_item',
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      id: 'inspection_date',
      Header: t('Inspection Date'),
      accessor: ({ inspection_date }) => inspection_date ? moment(inspection_date).format('MMM D, YYYY, h:mm:ss A') : '',
      customHeaderProps: {
        style: {
          minWidth: '100px'
        }
      }
    },
    {
      Header: t('MESC/CAT ID'),
      accessor: 'cat_id',
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
    },
    {
      Header: t('Material Description'),
      accessor: 'dsc_material',
      customHeaderProps: {
        style: {
          minWidth: '220px'
        }
      },
    },
    {
      Header: `${t('Inspected')}`,
      accessor: ({ inspected_pipe_count }) => `${formatNumberByCulture(Number(inspected_pipe_count), 0)}`,
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },

    {
      Header: `${t('Accepted')}`,
      accessor: ({ accept_count }) => `${formatNumberByCulture(accept_count, 0)}`,
      // accessor: 'accept_count',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },

    {
      Header: `${t('Rejected')}`,
      accessor: ({ reject_count }) => `${formatNumberByCulture(reject_count, 0)}`,
      // accessor: 'reject_count',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: `${t('To Repair')}`,
      accessor: ({ repair_count }) => `${formatNumberByCulture(repair_count, 0)}`,
      // accessor: 'repair_count',
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      id: 'download',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(row.original)} />
      </FlexView>
    }
  ], [t, onDownloadClick, formatNumberByCulture])

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <FilterableTable columns={columns} data={reports} />
    </Card>
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default PeriodicalInspectionReports