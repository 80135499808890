import React, { useContext, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import { Card, FlexView, Spinner } from 'components/common'

import AdminGrid from 'containers/kpis/AdminGrid'
import CustomerGrid from 'containers/kpis/CustomerGrid'
import KpiHeader from 'containers/kpis/KpiHeader'
import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { KpiContext } from 'stores/KpiStore'
import { UserContext } from 'stores/UserStore'

import { getPagePermissions } from 'utils/permissions-helper'

const KpisGlobalView = ({ stockKey }) => {
  const { stocks, setPageSubtitle } = useContext(AppContext)
  const stock = useMemo(() => _.find(stocks, { key: stockKey }), [stocks, stockKey])
  const { pageLoading, kpis, adminKpis, isAdmin } = useContext(KpiContext)
  const { currentUser } = useContext(UserContext)
  const pageKey = 'KPIS'
  const pageRole = getPagePermissions(currentUser, stockKey, pageKey)
  const hasAdmin = (role) => role['KPIS_ADMIN'] || false
  const { t } = useTranslation()

  useEffect(() => {
    if (stock) {
      setPageSubtitle(t(`stock.names.${stock.key}`) + ' - ' + t('KPI Global View'))
    }
  }, [stock, setPageSubtitle, t])

  return ( 
  <FlexView alignSelf="stretch" data-cy="flex-page">
    <StockTabs stock={stock} data-cy="menu-tab" />
    <Card margin="16px" padding="16px" alignSelf="stretch" data-cy="menu-tab">
      <KpiHeader isAdmin={isAdmin} hasAdmin={hasAdmin(pageRole)} data-cy="kpi-header" ></KpiHeader>

      {pageLoading && <FlexView width="100%" padding="32px 0px" alignItems="center">
        <Spinner size="32px" />
      
      </FlexView>}
      { !pageLoading && (isAdmin
        ? <AdminGrid adminKpis={adminKpis || null}/>
        : <CustomerGrid kpis={kpis || null} />
      )}
    </Card>
  </FlexView>)
}
export default KpisGlobalView