export default {
    development: {
        VTSA: 1,
        VSB: 4,
        VSB_BARREIRO: 5,
        CAJUN: 6, 
        ENVENTURE: 7, 
        VRCC: null,
        VBR: 8, 
        ENI_MYANMAR: 3, 
        TOTAL_MYANMAR: 4,
        TOTAL_ANGOLA: null, 
        ADIPEC: null,
        CHEVRON: 1, 
        BHP: 3, 
        PTCT: null, 
        PTCT_BATAM: null
    },
    qas: {
        VTSA: 1,
        VSB: null,
        VSB_BARREIRO: 5,
        CAJUN: 6, 
        ENVENTURE: 15, 
        VRCC: 2,
        VBR: 8, 
        ENI_MYANMAR: 3, 
        TOTAL_MYANMAR: 4,
        TOTAL_ANGOLA: null, 
        ADIPEC: null,
        CHEVRON: 1, 
        BHP: 3, 
        PTCT: null, 
        PTCT_BATAM: null
    },
    staging: {
        VTSA: 1,
        CAJUN: null, 
        ENVENTURE: 4, 
        ENI_MYANMAR: null, 
        TOTAL_MYANMAR: null,
        TOTAL_ANGOLA: null, 
        ADIPEC: null,
        CHEVRON: null, 
        BHP: null, 
        PTCT: 2, 
        PTCT_BATAM: 3
    },
    production: {
        VTSA: 1,
        CAJUN: null, 
        ENVENTURE: null, 
        ENI_MYANMAR: 3, 
        TOTAL_MYANMAR: 4,
        TOTAL_ANGOLA: 1, 
        ADIPEC: 15,
        CHEVRON: 1, 
        BHP: 3, 
        PTCT: 5, 
        PTCT_BATAM: 6
    }, 
    poc: {
        VSB: 1, 
        VTSA: 1,
        CAJUN: null, 
        ENVENTURE: 4, 
        ENI_MYANMAR: null, 
        TOTAL_MYANMAR: null,
        TOTAL_ANGOLA: null, 
        ADIPEC: null,
        CHEVRON: null, 
        BHP: null, 
        PTCT: 2, 
        PTCT_BATAM: 3
    }
}