import axios from 'axios'
import _ from 'lodash'
import { addLogger } from 'utils/logger'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_TEMPLATE_API}`,
  headers: {
    'Content-Type': 'application/json',
  }
})

addLogger(instance, false)

const formatAuthorization = idToken => idToken + '.' + btoa(JSON.stringify({ 'id': process.env.REACT_APP_ID }))

const sleep = time => new Promise(resolve => setTimeout(() => resolve(), time))

const getTemplate = (params, idToken) => new Promise((resolve, reject) => {
  instance.post('/get_template', params, { headers: { Authorization: formatAuthorization(idToken) }}).then(response => {
    if (response.status === 200 && _.get(response, 'data.body')) {
      resolve(response.data.body)
    }
    else {
      reject(new Error('Couldn\'t retrieve data'))
    }
  }).catch(error => {
    reject(error)
  })
})

const waitForTemplate = async (params, idToken) => {
  try {
    let timeout = 60
    let status = ''
    let response
    while (status !== 'READY' && status !== 'FAILED' && timeout > 0) {
      status !== '' && await sleep(1000)
      response = await getTemplate(params, idToken)
      status = response.status.toUpperCase()
      timeout -= 1
    }
    if (timeout === 0) {
      throw new Error('Request timed out')
    }
    else {
      return {
        ...response,
        status
      }
    }
  }
  catch (e) {
    throw e
  }
}

const generateTemplate = (query, idToken) => new Promise((resolve, reject) => {
  instance.post('/generate_template', query, { headers: { Authorization: formatAuthorization(idToken) }}).then(response => {
    if (response.status === 200 && _.get(response, 'data.body.filename')) {
      resolve(response.data.body)
    }
    else {
      reject(new Error('Couldn\'t generate template'))
    }
  }).catch(error => {
    reject(error)
  })
})

const templateDataport = {
  loadTemplate: (query, idToken) => new Promise((resolve, reject) => {
    generateTemplate(query, idToken).then(response => {
      const { filename } = response
      waitForTemplate({ filename, token: '' }, idToken).then(response => {
        if (response.status === 'READY') {
          resolve(response)
        }
        else {
          reject(new Error('Job has failed'))
        }
      }).catch(error => {
        reject(error)
      })
    }).catch(error => {
      reject(error)
    })
  }),
}

export default templateDataport
