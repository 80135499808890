import React, { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTable, useSortBy, usePagination, useFilters, useExpanded } from 'react-table'
import { Input, Select } from 'components/form'
import { TableWrapper } from 'components/common/Table'
import { Button, Link, FlexView, Icon, Card } from 'components/common'
import { StockContext } from 'stores/StockStore'
import styled from 'styled-components'
import moment from 'moment'
import _ from 'lodash'
import { AppContext } from 'stores/AppStore'
import { useRouteMatch, useHistory } from 'react-router-dom'

const Table = styled(TableWrapper)`
  display: flex;
  flex-direction: column;

  .table-wrap {
    flex: 1;
    overflow: auto;
  }

  th input {
    font-size: ${({ theme }) => theme.fontSizes.small};
    padding: 4px;
    min-height: 0px;
  }
`

const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
  const onChange = useCallback(e => {
    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
  }, [setFilter])

  return (
    <Input
      value={filterValue || ''}
      onChange={onChange}
      placeholder="Type to Filter"
      margin="0px"
      width="100%"
    />
  )
}

const sortByDate = (rowA, rowB, columnId, desc) => {
  const valueA = _.get(rowA, `original.${columnId}`)
  const valueB = _.get(rowB, `original.${columnId}`)
  return moment(valueA).isAfter(valueB) ? 1 : -1
}

const tableOptions = {
  defaultColumn: {
    Filter: DefaultColumnFilter
  },
  getSubRows: row => row.children || [],
  paginateExpandedRows: false,
  autoResetExpanded: false,
  autoResetPage: false,
  sortTypes: {
    date: sortByDate
  }
}

const AdnocStockTable = () => {
  const { t } = useTranslation()
  const { lengthUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit, weightUnit } = useContext(AppContext)
  const { filteredStockData: { tableData }, getPipeNominalData, getPipeData } = useContext(StockContext)
  const { url } = useRouteMatch()
  const history = useHistory()

  const data = useMemo(() => (
    _.chain(tableData)
      .groupBy('sapReference')
      .map((value, key) => ({
        sapReference: key,
        dscMaterial: value[0].dscMaterial,
        qtdPC: _.sumBy(value, ({ qtdPC }) => Number(qtdPC)),
        totalFootage: _.sumBy(value, ({ totalFootage }) => Number(totalFootage)),
        totalFootage_m: _.sumBy(value, ({ totalFootage_m }) => Number(totalFootage_m)),
        totalFootage_ft: _.sumBy(value, ({ totalFootage_ft }) => Number(totalFootage_ft)),
        total_weight: _.sumBy(value, ({ total_weight }) => Number(total_weight)),
        endUser: value[0].endUser,
        children: value || []
      }))
      .value()
  ), [tableData])

  const onDetailClick = useCallback(({ valids, sapReference }) => () => {
    getPipeNominalData(sapReference)
    getPipeData(valids)
    history.push(`${url}/pipe-data`)
  }, [history, url, getPipeNominalData, getPipeData])

  const columns = useMemo(() => [
    {
      Header: t('SAP Reference'),
      accessor: 'sapReference',
      Cell: ({ cell: { value, row } }) => (
        <FlexView
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          fontSize="13px"
          padding="16px 0px"
          {...row.getToggleRowExpandedProps({
            style: {
              paddingLeft: `${row.depth * 32}px`,
              lineHeight: '1px',
              cursor: row.canExpand ? 'pointer' : 'inherit'
            },
            title: ''
          })}
        >
          {row.canExpand && <Icon name={row.isExpanded ? 'chevron-up' : 'chevron-down'} width="12px" height="12px" margin="0px 4px 0px 8px" />}
          {value === 'null' ? t('Not Found') : value}
          {!row.canExpand && <Button fontSize="12px" padding="4px 8px" margin="0px 0px 0px 8px" color="secondary" onClick={onDetailClick(row.original)}>{t('Details')}</Button>}
        </FlexView>
      ),
      customCellProps: {
        style: {
          padding: '0px'
        }
      }
    },
    {
      Header: t('End User'),
      accessor: 'endUser',
      customHeaderProps: {
        style: {
          minWidth: 50
        }
      },
    },
    {
      Header: t('Material Description'),
      accessor: 'dscMaterial',
      customHeaderProps: {
        style: {
          minWidth: 350
        }
      },
    },
    {
      Header: t('Ownership'),
      accessor: 'owner_name',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true
    },
    {
      Header: t('Entrance Date'),
      accessor: ({ entranceDate }) => entranceDate ? moment(entranceDate).format('MMM D, YYYY') : '',
      // Cell: ({ cell: { value } }) => value ? moment(value).format('MMM D, YYYY') : '',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      sortType: 'date',
      disableFilters: true
    },
    {
      Header: t('Pipe Age') + ' (' + t('Months') + ')',
      accessor: 'pipeAge',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true
    },
    {
      Header: t('Material Status'),
      id: 'dscStatusMaterial',
      accessor: ({ dscStatusMaterial }) => dscStatusMaterial && t(`stock.materialStatus.${dscStatusMaterial}`).toUpperCase(),
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
      disableFilters: true
    },
    {
      Header: t('Pieces'),
      accessor: 'qtdPC',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Total Length') + ` (${lengthUnit})`,
      accessor: ({ totalFootage_m, totalFootage_ft }) => `${convertToCurrentLengthUnit(totalFootage_m, totalFootage_ft, lengthUnit)}`,
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: `${t('Total Weight') + ` (${weightUnit})`}`,
      accessor: ({ total_weight }) => `${convertToCurrentWeightUnit(total_weight, 'kg')}`,
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    }
  ], [t, lengthUnit, weightUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit, onDetailClick])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      ...tableOptions
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  )

  return (
    <Card padding="0px" margin="0px 16px 16px" alignSelf="stretch">
      <Table elevation="none" width="100%" fontSize="13px">
        <div className="table-wrap">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.customHeaderProps)} className="sortable">
                      <div {...column.getSortByToggleProps({ title: '' })}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" transform='rotate(180)'>
                                <path d="M0 6.5L6.5 6.5L13 6.5L6.5 0L0 6.5Z" fill="#777777" />
                              </svg>
                              : <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 6.5L6.5 6.5L13 6.5L6.5 0L0 6.5Z" fill="#777777" />
                              </svg>
                            : ''}
                        </span>
                      </div>
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps({ style: { backgroundColor: !row.canExpand ? '#f5f5f5' : 'inherit' } })}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps(cell.column.customCellProps)}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <FlexView flexDirection="row" justifyContent="flex-start" alignItems="center" flex="1">
            <Input
              inline
              type="number"
              label={t('Go to page')}
              fontSize="13px"
              margin="0px"
              padding="8px 4px"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              width="180px"
            />
          </FlexView>
          <FlexView flexDirection="row" alignItems="center" justifyContent="center" fontSize="13px">
            <Link noDecoration color="gray" fontSize="big" fontWeight="bold" padding="4px" margin="0px 4px" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'«'}
            </Link>{' '}
            <Link noDecoration color="gray" fontSize="big" fontWeight="bold" padding="4px" margin="0px 4px" onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'‹'}
            </Link>{' '}
            <FlexView flexDirection="row" margin="0px 8px" fontWeight="bold">
              {pageIndex + 1} {t('of')} {pageOptions.length}
            </FlexView>
            <Link noDecoration color="gray" fontSize="big" fontWeight="bold" padding="4px" margin="0px 4px" onClick={() => nextPage()} disabled={!canNextPage}>
              {'›'}
            </Link>{' '}
            <Link noDecoration color="gray" fontSize="big" fontWeight="bold" padding="4px" margin="0px 4px" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'»'}
            </Link>{' '}
          </FlexView>
          <FlexView flex="1" flexDirection="row" justifyContent="flex-end" alignItems="center">
            <FlexView flexDirection="row" alignItems="center" fontSize="small" borderRadius="component" backgroundColor="whitesmoke" padding="8px" margin="0px 16px">
              <FlexView margin="0px 4px 0px 0px" color="secondary">{_.sumBy(data, 'qtdPC')}</FlexView>
              <span style={{ marginRight: '4px' }}>{t('Pieces')}</span>
              <span>|</span>
              <FlexView margin="0px 4px 0px" color="secondary">{data.length}</FlexView>
              <span>{t('Materials')}</span>
            </FlexView>
            <Select
              margin="0px"
              value={pageSize}
              options={
                [5, 10, 20, 30, 40, 50].map(pageSize => ({
                  value: pageSize,
                  label: `${t('Show')} ${pageSize}`
                }))
              }
              onChange={value => {
                setPageSize(Number(value))
              }}
            />
          </FlexView>
        </div>
      </Table>
    </Card>
  )
}

export default AdnocStockTable