import axios from 'axios'
import { addLogger } from 'utils/logger'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_DATAPORT_API}`,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_DATAPORT_API_KEY
  }
})

addLogger(instance, false)

const sleep = time => new Promise(resolve => setTimeout(() => resolve(), time))

const formatResponseResults = response => {
  const data = []
  const props = Object.values(response.columns)
  for (const obj of response.data) {
    const value = {}
    obj.forEach((item, index) => {
      value[props[index]] = item
    })
    data.push(value)
  }
  return data
}

const getJobStatus = jobId => new Promise((resolve, reject) => {
  instance.get(`/jobs/${jobId}/`).then(response => {
    if (response.status === 200) {
      const { state } = response.data
      resolve(state.toUpperCase())
    }
    else {
      reject(new Error('Couldn\'t retrieve data'))
    }
  })
})

const waitForJobCompletion = async jobId => {
  try {
    let timeout = 60
    let status = ''
    while (status !== 'COMPLETED' && status !== 'FAILED' && timeout > 0) {
      status !== '' && await sleep(1000)
      status = await getJobStatus(jobId)
      timeout -= 1
    }
    if (timeout === 0) {
      throw new Error('Request timed out')
    }
    else {
      return status
    }
  }
  catch (e) {
    throw e
  }
}

const getJobData = jobId => new Promise((resolve, reject) => {
  instance.get(`/jobs/${jobId}/results/`).then(response => {
    if (response.status === 200) {
      resolve(formatResponseResults(response.data))
    }
    else {
      reject(new Error('Couldn\'t retrieve data'))
    }
  }).catch(error => {
    reject(error)
  })
})


const dataport = {
  getJobId: query => new Promise((resolve, reject) => {
    instance.post('/query_async/', query).then(response => {
      if (response.status === 200 && response.data && response.data.id) {
        const { id: jobId } = response.data
        resolve(jobId)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  asyncQuery: query => new Promise((resolve, reject) => {
    dataport.getJobId(query).then(jobId => {
      waitForJobCompletion(jobId).then(status => {
        if (status === 'COMPLETED') {
          getJobData(jobId).then(data => {
            resolve(data)
          }).catch(error => {
            reject(error)
          })
        }
        else {
          throw new Error('Job failed')
        }
      }).catch(error => {
        reject(error)
      })
    }).catch(error => {
      reject(error)
    })
  }),
}

export default dataport
