import React, { useCallback, useMemo, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Card } from 'components/common'
import _ from 'lodash'
import { UserContext } from 'stores/UserStore'
import TabButtons from 'containers/layout/TabButtons'

const Wrapper = styled(Card)`
  flex-direction: row;  
  display: flex;
  align-self: stretch;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.component};
  flex-wrap: wrap;
  box-shadow: ${({ theme }) => theme.boxShadows.medium};
  padding: 0px;
  margin: 16px 16px 0px;
  overflow: hidden;
`

const StockTabs = ({ stock }) => {

  const { t } = useTranslation()
  const { currentUser } = useContext(UserContext)
  const history = useHistory()

  const redirect = useCallback(path => () => {
    history.push(path)
  }, [history])

  useEffect(() => {
    stock.endpoints = [...stock.endpoints, 'blocked-stock']
  }, [stock])

  const stockOptions = useMemo(() => [
    {
      value: 'service-stock',
      index: 0,
      label: t('Service Stock'),
      role: 'id',
      uri: `/${stock.baseUrl}/service-stock`,
      onClick: redirect(`/${stock.baseUrl}/service-stock`), 
      subItems: []
    },
    {
      value: 'tracking-goods',
      index: 1,
      label: t('Tracking of Goods'),
      role: 'TRACKING_OF_GOODS',
      uri: `/${stock.baseUrl}/tracking-goods`,
      onClick: redirect(`/${stock.baseUrl}/tracking-goods`), 
      subItems: []
    },
    {
      value: 'inspection-reports-menu',
      index: 2,
      label: t('Inspection Reports'),
      role: 'INVENTORY_REPORT.RECEIVING_INSPECTION_REPORT',
      onClick: () => {},
      subItems: [
        {
          value: 'inspection-reports',
          label: t('Inspection Reports'),
          role: '',
          uri: `/${stock.baseUrl}/inspection-reports`,
          onClick: redirect(`/${stock.baseUrl}/inspection-reports`)
        },
        {
          value: 'receiving-inspection-reports',
          label: t('Receiving Inspection Reports'),
          role: 'INVENTORY_REPORT.RECEIVING_INSPECTION_REPORT',
          uri: `/${stock.baseUrl}/receiving-inspection-reports`,
          onClick: () => {
            redirect(`/${stock.baseUrl}/receiving-inspection-reports`)
          }
        },
        {
          value: 'rig-return-inspection-reports',
          label: t('Rig Return Inspection Reports'),
          role: 'INVENTORY_REPORT.RIG_RETURN_REPORT',
          uri: `/${stock.baseUrl}/rig-return-inspection-reports`,
          onClick: redirect(`/${stock.baseUrl}/rig-return-inspection-reports`)
        },
        {
          value: 'modified-products-inspection-reports',
          label: t('Modified Products Inspection Reports'),
          role: 'INVENTORY_REPORT.MODIFIED_PRODUCTS_INSPECTION_REPORT',  
          uri: `/${stock.baseUrl}/modified-products-inspection-reports`,
          onClick: redirect(`/${stock.baseUrl}/modified-products-inspection-reports`)
        },
        {
          value: 'material-requisition-inspection-reports',
          label: t('Material Requisition Inspection Reports'),
          role: 'INVENTORY_REPORT.RIG_PREP_REPORT',
          uri: `/${stock.baseUrl}/material-requisition-inspection-reports`,
          onClick: redirect(`/${stock.baseUrl}/material-requisition-inspection-reports`)
        },
        {
          value: 'periodical-inspection-reports',
          label: t('Periodical Inspection Reports'),
          role: 'INVENTORY_REPORT.PERIODICAL_INSPECTION_REPORT',
          uri: `/${stock.baseUrl}/periodical-inspection-reports`,
          onClick: redirect(`/${stock.baseUrl}/periodical-inspection-reports`)
        },
        {
          value: 'archived-reports',
          label: t('Archived Reports'),
          role: 'HIDDEN_REPORTS',
          uri: `/${stock.baseUrl}/archived-reports`,
          onClick: redirect(`/${stock.baseUrl}/archived-reports`)
        }
      ]
    },
    {
      value: 'mill-receipts',
      index: 3,
      label: t('Mill Receipts'),
      role: 'INVENTORY_REPORT.MILL_RECEIPTS',
      uri: `/${stock.baseUrl}/mill-receipts`,
      onClick: redirect(`/${stock.baseUrl}/mill-receipts`), 
      subItems: []
    },
    {
      value: 'delivery-notes',
      index: 4,
      label: t('Delivery Notes'),
      role: 'INVENTORY_REPORT.DELIVERY_NOTES',
      uri: `/${stock.baseUrl}/delivery-notes`,
      onClick: redirect(`/${stock.baseUrl}/delivery-notes`), 
      subItems: []
    },
    {
      value: 'material-requisition-status',
      index: 5,
      label: t('Material Requisition Status'),
      role: 'INVENTORY_REPORT.MATERIAL_REQUISITION_STATUS',
      uri: `/${stock.baseUrl}/material-requisition-status`,
      onClick: redirect(`/${stock.baseUrl}/material-requisition-status`), 
      subItems: []
    },
    {
      value: 'collaborative-planning',
      index: 6,
      label: t('Collaborative Planning'),
      role: 'COLLABORATIVE_PLANING',
      uri: `/${stock.baseUrl}/collaborative-planning`,
      onClick: redirect(`/${stock.baseUrl}/collaborative-planning`), 
      subItems: []
    },
    {
      value: 'pipe-age',
      index: 7,
      label: t('Pipe Age Management'),
      role: 'PIPE_AGE_MANAGEMENT',
      uri: `/${stock.baseUrl}/pipe-age`,
      onClick: () => {},
      subItems: [
        {
          value: 'pipe-age-view',
          label: t('Pipe Age View'),
          role: 'PIPE_AGE_MANAGEMENT.PIPE_AGE_VIEW',
          uri: `/${stock.baseUrl}/pipe-age-view`,
          onClick: redirect(`/${stock.baseUrl}/pipe-age-view`)
        },
        {
          value: 'slow-moving-pipes',
          label: t('Slow Moving Pipes'),
          role: 'PIPE_AGE_MANAGEMENT.SLOW_MOVING_PIPES',
          uri: `/${stock.baseUrl}/slow-moving-pipes`,
          onClick: () => {
            redirect(`/${stock.baseUrl}/slow-moving-pipes`)
          }
        },
        {
          value: 'title-transfer',
          label: t('Title Transfer'),
          role: 'PIPE_AGE_MANAGEMENT.TITLE_TRANSFER',
          uri: `/${stock.baseUrl}/title-transfer`,
          onClick: redirect(`/${stock.baseUrl}/title-transfer`)
        },
        {
          value: 'material-physical-transfer',
          label: t('Material Physical Transfer'),
          role: 'PIPE_AGE_MANAGEMENT.MATERIAL_PHYSICAL_TRANSFER',
          uri: `/${stock.baseUrl}/material-physical-transfer`,
          onClick: redirect(`/${stock.baseUrl}/material-physical-transfer`)
        }
      ]
    },
    {
      value: 'kpis',
      index: 8,
      label: t('KPI Global View'),
      role: 'KPIS',
      uri: `/${stock.baseUrl}/kpis`,
      onClick: redirect(`/${stock.baseUrl}/kpis`),
      subItems: []
    },
    {
      value: 'blocked-stock',
      index: 9,
      label: t('Blocked Stock Status'),
      role: 'ORDER_FORECASTING',
      uri: `/${stock.baseUrl}/blocked-stock`,
      onClick: redirect(`/${stock.baseUrl}/blocked-stock`), 
      subItems: []
    }
  ], [t, redirect, stock])

  const menuOptions = _.filter(stockOptions, ({ value, role }) => stock.endpoints.includes(value) && _.get(currentUser, `roles.${stock.key}.${role}`))

  const content = menuOptions.length > 0 ?
    <Wrapper>
      {
        menuOptions.map((option, i) =>
          <TabButtons key={i} stock={stock} option={option} index={i}></TabButtons>
        )
      }
    </Wrapper>
    : null

  return content
}

export default StockTabs