export default {
    development: {
        rig_return_report_data: 'RigReturnInspectionReport-V8',
        modified_products_report_data: 'ModifiedProductInspectionReport-V4',
        receive_inspection_data: 'ReceivingInspectionReport-V12',
        periodical_inspection_data: 'PeriodicInspectionReport-V6',
        spot_inspection_report: 'inspection_report_spot-V2',
        mill_receipt: 'millReceipt_template_v11',
        material_requisition_report: 'material_request_rig_prep_inspection_report_V6',
        material_requisition_delivery_third_party_report: 'material_request_delivery_third_party_inspection_report_V6',
        material_requisition_mat_modification_report: 'material_request_material_modification_inspection_report_V6',
        material_requisition_mat_physical_transfer_report: 'material_requisition_material_physical_transfer_report-V6',
        delivery_notes: 'delivery_notes_template_4',
        dispatch_notes_material_modification_report: 'delivery_notes_material_modification_report-V3', 
        dispatch_notes_delivery_to_third_party_report: 'delivery_notes_delivery_to_third_party_report-V3', 
        dispatch_notes_rig_preparation_report: 'delivery_notes_rig_preparation_report-V3',
        delivery_notes_mat_physical_transfer_report: 'delivery_notes_material_physical_transfer_report-V3',
        slow_moving_report_data: 'SlowMoving-V3', 
        title_transfer_report_data: 'TitleTransfer-V3', 
        material_physical_transfer_report_data: 'PhysicalTransfer-V3'
    },
    qas: {
        rig_return_report_data: 'RigReturnInspectionReport-V8',
        modified_products_report_data: 'ModifiedProductInspectionReport-V4',
        receive_inspection_data: 'ReceivingInspectionReport-V12',
        periodical_inspection_data: 'PeriodicInspectionReport-V6',
        spot_inspection_report: 'inspection_report_spot-V2',
        mill_receipt: 'millReceipt_template_v11',
        material_requisition_report: 'material_request_rig_prep_inspection_report_V6',
        material_requisition_delivery_third_party_report: 'material_request_delivery_third_party_inspection_report_V6',
        material_requisition_mat_modification_report: 'material_request_material_modification_inspection_report_V6',
        material_requisition_mat_physical_transfer_report: 'material_requisition_material_physical_transfer_report-V6',
        delivery_notes: 'delivery_notes_template_4',
        dispatch_notes_material_modification_report: 'delivery_notes_material_modification_report-V3', 
        dispatch_notes_delivery_to_third_party_report: 'delivery_notes_delivery_to_third_party_report-V3', 
        dispatch_notes_rig_preparation_report: 'delivery_notes_rig_preparation_report-V3',
        delivery_notes_mat_physical_transfer_report: 'delivery_notes_material_physical_transfer_report-V3',
        slow_moving_report_data: 'SlowMoving-V3', 
        title_transfer_report_data: 'TitleTransfer-V3', 
        material_physical_transfer_report_data: 'PhysicalTransfer-V3'
    },
    staging: {
        rig_return_report_data: 'RigReturnInspectionReport-V8',
        modified_products_report_data: 'ModifiedProductInspectionReport-V4',
        receive_inspection_data: 'ReceivingInspectionReport-V12',
        periodical_inspection_data: 'PeriodicInspectionReport-V6',
        spot_inspection_report: 'inspection_report_spot-V2',
        mill_receipt: 'millReceipt_template_v11',
        material_requisition_report: 'material_request_rig_prep_inspection_report_V6',
        material_requisition_delivery_third_party_report: 'material_request_delivery_third_party_inspection_report_V6',
        material_requisition_mat_modification_report: 'material_request_material_modification_inspection_report_V6',
        material_requisition_mat_physical_transfer_report: 'material_requisition_material_physical_transfer_report-V6',
        delivery_notes: 'delivery_notes_template_4',
        dispatch_notes_material_modification_report: 'delivery_notes_material_modification_report-V3', 
        dispatch_notes_delivery_to_third_party_report: 'delivery_notes_delivery_to_third_party_report-V3', 
        dispatch_notes_rig_preparation_report: 'delivery_notes_rig_preparation_report-V3',
        delivery_notes_mat_physical_transfer_report: 'delivery_notes_material_physical_transfer_report-V3',
        slow_moving_report_data: 'SlowMoving-V3', 
        title_transfer_report_data: 'TitleTransfer-V3', 
        material_physical_transfer_report_data: 'PhysicalTransfer-V3'
    },
    production: {
        rig_return_report_data: 'RigReturnInspectionReport-V8',
        modified_products_report_data: 'ModifiedProductInspectionReport-V5',
        receive_inspection_data: 'ReceivingInspectionReport-V12',
        periodical_inspection_data: 'PeriodicInspectionReport-V6',
        spot_inspection_report: 'inspection_report_spot-V2',
        mill_receipt: 'millReceipt_template_v11',
        material_requisition_report: 'material_request_rig_prep_inspection_report_V6',
        material_requisition_delivery_third_party_report: 'material_request_delivery_third_party_inspection_report_V6',
        material_requisition_mat_modification_report: 'material_request_material_modification_inspection_report_V6',
        material_requisition_mat_physical_transfer_report: 'material_requisition_material_physical_transfer_report-V6',
        delivery_notes: 'delivery_notes_template_4',
        dispatch_notes_material_modification_report: 'delivery_notes_material_modification_report-V3', 
        dispatch_notes_delivery_to_third_party_report: 'delivery_notes_delivery_to_third_party_report-V3', 
        dispatch_notes_rig_preparation_report: 'delivery_notes_rig_preparation_report-V3',
        delivery_notes_mat_physical_transfer_report: 'delivery_notes_material_physical_transfer_report-V3',
        slow_moving_report_data: 'SlowMoving-V3', 
        title_transfer_report_data: 'TitleTransfer-V3', 
        material_physical_transfer_report_data: 'PhysicalTransfer-V3'
    }
}