import React, { useMemo, useContext, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { UserContext } from 'stores/UserStore'
import { FlexView } from 'components/common'
import styled from 'styled-components'
import { Manager, Reference, Popper } from 'react-popper'
import StockLinkSelect from 'components/home/StockLinkSelect'
import { useHistory } from 'react-router-dom'

const Wrapper = styled(FlexView)`
  cursor: pointer;
  position: relative;
  width: 260px;
  min-height: 130px;
  background: transparent;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  margin: 8px 8px 0px;
  box-shadow: none;
  padding: 16px;
  border: 3px solid ${({ theme }) => theme.colors.lightGray};
  transition: all 0.2s;

  & > ${FlexView}:first-child {
    margin-top: 32px;
    font-family: Arvo;
    font-size: 32px;
  }

  & > ${FlexView} {
    transition: all 0.2s;
    filter: grayscale(100%);
    cursor: pointer;
    user-select: none;
  }

  &:hover {
    & > ${FlexView} {
      filter: grayscale(0%);
    }

    box-shadow: ${({ theme }) => theme.boxShadows.high};
  }

  @media screen and (min-width: 1400px) {
    width: 280px;
  }

  @media screen and (min-width: 1800px) {
    width: 400px;

    & > ${FlexView} {
      font-size: ${({ theme }) => theme.fontSizes.subtitle};
    }
  }
`

const redirectOptions = [
  {
    value: 'service-stock',
    label: 'Service Stock',
    role: 'id'
  },
  {
    value: 'tracking-goods',
    label: 'Tracking of Goods',
    role: 'TRACKING_OF_GOODS'
  },
  {
    value: 'inspection-reports',
    label: 'Inspection Reports',
    role: ''
  },
  {
    value: 'mill-receipts',
    label: 'Mill Receipts',
    role: 'INVENTORY_REPORT.MILL_RECEIPTS'
  },
  {
    value: 'receiving-inspection-reports',
    label: 'Receiving Inspection Reports',
    role: 'INVENTORY_REPORT.RECEIVING_INSPECTION_REPORT'
  },
  {
    value: 'periodical-inspection-reports',
    label: 'Periodical Inspection Reports',
    role: 'INVENTORY_REPORT.PERIODICAL_INSPECTION_REPORT'
  },
  {
    value: 'delivery-notes',
    label: 'Delivery Notes',
    role: 'INVENTORY_REPORT.DELIVERY_NOTES'
  },
  {
    value: 'rig-return-inspection-reports',
    label: 'Rig Return Inspection Reports',
    role: 'INVENTORY_REPORT.RIG_RETURN_REPORT'
  },
  {
    value: 'modified-products-inspection-reports',
    label: 'Modified Products Inspection Reports',
    role: 'INVENTORY_REPORT.MODIFIED_PRODUCTS_INSPECTION_REPORT'
  },
  {
    value: 'material-requisition-inspection-reports',
    label: 'Material Requisition Inspection Reports',
    role: 'INVENTORY_REPORT.RIG_PREP_REPORT'
  },
  {
    value: 'archived-reports',
    label: 'Archived Reports',
    role: 'HIDDEN_REPORTS'
  },
  {
    value: 'material-requisition-status',
    label: 'Material Requisition Status',
    role: 'INVENTORY_REPORT.MATERIAL_REQUISITION_STATUS'
  },
  {
    value: 'collaborative-planning',
    label: 'Collaborative Planning',
    role: 'COLLABORATIVE_PLANING'
  },
  {
    value: 'pipe-age-view',
    label: 'Pipe Age View',
    role: 'PIPE_AGE_MANAGEMENT.PIPE_AGE_VIEW'
  },
  {
    value: 'slow-moving-pipes',
    label: 'Slow Moving Pipes',
    role: 'PIPE_AGE_MANAGEMENT.SLOW_MOVING_PIPES'
  },
  {
    value: 'title-transfer',
    label: 'Title Transfer',
    role: 'PIPE_AGE_MANAGEMENT.TITLE_TRANSFER'
  },
  {
    value: 'material-physical-transfer',
    label: 'Material Physical Transfer',
    role: 'PIPE_AGE_MANAGEMENT.MATERIAL_PHYSICAL_TRANSFER'
  },
  {
    value: 'kpis',
    label: 'KPI Global View',
    role: 'KPIS'
  },
  
]

const StockCard = ({ stock, onCardInfoClick }) => {
  const node = useRef(null)
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { currentUser } = useContext(UserContext)
  const history = useHistory()
  const { key: stockKey, title, baseUrl, endpoints } = stock
  const options = useMemo(() => _.chain(redirectOptions)
    .filter(({ value, role }) => endpoints.includes(value) && _.get(currentUser.roles, `${stockKey}.${role}`))
    .map(({ value, label }) => ({
      value,
      label: t(label)
    }))
    .value()
  , [endpoints, currentUser, stockKey, t])

  const handleOutsideClick = e => {
    node && node.current && !node.current.contains(e.target) && setIsOpen(false)
  }

  const onSelect = value => {
    history.push(`${baseUrl}/${value}`)
  }

  const onCardClick = () => {
    setIsOpen(true)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [])

  return <Manager>
    <div ref={node}>
      <Reference>
        {({ ref }) => (
          <Wrapper ref={ref} onClick={onCardClick}>
            <FlexView fontWeight="bold" fontSize="big" color="darkGray">{title}</FlexView>
            <FlexView fontWeight="bold" fontSize="big" color="primary" margin="8px 0px 0px" flex="1">{t(`stock.names.${stockKey}`)}</FlexView>
          </Wrapper>
        )}
      </Reference>
      <Popper placement="right">
        {({ ref, style, placement, scheduleUpdate }) => (
          <StockLinkSelect {...{ ref, style, scheduleUpdate, placement, isOpen, options, onSelect }} />
        )}
      </Popper>
    </div>
  </Manager>
}

export default StockCard