import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import stockApi from 'api/stock'

import { LoadingOverlay, FlexView, Card, Icon } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'
import { DialogModal, Select } from 'components/form'

import StockTabs from 'containers/layout/StockTabs'
import UploadBox from 'containers/tracking-goods/UploadBox'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'

import { getPagePermissions } from 'utils/permissions-helper'

const loadFile = url => {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.click()
}

const options = [
  {
    value: 'ADNOC ONSHORE',
    label: 'ADNOC ONSHORE',
  },

  {
    value: 'ADNOC OFFSHORE',
    label: 'ADNOC OFFSHORE'
  }, 
  // {
  //   value: 'VALLOUREC',
  //   label: 'VALLOUREC'
  // },
]

const getValidOptions = (options, endUsers) => options.filter(option => endUsers.includes(option.value))

const TrackingOfGoods = ({ stockKey }) => {
  const { t } = useTranslation()
  const { stocks, setPageSubtitle } = useContext(AppContext)
  const { endUsers, idToken, currentUser } = useContext(UserContext)
  const stock = useMemo(() => _.find(stocks, { key: stockKey }), [stocks, stockKey])
  const [loading, setLoading] = useState(true)
  const [endUser, setEndUser] = useState(getValidOptions(options, endUsers)[0].value ? getValidOptions(options, endUsers)[0].value : null)
  const [data, setData] = useState({
    list: [],
    roles: {}
  })
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [showDeleteDialog, toggleDeleteDialog] = useState(false)

  const pageKey = 'TRACKING_OF_GOODS'
  const pageRole = getPagePermissions(currentUser, stockKey, pageKey)
  const canDelete = (role) => role['DELETE'] || false
  const canUpload = (role) => role['UPLOAD'] || false

  useEffect(() => {
    if (stock) {
      setPageSubtitle(t(`stock.names.${stock.key}`) + ' - ' + t('Tracking of Goods'))
    }
  }, [stock, setPageSubtitle, t])

  const getData = useCallback(() => {
    stockApi.listReports(endUsers, idToken).then(data => {
      setData({
        ...data,
        list: _.orderBy(data.list.filter(listItem => listItem.endUser === endUser), ({ uploadDate }) => moment(uploadDate).unix(), 'desc')
      })
    }).catch(e => {
      console.log(e)
      toast.error(e.message)
    }).finally(() => setLoading(false))
  }, [endUsers, endUser, idToken])


  useEffect(() => {
    getData()
  }, [getData])

  const downloadDocument = useCallback(docId => async () => {
    try {
      setLoading(true)
      const url = await stockApi.downloadReport(docId, idToken)
      loadFile(url)
      toast.info(t('documents.popupInfo'))
    }
    catch (error) {
      console.log(error)
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [idToken, t])

  const onDeleteClick = useCallback(document => () => {
    setSelectedDocument(document)
    toggleDeleteDialog(true)
  }, [])
  const clearDelete = () => toggleDeleteDialog(false)

  const deleteDocument = useCallback(async () => {
    try {
      toggleDeleteDialog(false)
      setLoading(true)
      await stockApi.deleteReport(selectedDocument.id, idToken)
      getData()
      toast.success(t('documents.documentDeleted', { name: selectedDocument.name }))
      setSelectedDocument(null)
    }
    catch (error) {
      console.log(error)
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [idToken, t, selectedDocument, getData])

  const columns = useMemo(() => [
    {
      accessor: 'name',
      Header: t('Name'),
      customHeaderProps: {
        style: {
          minWidth: '400px'
        }
      }
    },
    {
      // accessor: 'uploadDate',
      Header: t('Upload Date'),
      accessor: ({ uploadDate }) => uploadDate ? moment(uploadDate).format('MMM D, YYYY, h:mm:ss A') : '',
      // Cell: ({ cell: { value } }) => value ? moment(value).format('MMM D, YYYY, h:mm:ss A') : '',
      sortType: 'date',
      customHeaderProps: {
        style: {
          minWidth: '160px'
        }
      }
    },
    {
      accessor: 'description',
      Header: t('Description'),
      customHeaderProps: {
        style: {
          minWidth: '200px'
        }
      }
    },
    {
      accessor: 'uploadedBy',
      Header: t('Uploaded By'),
      customHeaderProps: {
        style: {
          minWidth: '100px'
        }
      }
    },
    {
      accessor: 'id',
      Header: t('Options'),
      Cell: ({ cell: { value, row } }) => <FlexView flexDirection="row" alignItems="center" justifyContent="center">
        <Icon name="download" width="20px" height="20px" tooltip={t('Download')} margin="0px 8px" onClick={downloadDocument(value)} />
        {canDelete(pageRole)
          ? <Icon name="trash" color="error" width="20px" height="20px" tooltip={t('Delete')} margin="0px 8px" onClick={onDeleteClick(row.original)} />
          : null}
      </FlexView>,
      disableFilters: true
    }
  ], [t, downloadDocument, onDeleteClick, pageRole])

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    {getValidOptions(options, endUsers).length > 1 ?
      <Select margin="10px 15px 0px 15px" value={endUser} options={options} onChange={setEndUser} placeHolder={endUser ? endUser : t('Select an option')} /> : null}
    {canUpload(pageRole) ? <UploadBox onFileUpload={getData} /> : null}
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <FilterableTable data={data.list} columns={columns} />
    </Card>
    <DialogModal
      isOpen={showDeleteDialog}
      title={t('Confirm')}
      text={t('documents.confirmDeletion', { name: _.get(selectedDocument, 'name', '') })}
      onConfirm={deleteDocument}
      onCancel={clearDelete}
      danger
    />
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default TrackingOfGoods