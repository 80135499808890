import React, { useContext } from 'react'
import { FlexView, Button, Modal, Card, LoadingOverlay, Icon } from 'components/common'
import styled from 'styled-components'
import { KpiContext } from 'stores/KpiStore'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Cell} from 'recharts'
import { useTranslation } from 'react-i18next'
import { colorStatusMap } from 'utils/constants'

const Title = styled.div`
    font-family: Roboto;
    font-size: 20px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`
const Subtitle = styled.div`
    font-family: Roboto;
    font-size: 15px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`

const CustomButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding:10px 0px;
    width: 200px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    &:active {
        position:relative;
        top:1px;
    }
`
const CloseButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding: 0px 0px;
    margin: 0px 0px;
    background-color: transparent;
    &:active {
        position:relative;
        top:1px;
    }
`

const HistoryModal = ({ isOpen, kpi, onOutsideClick, onCancel }) => {

  const { t } = useTranslation()

  const { historyData, modalLoading, setHistoryKpi } = useContext(KpiContext)

  const historyOutSideClick = () => {
    setHistoryKpi(undefined)
    onOutsideClick()
  }

  return (
    <Modal kpi={kpi} isOpen={isOpen} onOutsideClick={historyOutSideClick} onModalExit={onCancel}>
      {kpi ?
        <Card width="900px" flexDirection="column" alignItems="center" justifyContent="space-between">
          <LoadingOverlay visible={modalLoading} />
          <FlexView flexDirection='column' alignItems="flex-start" justifyContent="center" width="100%">
            <FlexView flexDirection='row' width="100%" justifyContent="space-between" alignItems="flex-start" margin="0px 0px 30px 0px ">
              <FlexView flexDirection='row' justifyContent="flex-start" width="90%">
                <FlexView flexDirection='column' alignItems="flex-start" justifyContent="center" >
                  <Title> <i> {t('kpis.history')} </i>   </Title>
                  <Subtitle>{kpi ? t(kpi.translateNameKey) : null} </Subtitle>
                </FlexView>
              </FlexView>
              <FlexView flexDirection='row' justifyContent="flex-end">
                <CloseButton onClick={historyOutSideClick}>
                  <Icon name="cross" width="20px" height="20px" color="error"/>
                </CloseButton>
              </FlexView>
            </FlexView>
            <FlexView flexDirection='column' alignItems="center" justifyContent="center" width="100%">
              <ResponsiveContainer width="99%" height={400}>
                <BarChart width={900} height={500} data={historyData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <XAxis dataKey="date" tick={{ fontSize: 12 }} />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value">
                    {
                      historyData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colorStatusMap[entry.colorStatus]} />
                      ))
                    }
                  </Bar>
                 
                  {/* <Bar dataKey="value" name=" " fill={theme.colors.primary} /> */}
                </BarChart>
              </ResponsiveContainer>
              <CustomButton onClick={historyOutSideClick} > {t('kpis.ok_uc')} </CustomButton>
            </FlexView>
          </FlexView>
        </Card>
        : ''
      }
    </Modal>
  )
}

export default HistoryModal