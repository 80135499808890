import _ from 'lodash'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from 'stores/AppStore'
import { useMemo } from 'react'
import stockFilters from 'utils/stock-filter-config'

const useStockFilters = (stockKey) => {
  const { t } = useTranslation()
  const { lengthUnit, weighUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit } = useContext(AppContext)
  const params = useMemo(() => ({
    t,
    lengthUnit,
    weighUnit,
    convertToCurrentLengthUnit,
    convertToCurrentWeightUnit
  }), [t, lengthUnit, weighUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit])

  const filters = useMemo(() => {
    const getFilters = _.get(stockFilters, stockKey, () => null)

    return getFilters(params)
  }, [stockKey, params])

  return filters
}

export default useStockFilters