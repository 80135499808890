import React, { useMemo } from 'react'
import AdnocStockTable from 'containers/stock/AdnocStockTable'
import AdipecStockTable from 'containers/stock/AdipecStockTable'
import ChevronStockTable from 'containers/stock/ChevronStockTable'
import TotalStockTable from 'containers/stock/TotalStockTable'
import TotalAngolaStockTable from 'containers/stock/TotalAngolaStockTable'
import BhpStockTable from 'containers/stock/BhpStockTable'

const StockTable = ({ stockKey }) => {
  const table = useMemo(() => {
    let render
    switch (stockKey){
      case ('SERVICE_STOCK_ADNOC'):
        render = <AdnocStockTable />
        break
      case ('SERVICE_STOCK_ADIPEC'):
        render = <AdipecStockTable />
        break
      case ('SERVICE_STOCK_CHEVRON'):
        render = <ChevronStockTable />
        break
        case ('SERVICE_STOCK_BHP'):
        render = <BhpStockTable />
        break
      case ('SERVICE_STOCK_TOTAL_MYANMAR'):
        render = <TotalStockTable />
        break
      case ('SERVICE_STOCK_TOTAL_ANGOLA'):
        render = <TotalAngolaStockTable />
        break
      case ('SERVICE_STOCK_ENI_MYANMAR'):
        render = <TotalStockTable />
        break
      default:
        render = null
        break
    }
    return render
  }, [stockKey])

  return table
}

export default StockTable