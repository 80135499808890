import axios from 'axios'
import { decompressResponse } from 'utils/decompress'
import { addLogger } from 'utils/logger'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json'
  }
})

addLogger(instance, true)

const formatAuthorization = idToken => idToken + '.' + btoa(JSON.stringify({ 'id': process.env.REACT_APP_ID }))

export default {
  getStockData: (stock, wid, idToken) => new Promise((resolve, reject) => {
    instance.post(
      `/stocks/${stock}`,
      wid ? {
        wid
      } : {},
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  getRackData: (idToken) => new Promise((resolve, reject) => {
    instance.post(
      `/stocks/rack-distribution-adnoc`,
       {},
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  getCertificateDownloadUrl: (path, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/get-certificate',
      {
        path
      },
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const { url } = response.data.body
        resolve(url)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  getUserGuideDownloadUrl: (key, version, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/get-user-guide',
      {
        key, version
      },
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const { url } = decompressResponse(response.data)
        resolve(url)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  listReports: (endUsers, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/tracking-of-goods/list',
      endUsers,
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const data = decompressResponse(response.data)
        resolve(data)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  uploadReport: (fileParams, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/tracking-of-goods/upload', 
      fileParams,
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const data = decompressResponse(response.data)
        resolve(data)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  downloadReport: (id, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/tracking-of-goods/download',
      { id },
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const url = decompressResponse(response.data)
        resolve(url)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  deleteReport: (id, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/tracking-of-goods/delete',
      { id },
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const data = decompressResponse(response.data)
        resolve(data)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  listReportsCollaborative: (endUsers, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/collaborative-planning/list',
      { endUsers },
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const data = decompressResponse(response.data)
        resolve(data)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  uploadReportCollaborative: (fileParams, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/collaborative-planning/upload',
      fileParams,
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const data = decompressResponse(response.data)
        resolve(data)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  downloadReportCollaborative: (id, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/collaborative-planning/download',
      { id },
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const url = decompressResponse(response.data)
        resolve(url)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  deleteReportCollaborative: (id, idToken) => new Promise((resolve, reject) => {
    instance.post(
      '/document-management/collaborative-planning/delete',
      { id },
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        const data = decompressResponse(response.data)
        resolve(data)
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    }).catch(error => {
      reject(error)
    })
  }),
  getPipeAgeList: (uri, params = {}, idToken = '') => new Promise((resolve, reject) => {
    instance.post(
      `/${uri}`,
      params,
      {
        headers: {
          Authorization: formatAuthorization(idToken)
        }
      }
    ).then(response => {
      if (response.status === 200) {
        resolve(decompressResponse(response.data))
      }
      else {
        reject(new Error('Couldn\'t retrieve data'))
      }
    })
      .catch(error => {
        reject(error)
      })
    }), 
    getDailyServiceStock: (params = {}, idToken = '') => new Promise((resolve, reject) => {
      instance.post(
        `/service/get-daily-service-stock`,
        params,
        {
          headers: {
            Authorization: formatAuthorization(idToken)
          }
        }
      ).then(response => {
        if (response.status === 200) {
          resolve(decompressResponse(response.data))
        }
        else {
          reject(new Error('Couldn\'t retrieve data'))
        }
      })
        .catch(error => {
          reject(error)
        })
      }), 
}