import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import apiReports from 'api/reports'

import { LoadingOverlay, FlexView, Icon, Card, Button } from 'components/common'
import FilterableTable from 'components/common/FilterableTable'

import ReportVisibilityModal from 'containers/common/ReportVisibilityModal'
import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'

import { reports_uri, hiddenReportsStatus } from 'utils/constants'
import { downloadReports } from 'utils/download-reports'

// import { generateSpreadsheet } from 'provider/excelGeneration'

const ArchiveButton = styled(Button)`
font-family: 'Roboto';
background: none;
border-radius: 0px;
border: none;
box-shadow: none;
margin-top: 20px;
color: gray; 
fontWeight: bold; 
margin: 0px;  

&:active {
  opacity: 0.4;
}
`

const FlexStatus = styled(FlexView)`
    font-family: calibri;
`

const formatReports = reports => _.orderBy(reports, 'date', 'desc')

const getBackgroundColor = value => value === hiddenReportsStatus.VISIBLE ? 'success' : 'warning'
const getActionIcon = value => value === hiddenReportsStatus.VISIBLE ? 'hide' : 'view'

const ArchivedReports = ({ stockKey }) => {
  const { t } = useTranslation()
  const { stocks, setPageSubtitle, datasets, pageLoading, setPageLoading, dataportTemplates } = useContext(AppContext)
  const { endUsers, idToken } = useContext(UserContext)
  const stock = useMemo(() => _.find(stocks, { key: stockKey }), [stocks, stockKey])
  const [reports, setReports] = useState([])
  const [ reportId, setReportId] = useState(null)
  const [ reportType, setReportType] = useState(null)
  const [ isOpen, setIsOpen ] = useState(null)
  const [ status, setStatus ] = useState(null)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(t(`stock.names.${stock.key}`) + ' - ' + t('Archive Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const onDownloadClick = useCallback( async (report) => {
    try {
      setPageLoading(true)
      const params = { report_type: report.report_type, reportId: report.id_report }
      const result = await apiReports.getReportInfo( reports_uri.GET_REPORT_INFO, params, idToken) 
      if(result){
        await downloadReports(report, datasets, dataportTemplates, idToken, setPageLoading, t, result.reportInfo)
      }
    } 
    catch (error) {
        setPageLoading(false)
        toast.error(error.message)
        throw new Error(error)
    }
  }, [ dataportTemplates, datasets, idToken, setPageLoading, t ])

  const onVisibilityClick = useCallback(async(id, type, status) => {
    setReportId(id)
    setReportType(type)
    setStatus(status)
    setIsOpen(true)
  }, [])

  const loadReports = useCallback(async() => {
    try {
      setPageLoading(true)
      const result = await apiReports.getHiddenReportsHist(reports_uri.GET_HIDDEN_REPORTS_HIST, {}, idToken)
      if(result){
        setReports(formatReports(result))
        setPageLoading(false)
      }
    }
    catch(error){
      console.log(error.message)
      toast.error(error.message)
      setPageLoading(false)
    }    
  }, [ idToken, setPageLoading])

  const toggleModal = useCallback(async(isSaving) => {
    setIsOpen(false)
    console.log(isSaving)
    if(isSaving)
      loadReports()
    setTimeout(() => {
      setReportType(null)
      setReportId(null)
      setStatus(null)
    }, 500)
  }, [ loadReports ])

  useEffect(() => {
    try {
      loadReports()
    }
    catch(error){
      console.log(error.message)
      toast.error(error.message)
      setPageLoading(false)
    }
  }, [ endUsers, datasets, idToken, setPageLoading, loadReports ])

  const columns = useMemo(() => [
    // {
    //   accessor: 'id',
    //   Header: t('ID')
    // },
    {
      accessor: 'id_report',
      Header: t('Report ID')
    },
    {
      id: 'date',
      Header: t('Date'),
      accessor: ({ date }) => date ? moment(date).format('MMM D, YYYY, h:mm:ss A') : ''
    },
    {
      accessor: ({ report_type }) => report_type ? t(report_type) : '',
      Header: t('Report Type')
    },
    {
      accessor: 'responsible',
      Header: t('Changed By')
    },
    {
      accessor: 'justification',
      Header: t('Justification')
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: ({ cell: { value, row } }) => ( 
        <FlexView alignItems="center" justifyContent="center">
          <FlexStatus  alignItems="center" justifyContent="center" padding="4px 8px" margin="0px 0px 0px 8px" color="white" 
                     backgroundColor={getBackgroundColor(value)} width="10rem" borderRadius="10px" text-center>
               <b> {t(value.toLowerCase())} </b> 
          </FlexStatus>         
        </FlexView>
      )
    },
    
    {
      id: 'options',
      Header: t('Options'),
      disableFilters: true,
      Cell: ({ cell: { row } }) => (
          <FlexView flexDirection="row" alignItems="flex-start" justifyContent="center" width="100%" >
            <Icon name="download" width="24px" height="24px" onClick={()=> onDownloadClick(row.original)} 
                  margin="0px 10px 0px 0px" tooltip={t('Export to Excel')}/>
            <Icon name={getActionIcon(row.original.status)} width="24px" height="24px" 
            tooltip={ row.original.status === hiddenReportsStatus.VISIBLE ? t('Archive Tooltip') :  t('Reveal Tooltip') }
                  onClick={()=> onVisibilityClick(row.original.id_report, row.original.report_type, 
                                                  row.original.status )} />
          </FlexView> )
    }
  ], [ t, onDownloadClick, onVisibilityClick ])

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <ArchiveButton flexDirection="row" onClick={() => setIsOpen(true)} fontSize="subtitle" justifyContent="flex-end" width="99%">
              <Icon name="plus" width="16px" height="16px"  margin="0px 5px 0px 0px"/>
              <div> <font size="4"> { t('Archive New Report') } </font> </div>
      </ArchiveButton>
      <FilterableTable columns={ columns } data={ reports } />
    </Card>
    <ReportVisibilityModal isOpen={ isOpen } reportId={ reportId }  type={ reportType } status={ status } 
                           isRowClick={ status ? true : false } onOutsideClick={toggleModal}> </ReportVisibilityModal>
    <LoadingOverlay visible={pageLoading} />
  </FlexView>
}

export default ArchivedReports