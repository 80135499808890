import React, { useContext } from 'react'

import { Modal, FlexView, Card, Button, Icon, LoadingOverlay } from 'components/common'
import { Select, Input } from 'components/form'

import { peridiocity, years, quarters, months, callComplianceOptions, semesters, type, paymentAfterAccurateInvoiceOptions, inputUnit } from 'utils/constants'

import ReactSlider from 'react-slider'
import styled from 'styled-components'
import moment from 'moment'
import { KpiContext } from 'stores/KpiStore'
import { useTranslation } from 'react-i18next'

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 25px;
    margin: 20px 0px 10px 0px;
`

const StyledThumb = styled.div`
    height: 40px;
    line-height: 40px;
    width: 40px;
    text-align: center;
    border: 3px solid #00ccff;
    background-color: #fff;
    color: #000;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 50%;
    cursor: grab;
`

const Thumb = (props, state) => <StyledThumb {...props}> <b> {state.valueNow + '%'} </b></StyledThumb>

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    margin: 5px 0px;
    background: ${props => props.index ? '#ddd' : '#00ccff'};
    border-radius: 999px;
    margin: 20px 0px 0px 0px;
`

const CustomButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding:10px 0px;
    margin: 20px 20px;
    width: 200px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    &:active {
        position:relative;
        top:1px;
    }
`

const CloseButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding: 0px 0px;
    margin: 0px 0px;
    background-color: transparent;
    &:active {
        position:relative;
        top:1px;
    }
`

const NoButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding:10px 0px;
    margin: 20px 20px;
    width: 200px;
    &:active {
        position:relative;
        top:1px;
    }
`
const ModalCard = styled(Card)`
    width: 600px;
`

const CustomInput = styled(Input)`
    text-align: center;
    align-items: center;
`

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

const AdminModal = ({ isOpen, kpi, onOutsideClick, onCancel, isAverage}) => {

    const { t } = useTranslation()

    const { value, isAlreadyFilledAndNotOk, setIdKpi, setIsToSave, setValue, setisAlreadyFilledAndNotOk } = useContext(KpiContext)
    const { month, year, quarter, semester, setPeriod, setMonth, setYear, setSemester, setQuarter, modalLoading, setIsAverage } = useContext(KpiContext)

    const handleClick = (kpi, override) => {
        const date = formatValuesForUpInsert(kpi)
        const isFilled = checkForFilledPeriods(kpi, date)
        if (!isFilled || override) {
            isAverage ? setIsAverage(true) : setIsAverage(false)
            setPeriod(date)
            setIdKpi(kpi.id)
            setIsToSave(true)
        }
        else {
            setisAlreadyFilledAndNotOk(true)
        }
    }

    const adminKpiOutSideClick = () => {
        setValue(null)
        setPeriod('')
        setIdKpi(null)
        setYear(null)
        setMonth(null)
        setSemester(null)
        setQuarter(null)
        setisAlreadyFilledAndNotOk(false)
        setIsToSave(false)
        onOutsideClick()
    }

    const checkForFilledPeriods = (kpi, period) => {

        if(isAverage){
            if (kpi && kpi.filled_input_year_average && kpi.filled_input_year_average.length > 0) {
                const formatedPeriods = kpi.filled_input_year_average.map(date => moment.utc(date).format('YYYY-MM-DD'))
                const index = formatedPeriods.findIndex(date => date === period)
                if (index > -1)
                    return true
            }          
        }
        else if (kpi && kpi.filledperiods && kpi.filledperiods.length > 0) {
            const formatedPeriods = kpi.filledperiods.map(date => moment.utc(date).format('YYYY-MM-DD'))
            const index = formatedPeriods.findIndex(date => date === period)
            if (index > -1)
                return true
        }
        return false
    }

    const formatValuesForUpInsert = (kpi) => {
        var date
        if (isAverage)
            date = new Date(year, 0, 1)
        else if (kpi.peridiocity === peridiocity.FIXED)
            date = moment('2020-01-01').format('YYYY-MM-DD')
        else if (kpi.peridiocity === peridiocity.MONTHLY)
            date = new Date(year, month - 1, 1)
        else if (kpi.peridiocity === peridiocity.QUARTERLY) {
            if (quarter === quarters[0].value)
                setMonth(0)
            else if (quarter === quarters[1].value)
                setMonth(3)
            else if (quarter === quarters[2].value)
                setMonth(6)
            else
                setMonth(9)
            date = new Date(year, month, 1)
        }
        else if (kpi.peridiocity === peridiocity.BIANNUAL) {
            if (semester === semesters[0].value)
                setMonth(0)
            else
                setMonth(6)
            date = new Date(year, month, 1)
        }
        else if (kpi.peridiocity === peridiocity.YEARLY)
            date = new Date(year, 0, 1)

        const formatedDate = moment(date).format('YYYY-MM-DD')
        return formatedDate
    }

    const validateApplyButton = (kpi) => {
        if (kpi) {
            if(isAverage){
                if (year && value && (value >= kpi.minValue && value <= kpi.maxValue))
                    return false
                return true
            }
            else if (kpi.peridiocity === peridiocity.FIXED) {
                if (value && (value >= kpi.minValue && value <= kpi.maxValue))
                    return false
                return true
            }
            else if (kpi.peridiocity === peridiocity.QUARTERLY) {
                if (year && quarter && value && (value >= kpi.minValue && value <= kpi.maxValue))
                    return false
                return true
            }
            else if (kpi.peridiocity === peridiocity.MONTHLY) {
                if (year && month && value && (value >= kpi.minValue && value <= kpi.maxValue))
                    return false
                return true
            }
            else if (kpi.peridiocity === peridiocity.YEARLY) {
                if ((kpi.type === type.RECIPROCAL && kpi.id === 1) || (kpi.type === type.KPI && kpi.id === 4)) {
                    if (year && (value || value === 0))
                        return false
                    return true

                }
                else if ((kpi.type === type.KPI && (kpi.id === 5))) {
                    if (year)
                        return false
                    return true
                }
                else if ((kpi.type === type.KPI && (kpi.id === 5))) {
                    if (year && ((value >= 0 && value <= 100)))
                        return false
                    return true
                }
                else if ((kpi.type === type.RECIPROCAL && (kpi.id === 7))) {
                    if (year && value && (value >= kpi.minValue && value <= kpi.maxValue) && Number.isInteger(Number(value)) )
                        return false
                    return true
                }
                else if (year && value && (value >= kpi.minValue && value <= kpi.maxValue))
                    return false
                return true
            }
            else if (kpi.peridiocity === peridiocity.BIANNUAL) {
                if (year && semester && value && (value >= kpi.minValue && value <= kpi.maxValue))
                    return false
                return true
            }
        }
    }

    const validateSuccess = (kpi, value) => {
        return value >= kpi.minValue && value <= kpi.maxValue
    }

    const validateMessage = (kpi, value) => {
        return (!value || value < kpi.minValue || value > kpi.maxValue || (kpi.type === type.RECIPROCAL && (kpi.id === 7) && !Number.isInteger(Number(value))))
    }

    const returnWarningMessage = (kpi, value = null) => {
        let message = ''
        if ((kpi.type === type.RECIPROCAL && (kpi.id === 7))) {
            if (!Number.isInteger(Number(value)) )
               message = t('Number must be integer')
            else 
               message = t('kpis.lbl_please_enter_value_between') + kpi.minValue + t('kpis.and') + kpi.maxValue
        }
        else 
            message = t('kpis.lbl_please_enter_value_between') + kpi.minValue + t('kpis.and') + kpi.maxValue
        return message
    }

    const returnInputTextByUnit = (kpi) => {
        if(isAverage){
            return kpi.inputUnit === inputUnit.PERCENT
            ? t('kpis.lbl_please_enter_average_percent') 
            : kpi.inputUnit === inputUnit.NUMBER_OF_CHANGES 
            ? t('kpis.lbl_please_enter_average_changes') 
            : kpi.inputUnit === inputUnit.NUMBER_OF_TIMES
            ? t('kpis.lbl_please_enter_average_times') 
            : kpi.inputUnit === inputUnit.DAYS 
            ? t('kpis.lbl_please_enter_average_days') 
            : kpi.inputUnit === inputUnit.WEEKS
            ? t('kpis.lbl_please_enter_average_weeks') 
            : t('kpis.lbl_please_enter_average_hours')
        }
        else if(kpi.peridiocity === peridiocity.QUARTERLY){
            return kpi.inputUnit === inputUnit.PERCENT 
                   ? t('kpis.lbl_please_enter_value_percent') 
                   : kpi.inputUnit === inputUnit.DAYS 
                   ? t('kpis.lbl_please_enter_value_days') 
                   : t('kpis.lbl_please_enter_value_times')
        }
        else {
            return kpi.inputUnit === inputUnit.PERCENT
            ? t('kpis.lbl_please_enter_value_percent') 
            : kpi.inputUnit === inputUnit.NUMBER_OF_CHANGES 
            ? t('kpis.lbl_please_enter_value_changes') 
            : kpi.inputUnit === inputUnit.NUMBER_OF_TIMES
            ? t('kpis.lbl_please_enter_value_times') 
            : kpi.inputUnit === inputUnit.DAYS  
            ? t('kpis.lbl_please_enter_value_days') 
            : kpi.inputUnit === inputUnit.WEEKS
            ? t('kpis.lbl_please_enter_value_weeks') 
            : t('kpis.lbl_please_enter_value_hours')
        }      
    }

    const validateDisplayYear = (adminKpi) => {
        if (adminKpi) {
            if (adminKpi.peridiocity !== peridiocity.FIXED || isAverage) {
                return <FlexView flexDirection="column" alignItems="center" justifyContent="center">
                    <label> {t('kpis.lbl_please_select_year')}  </label>
                    <Select width="300px" options={years} value={year} placeholder={year ? year.toString() : t('kpis.lbl_select_year')}
                        onChange={year => { setYear((year)) }}></Select>
                </FlexView>
            }
            return ''
        }
        return ''
    }

    const validateWhatDisplay = (kpi) => {
        if (kpi) {
            if(isAverage){
                return <FlexView flexDirection="column" alignItems="center" justifyContent="center">
                            <label> { returnInputTextByUnit(kpi) } </label>  
                            <Input width="300px" placeholder={ returnInputTextByUnit(kpi) } value={value} onChange={(e) => setValue(e.target.value)}
                            success={validateSuccess(kpi, value)} warning={validateMessage(kpi, value)}
                            message={validateMessage(kpi, value) ? returnWarningMessage(kpi,value) : ''} messageType="warning" />
                        </FlexView>
            }
            else if (kpi.peridiocity === peridiocity.FIXED) {
                return <FlexView flexDirection="column" alignItems="center" justifyContent="center">
                    <label> {t('kpis.lbl_please_enter_value_weeks')} </label>
                    <CustomInput width="300px" placeholder={t('kpis.lbl_please_enter_value_weeks')} value={value}
                        onChange={(e) => setValue(e.target.value)} success={validateSuccess(kpi, value)} warning={validateMessage(kpi, value)}
                        message={validateMessage(kpi, value) ? returnWarningMessage(kpi) : ''} messageType="warning" />
                </FlexView>
            }
            else if (kpi.peridiocity === peridiocity.QUARTERLY) {
                return <FlexView flexDirection="column" alignItems="center" justifyContent="center">
                    <label> {t('kpis.lbl_please_select_quarter')} </label>
                    <Select width="300px" options={quarters} value={quarter} placeholder={quarter ? quarter : t('kpis.lbl_select_quarter')}
                        onChange={quarter => { setQuarter(quarter) }}></Select>
                    <label> { returnInputTextByUnit(kpi) } </label>
                    <Input width="300px" placeholder={returnInputTextByUnit(kpi)} value={value} onChange={(e) => setValue(e.target.value)}
                        success={validateSuccess(kpi, value)} warning={validateMessage(kpi, value)} 
                        message={validateMessage(kpi, value) ? returnWarningMessage(kpi) : ''} messageType="warning" />
                </FlexView>
            }
            else if (kpi.peridiocity === peridiocity.MONTHLY) {
                return <FlexView flexDirection="column" alignItems="center" justifyContent="center">
                    <label> {t('kpis.lbl_please_select_month')} </label>
                    <Select width="300px" options={months} value={month} placeholder={month ? month.toString() : t('kpis.lbl_select_month')}
                        onChange={month => { setMonth(month) }}></Select>
                    <label> {t('kpis.lbl_please_enter_value_months')} </label>
                    <Input width="300px" placeholder={t('kpis.lbl_please_enter_value_months')} value={value} onChange={(e) => setValue(e.target.value)}
                        success={validateSuccess(kpi, value)} warning={validateMessage(kpi, value)}
                        message={validateMessage(kpi, value) ? returnWarningMessage(kpi) : ''} messageType="warning" />
                </FlexView>
            }
            else if (kpi.peridiocity === peridiocity.YEARLY) {
                if (kpi.type === type.KPI) {
                    if (kpi.id === 4) {
                        return <FlexView flexDirection="column" alignItems="center" justifyContent="center">
                            <label> {t('kpis.lbl_please_select_option')} </label>
                            <Select width="300px" options={callComplianceOptions} value={value} placeholder={value ? value.label : t('kpis.lbl_select_option')}
                                onChange={(value) => setValue(value)}></Select>
                        </FlexView>
                    }
                    else if (kpi.id === 5 && kpi.type === type.KPI) {
                        return <FlexView flexDirection="row" width="100%">
                            <label> 0% </label>
                            <StyledSlider withTracks={true} defaultValue={0} renderTrack={Track} renderThumb={Thumb} value={value} onChange={(value) => setValue(Number(value))} />
                            <label> 100% </label>
                        </FlexView>
                    }
                    else {
                        return <FlexView flexDirection="column" alignItems="center" justifyContent="center">
                            <label> {t('kpis.lbl_please_enter_value')} </label>
                            <Input width="300px" placeholder={t('kpis.lbl_please_enter_value')} value={value} onChange={(e) => setValue(e.target.value)}
                                success={validateSuccess(kpi, value)} warning={validateMessage(kpi, value)}
                                message={validateMessage(kpi, value) ? returnWarningMessage(kpi) : ''} messageType="warning" />
                        </FlexView>
                    }
                }
                else if (kpi.type === type.RECIPROCAL) {
                    if (kpi.id === 1) {
                        return <FlexView flexDirection="column" alignItems="center" justifyContent="center">
                            <label> {t('kpis.lbl_please_select_option')} </label>
                            <Select width="300px" options={paymentAfterAccurateInvoiceOptions} value={value} placeholder={value ? value.label : t('kpis.lbl_select_option')}
                                onChange={(value) => setValue(value)}></Select>
                        </FlexView>
                    }
                    else {
                        return <FlexView flexDirection="column" alignItems="center" justifyContent="center">
                            <label> { returnInputTextByUnit(kpi) } </label>
                            <Input width="300px" placeholder={ returnInputTextByUnit(kpi) } value={value} onChange={(e) => setValue(e.target.value)}
                                success={validateSuccess(kpi, value)} warning={validateMessage(kpi, value)}
                                message={validateMessage(kpi, value) ? returnWarningMessage(kpi, value) : ''} messageType="warning" />
                        </FlexView>
                    }
                }
            }
            else if (kpi.peridiocity === peridiocity.BIANNUAL) {
                return <FlexView flexDirection="column" alignItems="center" justifyContent="center">
                    <label> {t('kpis.lbl_please_select_semester')} </label>
                    <Select width="300px" options={semesters} value={semester} placeholder={semester ? semester : t('kpis.lbl_select_semester')}
                        onChange={semester => { setSemester(semester) }}></Select>
                    <label> {t('kpis.lbl_please_enter_value')} </label>
                    <Input width="300px" placeholder={t('kpis.lbl_please_enter_value')} value={value} onChange={(e) => setValue(e.target.value)}
                        success={validateSuccess(kpi, value)} warning={validateMessage(kpi, value)}
                        message={validateMessage(kpi, value) ? returnWarningMessage(kpi) : ''} messageType="warning" />
                </FlexView>
            }

            return ''
        }
        return ''
    }

    return (
        <FlexView  >
            <Modal kpi={kpi} isOpen={isOpen} onOutsideClick={adminKpiOutSideClick} onModalExit={onCancel} >
                <ModalCard flexDirection='column' justifyContent="center">
                    <LoadingOverlay visible={modalLoading} />
                    <FlexView flexDirection='row' width="100%" justifyContent="space-between" alignItems="flex-start" margin="0px 0px 30px 0px ">
                        <FlexView flexDirection='row' justifyContent="flex-start" width="90%">
                            <font size="5"> {kpi ? t(kpi.translateNameKey) : null}   </font>
                        </FlexView>
                        <FlexView flexDirection='row' justifyContent="flex-end">
                            <CloseButton onClick={adminKpiOutSideClick}>
                                <Icon name="cross" width="20px" height="20px" color="error"/>
                            </CloseButton>
                        </FlexView>

                    </FlexView>
                    <FlexView flexDirection='column' alignItems="center" justifyContent="center" width="100%">
                        {validateDisplayYear(kpi)}
                        {validateWhatDisplay(kpi)}
                        {isAlreadyFilledAndNotOk ?
                            <FlexView flexDirection='column' alignItems="center" justifyContent="space-between">
                                <h4>  {t('kpis.msg_filled_kpi')}</h4>
                                <FlexView flexDirection='row' alignItems="center" justifyContent="space-between">
                                    <NoButton onClick={() => setisAlreadyFilledAndNotOk(false)} > {t('kpis.no_uc')} </NoButton>
                                    <CustomButton onClick={() => handleClick(kpi, true)}> {t('kpis.yes_uc')} </CustomButton>
                                </FlexView>
                            </FlexView>
                            :
                            <CustomButton onClick={() => handleClick(kpi, false)} disabled={validateApplyButton(kpi)}> {t('kpis.apply_uc')} </CustomButton>
                        }
                    </FlexView>
                </ModalCard>
            </Modal>

        </FlexView>
    )
}

export default AdminModal