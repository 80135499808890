import React, { useContext } from 'react'
import { Card, FlexView, Spinner } from 'components/common'
import styled from 'styled-components'
import PipeProperty from 'components/pipe-data/PipeProperty'
import { StockContext } from 'stores/StockStore'
import { millimeterToInch } from 'utils/conversion'
import { useTranslation } from 'react-i18next'

const Grid = styled(FlexView)`
  display: grid;
  grid-template-columns: repeat(5, minMax(0, 5fr));
`

const ProductDescriptionCard = () => {
  const { t } = useTranslation()
  const { pipeNominalData, loading } = useContext(StockContext)
  const { pipeNominalData: isLoading } = loading

  return <Card margin="16px" alignItems="stretch" alignSelf="stretch" padding="16px">
    <FlexView fontSize="big" fontWeight="bold" margin="0px 0px 8px">{t('Product Description')}</FlexView>
    {isLoading ? <FlexView alignSelf="stretch" alignItems="center" justifyContent="center" padding="16px">
      <Spinner size="32px" />
    </FlexView>
    :
    <Grid width="100%">
      <PipeProperty name={t('Product Family')} value={pipeNominalData.type_material} />
      <PipeProperty name={'OD (")'} value={pipeNominalData.od_nominal ? millimeterToInch(pipeNominalData.od_nominal) : undefined} />
      <PipeProperty name={`${t('Weight')} (lb/ft)`} value={pipeNominalData.weight_nominal} />
      <PipeProperty name={t('Grade')} value={pipeNominalData.grade} />
      <PipeProperty name={t('Connection')} value={pipeNominalData.connection} />
      <PipeProperty name={t('Length')} value={pipeNominalData.length_nominal} />
      <PipeProperty name={t('Drift')} value={pipeNominalData.drift} />
      <PipeProperty name={t('Storage Type')} value={pipeNominalData.storage_type} />
      <PipeProperty name={t('MESC No/CATID No')} value={pipeNominalData.material} />
      <PipeProperty name={t('Serial Number')} value={pipeNominalData.serial_number} />
    </Grid>}
  </Card>
}

export default ProductDescriptionCard