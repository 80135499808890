import React, { useContext } from 'react'
import { FlexView, Card, Icon, Button, Spinner } from 'components/common'
import { Select, Toggle } from 'components/form'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { type } from 'utils/constants'
import { KpiContext } from 'stores/KpiStore'
import ExportModal from 'containers/kpis/ExportModal'

const TypeButton = styled(Button)`
  background-color: transparent;
  color: "#303030";
  margin-right: 10px;
  &.active {
    box-shadow: 0 0 1pt 1pt #ddd;
  }
`

const IconFlexView = styled(FlexView)`
    padding: 0px 5px 0px 5px;
    float: right;
    position: relative;
    display:flex;
    height: 40px;
    margin-top: 0px;
    border-radius: 0px 8px 8px 0px;
    line-height: 2.5;
`

const KpiHeader = ({ hasAdmin }) => {
  const { isAdmin, openExportModal, setIsAdmin, endUser, totalScore, maxScore, setEndUser, setKpiType, kpiType, pageLoading, setOpenExportModal, setInitDate, setEndDate, endUserOptions } = useContext(KpiContext)
  const { t } = useTranslation()

  const onExportClick = () => {
    setOpenExportModal(true)
  }

  const onCancel = () => {
    setOpenExportModal(false)
  }

  const onKpiTypeClick = kpiType => () => {
    setKpiType(kpiType)
  }

  const onOutsideClick = () => {
    setOpenExportModal(false)
    setInitDate(undefined)
    setEndDate(undefined)
  }

  return (
    <FlexView flexDirection="column" justifyContent="space-between" flexWrap="wrap" backgroundColor="transparent" width="100%">
      <FlexView flexDirection="row" justifyContent="space-between" alignItems="center" alignSelf="stretch">
          { endUserOptions.length > 1
            ? <Select margin="0px" value={endUser} options={endUserOptions} onChange={setEndUser} placeHolder={endUser ? endUser : t('kpis.lbl_select_option')} />
            : <div></div>
          }
          {hasAdmin 
          ? <Toggle textOff={t('kpis.customer_view_uc')} textOn={t('kpis.administrator_view_uc')} state={isAdmin} onChange={setIsAdmin} alternate />
          : null}
      </FlexView>
      <FlexView flexDirection="row" justifyContent="space-between" alignItems="center" backgroundColor="transparent" width="100%">
        <FlexView flexDirection="row" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
          <TypeButton className={kpiType === type.KPI ? 'active' : null} onClick={onKpiTypeClick(type.KPI)}> {t('kpis.name')} </TypeButton>
          <TypeButton className={kpiType === type.RECIPROCAL ? 'active' : null} onClick={onKpiTypeClick(type.RECIPROCAL)}> {t('kpis.reciprocal_kpis')} </TypeButton>
          <FlexView fontWeight="bold" flexDirection="row" padding="8px" onClick={onExportClick} style={{ cursor: 'pointer' }} alignItems="center">
            <Icon name="export" color="secondary" margin="0px 8px 0px 0px" />
            {t('kpis.export_uc')}
          </FlexView>
        </FlexView>
        <Card elevation="none" margin="0px" padding="0px 0px 0px 8px" width="240px" height="40px" borderRadius="8px" backgroundColor="#f5f5f5" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
          <FlexView flexDirection="row" justifyContent="space-between" alignItems="center" alignSelf="stretch" flex="1" padding="0px 8px">
            {t('kpis.total_score_uc')}
            {!pageLoading && <span><b>{totalScore}</b>/{maxScore}</span>}
          </FlexView>
          {pageLoading
            ? <IconFlexView alignItems="center" justifyContent="center" padding="16px" margin="0px 16px 0px 0px">
              <Spinner size="16px" />
            </IconFlexView>
            : <IconFlexView flexDirection="row" alignItems="center" backgroundColor={totalScore > 50 ? 'success' : totalScore < 50 ? 'error' : 'warning'}>
              <Icon name={totalScore > 50 ? 'smile' : totalScore < 50 ? 'smile-sad' : 'smile-neutral'} color="white"> </Icon>
            </IconFlexView>
          }
        </Card>
      </FlexView>
      <ExportModal isOpen={openExportModal} onOutsideClick={onOutsideClick} onModalExit={onCancel}></ExportModal>
    </FlexView>
  )
}

export default KpiHeader