import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'components/common'
import { StockContext } from 'stores/StockStore'
import { AppContext } from 'stores/AppStore'
import FilterableTable from 'components/common/FilterableTable'

const TotalAngolaStockTable = () => {
  const { t } = useTranslation()
  const { lengthUnit, convertToCurrentLengthUnit } = useContext(AppContext)
  const { filteredStockData: { tableData: data }} = useContext(StockContext)

  const columns = useMemo(() => [
    {
      Header: t('Block Name'),
      accessor: 'blockName',
      customHeaderProps: {
        style: {
          minWidth: 80
        }
      },
    },
    {
      Header: t('Project Type'),
      accessor: 'projectType',
      customHeaderProps: {
        style: {
          minWidth: 100
        }
      },
    },
    {
      Header: t('SAP Reference'),
      accessor: 'sapReference',
      customHeaderProps: {
        style: {
          minWidth: 80
        }
      },
    },
    {
      Header: t('Material Description'),
      accessor: 'dscMaterial',
      customHeaderProps: {
        style: {
          minWidth: 140
        }
      },
    },
    {
      Header: t('Material Status'),
      id: 'dscStatusMaterial',
      accessor: ({ dscStatusMaterial }) => dscStatusMaterial && t(`stock.materialStatus.${dscStatusMaterial}`).toUpperCase(),
      customHeaderProps: {
        style: {
          minWidth: 125
        }
      },
    },
    {
      Header: t('Pieces'),
      accessor: 'qtdPC',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Total Estimated Length') + ` (${lengthUnit})`,
      id: 'totalEstimatedLength',
      accessor: ({ totalFootage }) => `${convertToCurrentLengthUnit(totalFootage/1000, totalFootage/304.8, lengthUnit)}`,
      customHeaderProps: {
        style: {
          minWidth: 180
        }
      },
    },
    {
      Header: t('Site'),
      accessor: 'site',
    },
    {
      Header: t('Zone'),
      accessor: 'zone',
    },
    {
      Header: t('Area'),
      accessor: 'area',
    },
    {
      Header: t('Rack'),
      accessor: 'rack',
    }
  ], [t, lengthUnit, convertToCurrentLengthUnit])

  return (
    <Card padding="0px" margin="0px 16px 16px" alignSelf="stretch">
      <FilterableTable columns={columns} data={data} />
    </Card>
  )
}

export default TotalAngolaStockTable