import React, { useState, useEffect, useMemo } from 'react'
import { Auth } from 'aws-amplify'
import Cookies from 'js-cookie'
import axios from 'axios'
import { LoadingOverlay } from 'components/common'
import { decompressResponse } from 'utils/decompress'
import _ from 'lodash'
import useInterval from 'hooks/useInterval'

const initialState = {
  user: null,
  accessToken: '',
  idToken: ''
}

export const UserContext = React.createContext(initialState)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [settings, setSettings] = useState(null)
  const [authState, setAuthState] = useState(null)
  const [accessToken, setAccessToken] = useState('')
  const [idToken, setIdToken] = useState('')
  const [isLoading, setLoading] = useState(true)

  const signOut = async () => {
    try {
      await Auth.signOut()
      setUser(null)
      setAccessToken('')
      setIdToken('')
      setAuthState(null)
    }
    catch (e) {
      console.log(e)
    }
  }

  const authApi = useMemo(() => axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': idToken + '.' + btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
  }), [idToken])

  const getUserDetails = (token) => {
    return new Promise((resolve, reject) => {
      if (!currentUser) {
        const headerToken = { 'Authorization': token + '.' + btoa(JSON.stringify({ "id": process.env.REACT_APP_ID })) }
        authApi.post('/user/detail', {}, { headers: headerToken })
          .then((response) => {
            const responseData = response.data ? response.data : response
            const data = decompressResponse(responseData)
            setCurrentUser(data.user)
            setSettings(data.rootSettings)
            console.log(data)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  useEffect(() => {
    const hydrate = () => new Promise((resolve, reject) => {
      const shouldGetUser = (Cookies.get('rememberMe') === 'true' || Cookies.get('signedIn') === 'true')
      shouldGetUser ? Auth.currentUserPoolUser().then(async (user) => {
        if (user) {
          console.log(user)
          setUser(user)
          setIdToken(user.signInUserSession.idToken.jwtToken)
          setAccessToken(user.signInUserSession.accessToken.jwtToken)
          setAuthState('SIGNED_IN')
          await getUserDetails(user.signInUserSession.idToken.jwtToken)
        }
        resolve()
      }).catch(err => {
        reject(err)
      }) : resolve()
    })

    hydrate().catch(err => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const endUsers = useMemo(() => {
    const customers = _.get(currentUser, 'roles.CUSTOMERS', {})
    return _.chain(customers)
      .pickBy((value, key) => key !== 'id')
      .map(group => _.chain(group)
        .pickBy((value, key) => key !== 'id')
        .map(({ id }) => id)
        .uniq()
        .value()
      )
      .flatten()
      .uniq()
      .value()
  }, [currentUser])

  const inputEndUsers = useMemo(() => {
    const customers = _.get(currentUser, 'roles.CUSTOMERS', {})
    return _.chain(customers)
      .pickBy((value, key) => key !== 'id')
      .map(group => _.chain(group)
        .pickBy((value, key) => key !== 'id')
        .map(({ id }) => ({ label: id.toString(), value: id.toString()}))
        .uniq()
        .value()
      )
      .flatten()
      .uniq()
      .value()
  }, [currentUser])

  useInterval(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      const currentSession = await Auth.currentSession()
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        if (err) {
          console.log('Unable to refresh Token', err)
        }
        else {
          console.log('Refreshed User Tokens')
          const { idToken, accessToken } = session
          setIdToken(idToken.jwtToken)
          setAccessToken(accessToken.jwtToken)
        }
      })
    } catch (e) {
      console.log('Unable to refresh Token', e)
    }
  }, 600000)

  const renderStore = isLoading ?
    <LoadingOverlay visible={true} />
    :
    <UserContext.Provider value={{
      user,
      accessToken,
      idToken,
      authState,
      currentUser,
      endUsers,
      settings,
      authApi,
      signOut, 
      inputEndUsers
    }}>
      {children}
    </UserContext.Provider>

  return renderStore
}
